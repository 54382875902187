import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { getLeadListApi } from "../../Leads/ApiCall";
import { toast } from "react-toastify";
import { createDealApi } from "../ApiCall";
import { getUserListApi } from "../../Company/ApiCall";

function DealCreate() {
    const [date, setDate] = useState(null);
    const [leadList, setLeadList] = useState([])
    const [salesList, setSalesList] = useState([]);
    const [dealInfo, setDealInfo] = useState({
        name: "",
        leadId: "",
        value: "",
        stage: "",
        closingDate: "",
        probability: "",
        nextAction: "",
        date: "",
        salesInCharge: [],
        remarks: "",
    });
    const requiredFields = [
        "name",
        "leadId",
        "value",
        "stage",
        "closingDate",
        "probability",
        "nextAction",
        "date",
        "salesInCharge"
    ];
    const stagesList = [
        { value: "Target", label: "Target" },
        { value: "Potential", label: "Potential" },
        { value: "Client", label: "Client" },
        { value: "Other", label: "Other" },
    ]
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getUserList();
        getLeadList();
    },[]);

    const getUserList = async () => {
        const { response, err } = await getUserListApi()

        if (!err) {
            const mappedCountries = response.map(({ id, username, ...rest }) => ({
                value: id,
                label: username,
                checked: false,
                ...rest,
            }));
            setSalesList(mappedCountries);
        }
    };

    const getLeadList = async () => {
        const { response, err } = await getLeadListApi()

        try {
            if (!err) {
                const mapped = response.map(({ id, nameEnglish, ...rest }) => ({
                    value: id,
                    label: nameEnglish,
                    ...rest,
                }));
                setLeadList(mapped)
            }
        } catch (err) {
            return err
        }
    }

    const handleChange = (name, value) => {
        // console.log('name:', name);
        // console.log('value:', value);
        let formattedValue = value;
        if(name == 'date' || name == 'closingDate') {
            const selectedDate = new Date(value);
            formattedValue = selectedDate.toISOString().split('T')[0];
        }
        setDealInfo((prevState) => ({
            ...prevState,
            [name]: name === 'salesInCharge' ? value[0] : formattedValue,
        }));
    };

    const handleCheckboxChange = (e, i) => {
        const updatedSalesList = salesList.map((item, index) => ({
            ...item,
            checked: index === i, 
        }));
        const updatedSalesInCharges = updatedSalesList[i].checked ? [updatedSalesList[i].value] : [];
        handleChange('salesInCharge', updatedSalesInCharges); 
        setSalesList(updatedSalesList); 
    };

      
    const handleSubmit = async (e) => {
        const newErrors = {};
        requiredFields.forEach((field) => {
            if (!dealInfo[field]) {
                newErrors[field] = `${field.replace(/_/g, " ")} field is required`;
            }
        });

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            // Handle form submission
            console.log("Form submitted successfully:", dealInfo);

            const { response, err } = await createDealApi(dealInfo);

            if (!err) {
                console.log("response", response);
                toast.success("Record created successfully!")

                navigate("/deal-list");
            }
        }
    };

    return (
        <div className="page-wrapper custom-lead  company-create">
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col-xl-12">
                            <h3 className="page-title">Deal Create</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="#">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/deal-list">Deal</Link>
                                </li>
                                <li className="breadcrumb-item active">Deal Create</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="">
                    <form>
                        <div className="contact-input-set">
                            <div className="row">

                                <div className="col-md-5">
                                    <div className="input-block mt-3">
                                        <label className="col-form-label">
                                            Deal Name (EN/JP) <span className="text-danger">*</span>
                                        </label>
                                        <input placeholder="eg: Jhon Doe"
                                            value={dealInfo.name}
                                            onChange={(e) =>
                                                handleChange("name", e.target.value)
                                            }
                                            className="form-control"
                                            type="text"
                                        />
                                    </div>
                                    {errors.name && (
                                        <p className="error-text">{errors.name}</p>
                                    )}
                                </div>

                                <div className="col-md-5">
                                    <div className="input-block mt-3">
                                        <label className="col-form-label">
                                            Lead Name <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                            value={
                                                dealInfo.leadId
                                                    ? leadList.find(
                                                        (e, i) => e.value === dealInfo.leadId
                                                    )
                                                    : null
                                            }
                                            onChange={(e) => handleChange("leadId", e.value)}
                                            options={leadList}
                                        />
                                    </div>
                                    {errors.leadId && (
                                        <p className="error-text">{errors.leadId}</p>
                                    )}
                                </div>

                                <div className="col-md-5">
                                    <div className="input-block mt-3">
                                        <label className="col-form-label">
                                            Deal Value <span className="text-danger">*</span>
                                        </label>
                                        <input placeholder="eg: 100000"
                                            value={dealInfo.value}
                                            onChange={(e) =>
                                                handleChange("value", e.target.value)
                                            }
                                            className="form-control"
                                            type="number"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-5">
                                    <div className="input-block mt-3">
                                        <label className="col-form-label">
                                            Deal Stage <span className="text-danger">*</span>
                                        </label>

                                        <Select
                                            value={
                                                dealInfo.stage
                                                    ? stagesList.find(
                                                        (e, i) => e.label === dealInfo.stage
                                                    )
                                                    : null
                                            }
                                            onChange={(e) => handleChange("stage", e.label)}
                                            options={stagesList}
                                        />

                                    </div>
                                    {errors.stage && (
                                        <p className="error-text">{errors.stage}</p>
                                    )}
                                </div>

                                <div className="col-md-5">
                                    <div className="input-block mt-3">
                                        <label className="col-form-label">
                                            Closing Date <span className="text-danger">*</span>
                                        </label>
                                        <input placeholder="YYYY / MM / DD"
                                            value={dealInfo.closingDate}
                                            onChange={(e) =>
                                                handleChange("closingDate", e.target.value)
                                            }
                                            className="form-control"
                                            type="date"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-5">
                                    <div className="input-block mt-3">
                                        <label className="col-form-label">
                                            Probability <span className="text-danger">*</span>
                                        </label>
                                        <input placeholder="eg: 100"
                                            value={dealInfo.probability}
                                            onChange={(e) =>
                                                handleChange("probability", e.target.value)
                                            }
                                            className="form-control"
                                            type="number"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-5">
                                    <div className="input-block mt-3">
                                        <label className="col-form-label">
                                            Date <span className="text-danger">*</span>
                                        </label>
                                        <input placeholder="YYYY / MM / DD"
                                            value={dealInfo.date}
                                            onChange={(e) =>
                                                handleChange("date", e.target.value)
                                            }
                                            className="form-control"
                                            type="date"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-5">
                                    <div className="input-block mt-3">
                                        <label className="col-form-label">
                                            Next Action <span className="text-danger">*</span>
                                        </label>
                                        <input 
                                         placeholder="eg: Followup meeting"
                                        value={dealInfo.nextAction}
                                        onChange={(e) =>
                                            handleChange("nextAction", e.target.value)
                                        }
                                        className="form-control"
                                            type="text"
                                        />
                                    </div>
                                </div>

                                <div className="row">

                                    <div className="col-md-8">
                                        <div className="input-block mt-3">
                                            <h3 className="heading-subtitles mt-2">
                                                Sales Information
                                            </h3>
                                            <ul className="personal-info">
                                                <li className="mb-2">
                                                    <div className="title head"> Created By</div>
                                                </li>
                                                <li className="mb-3">
                                                    <div className="project-members">
                                                        <ul className="team-members">
                                                            <li>
                                                                <a
                                                                    href="#"
                                                                    data-bs-toggle="tooltip"
                                                                    aria-label="John Doe"
                                                                    data-bs-original-title="John Doe"
                                                                >
                                                                    <img src="/assets/img/avatar/avatar-27.jpg" alt="User Image" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            </ul>
                                            <label className="col-form-label">Managed by <span
                                                className="text-danger">*</span></label>
                                        </div>
                                        <div className="row">
                                            {salesList.map((e, i) => (
                                                e.checked ?
                                                    <div className="w-auto d-flex align-items-center justify-content-between sales-incharge-curve">
                                                        <div className=" d-flex align-items-center justify-content-between">
                                                            <div className="list-image-circle ">
                                                                {e.label.split(' ')
                                                                    .map(name => name.charAt(0).toUpperCase())
                                                                    .join('')
                                                                }
                                                            </div>
                                                            <div className="offset-1">{e.label}</div>
                                                        </div>
                                                        <button onClick={() => {
                                                            setDealInfo({ ...dealInfo, salesInCharge: e.value })
                                                            var data1 = [...salesList]
                                                            data1[i].checked = false
                                                            setSalesList(data1)
                                                        }} type="button" className="custom-btn-close"><span aria-hidden="true">×</span>
                                                        </button>
                                                    </div>
                                                    : null
                                            ))}

                                            <div className="col-md-3">
                                                <div className="">
                                                    <button data-bs-toggle="modal" data-bs-target="#add_policy" className="btn btn-primary" type="button">
                                                        Add Person
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-10">
                                        <div className="input-block mt-3">
                                            <label className="col-form-label">Remarks</label>
                                            <textarea
                                                placeholder="eg: type some information"
                                                value={dealInfo.remarks}
                                                onChange={(e) => handleChange("remarks", e.target.value)}
                                                className="form-control"
                                                rows="5"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-lg-12 text-end form-wizard-button">
                    <button
                        className="button btn-lights reset-btn"
                        type="reset"
                    >
                        Back
                    </button>
                    <button
                        className="button btn-lights reset-btn"
                        type="reset"
                    >
                        Reset
                    </button>
                    <button onClick={() => handleSubmit()} className="btn btn-primary"
                        type="button">
                        Save
                    </button>
                </div>
                <div id="add_policy" className="modal custom-modal fade" role="dialog">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">SalesInCharges</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className=" mb-3">
                                    <input placeholder="search......" className="form-control" type="text" />
                                </div>
                                <div className="list-content-checkbox">
                                    {salesList.map((e, i) => (
                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <div className="image-circle ">
                                                    {e.label.split(' ')
                                                        .map(name => name.charAt(0).toUpperCase())
                                                        .join('')}
                                                </div>
                                                <p className="offset-1">{e.label}</p>
                                            </div>
                                            <div className="edit-column-checkbox">
                                            <input
                                                type="checkbox"
                                                checked={e.checked}
                                                onChange={(event) => handleCheckboxChange(event, i)}
                                                id={`checkbox-${i}`}
                                            />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DealCreate;