import React, { useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import "./common.css"
const GridTable = ({ data, columns, pagination, perpage }) => {

    const CombinedCellRenderer = (props) => {
        const { data } = props;
        console.log("datadata", data)
        // Assuming data contains the values you want to combine
        return (
            <div>
                <div>{data.value1}</div>
                <div>{data.value2}</div>
            </div>
        );
    };






    const autoSizeStrategy = {
        type: 'fitGridWidth',
        defaultMinWidth: 150,
        columnLimits: [
            {
                colId: 'employees',
                minWidth: 150,
            },
            {
                colId: 'actions',
                minWidth: 100,
            },
        ],
    };
    const onGridReady = (params) => {
        // Auto-size columns to fit the grid width
        params.api.sizeColumnsToFit();
    };

    const gridOptions = {
        localeText: {
            // Override default pagination text
            page: 'Page',
            to: 'to',
            of: 'of',
            next: 'Next',
            last: 'Last',
            first: 'First',
            previous: 'Previous',
            selectAll: 'Select All',
            pageSize: 'Rows per page' // Change this to your desired label
        },

    };

    const localeText = {
        page: 'Page',
        to: 'to',
        of: 'of',
        more: 'more',
        next: 'Next',
        last: 'Last',
        first: 'First',
        previous: 'Previous',
        loadingOoo: 'Loading...',
        selectAll: 'Select All',
        searchOoo: 'Search...',
        blanks: 'Blanks',
        filterOoo: 'Filter...',
        applyFilter: 'Apply Filter...',
        equals: 'Equals',
        notEqual: 'Not Equal',
        lessThan: 'Less Than',
        greaterThan: 'Greater Than',
        lessThanOrEqual: 'Less Than Or Equal',
        greaterThanOrEqual: 'Greater Than Or Equal',
        inRange: 'In Range',
        contains: 'Contains',
        notContains: 'Not Contains',
        startsWith: 'Starts With',
        endsWith: 'Ends With',
        pageSize: 'Rows Per Page', // Change this to your desired text
    };
    const CustomPagination = (props) => {
        const { gridOptions } = props;
        const onPageSizeChanged = (event) => {
            gridOptions.api.paginationSetPageSize(Number(event.target.value));
        };

        return (
            <div className="custom-pagination">
                <span className="per-page-text">Rows Per Page:</span>
                <select onChange={onPageSizeChanged} value={gridOptions.api.paginationGetPageSize()}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
            </div>
        );
    };

    const gridOptionsRef = useRef(null);

    useEffect(() => {
        if (gridOptionsRef.current) {
            gridOptionsRef.current.api.paginationSetPageSize(10); 
        }
    }, []);
    
    return (
        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
            <AgGridReact
                // gridOptions={gridOptions}
                gridOptions={gridOptionsRef.current}
                autoSizeStrategy={autoSizeStrategy}
                columnDefs={columns}
                paginationAutoPageSize={false}
                rowData={data}
                frameworkComponents={{
                    agPagination: CustomPagination,
                }}
                // sideBar={{ toolPanels: ['columns'], defaultToolPanel: '' }}
                pagination={pagination ? pagination : false}
                paginationPageSize={perpage ? perpage : 10}
                localeText={localeText}
                domLayout='autoHeight'
            // onGridReady={onGridReady}
            // suppressCellSelection={true}

            />
        </div>
    );
};

export default GridTable;
