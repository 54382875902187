import React, { useEffect, useRef, useState } from "react";
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
import './LeadDetails.css';
import { Link, useLocation, useParams } from "react-router-dom";
import GridTable from "../../../components/common/GridTable";
import { getcompanydeleteapi, getcompanyviewapi, getimageapi, getcompanylistapi, getLeadDetailsByID, getDealListByLeadIDApi } from "../ApiCall";
import { createPortal } from 'react-dom';
import { ToastContainer, toast } from 'react-toastify';
import moment from "moment/moment";
import { LeadDetailsActivityLog } from "./component/LeadDetailsActivityLogs";
import { LeadDetailsDealsList } from "./component/LeadDetailsDealsList";
import { getCompanyDetailsApi, getImageApi } from "../../Company/ApiCall";
import { LeadDetailsMemo } from "./component/LeadDetailsMemo";


function LeadDetails() {

  const { id } = useParams()
  const [leadDeatils, setLeadDeatils] = useState({})
  const [leadLogo, setLeadLogo] = useState("");
  const [dealList, setDealList] = useState([]);

    useEffect(() => {
      getLeadDetails();
      getDealListByLeadID();
  }, [])

  const getLeadDetails = async () => {
    try {
      const { response, err } = await getLeadDetailsByID(id);
      console.log("response", response);
      if (!err && response) {
        if (response.businessCard) {
          loadLeadImage(response.businessCard);
        }
        let leadDetails = response;
        try {
          const companyName = await getCompanyDetailsApi(leadDetails.companyId);
          if (companyName && companyName.response) {
            const compData = companyName.response;
            console.log("compData", compData);
            setLeadDeatils({ ...leadDetails, companyData: compData });
          }
        } catch (companyError) {
          console.error("Error fetching company name", companyError);
        }
      } else {
        console.log("Error fetching lead details:", err);
      }
    } catch (error) {
      console.error("Error in getLeadDetails:", error);
    }
  }

  const getDealListByLeadID = async () => {
    const { response, err } = await getDealListByLeadIDApi(id);
    console.log("getDealListByLeadID response 1", response)
    if (!err) {
       setDealList(response);
    }
}

  const loadLeadImage = async (path) => {
      const { response, err } = await getImageApi(path)
      if (!err) {
          console.log("hhhh", response)
          setLeadLogo(response)
      }
  }
  return (

    <div className="main-wrapper company_view">
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <h3 className="page-title">Lead List</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                    <li className="breadcrumb-item "><a href="/lead-list">Lead List</a></li>
                    <li className="breadcrumb-item active">Lead Details</li>
                  </ul>
                </div>
                <div className="col-md-0 float-end ms-auto">
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-12">
                <div className="contact-head">
                  <div className="row align-items-center">
                    <div className="col-sm-6">
                      <ul className="contact-breadcrumb">
                        <li><a href="/lead-list"><i className="las la-arrow-left"></i> Lead List</a></li>
                        <li>Lead Details</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="contact-wrap row">
                  <div className="contact-profile col-md-6">
                    <div className="avatar company-avatar">
                    {leadLogo ? (
                          <img src={`data:image/png;base64,${leadLogo}`} alt="Company Logo" />
                      ) : (
                          <img src="/assets/img/company/company-01.svg" alt="Default Company Logo" />
                      )}
                      {/* <img src="assets/img/icons/company-icon-01.svg" alt="Image" /> */}
                    </div>
                    <div className="name-user">
                      <h4>{leadDeatils.nameEnglish}</h4>
                      <p><i className="las la-map-marker"></i> 22, Ave Street, Newyork, USA</p>
                      <div className="badge-rate">
                        <p><i className="fa-solid fa-star"></i> 5.0</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="project-members m-b-15">
                      <div>Created By:</div>
                      <ul className="team-members">
                        <li>
                          <a href="#" data-bs-toggle="tooltip" title="John Doe">
                          <img src="/assets/img/profiles/avatar-02.jpg"  alt="User Image" /></a>
                          
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="project-members m-b-15">
                      <div>Managed By:</div>
                      <ul className="team-members">
                        <li>
                        {leadDeatils?.managedBy?.map((e, i) => (
                            <a
                            href="#"
                            key={i}
                            data-bs-toggle="tooltip"
                            aria-label={e.user.username}
                            data-bs-original-title={e.user.username}
                            >
                            {e.user?.image ?
                                <img src="assets/img/avatar/rletter.png" alt="User Image" /> :
                                <p>
                                    {e.user.username.charAt(0).toUpperCase()}
                                </p>
                            }
                            </a>
                            ))}
                        </li>
                    

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="card contact-sidebar">

                  <h5 className="left">General Info</h5>
                  <ul className="other-info">

                    <li><span className="other-titlenew">Stage</span><span className="badge-soft-info">{leadDeatils.stage}</span></li>
                    <li><span className="other-titlenew">Status</span><span className={leadDeatils.status == "Active" ? "outline-status d-flex float-end" : "outline-inactive-status d-flex float-end d-flex float-end"}>{leadDeatils.status}</span></li>
                    <li><span className="other-titlenew">Department</span><span>{leadDeatils.department}</span></li>
                    <li><span className="other-titlenew">Position</span><span>{leadDeatils.position}</span></li>
                    <li><span className="other-titlenew">Company</span><span>{leadDeatils.companyData?.companyen}</span></li>
                    <li><span className="other-titlenew">Email
                    </span><span className="ledwdth">{leadDeatils.email}</span></li>
                    <li><span className="other-titlenew">Telephone</span><span>{leadDeatils.telephone}</span></li>
                    {/* <li><span className="other-titlenew">State</span><span>Kansai</span></li>
                    <li><span className="other-titlenew">Country</span><span>Japan</span></li> */}

                  </ul>
                  <h5 className="left">Performance</h5>
                  <ul className="other-info">

                    <div className="card w-100">

                      <ul className="personal-info" style={{ margin: "8px" }}>

                        <li className="mb-20">
                          <div className="title margintplt wdth0">Revenue generated
                          </div>

                        </li>

                      </ul>

                    </div>
                    <div className="card w-100 crdbckclr">

                      <ul className="personal-info" style={{ margin: "8px" }}>

                        <li className="mb-20">
                          <div className="title margintplt wdth0">Invoices created
                          </div>

                        </li>

                      </ul>

                    </div>
                    <div className="card w-100 crdbckclr2">

                      <ul className="personal-info" style={{ margin: "8px" }}>

                        <li className="mb-20">
                          <div className="title margintplt wdth0">Estimations create
                          </div>

                        </li>

                      </ul>

                    </div>

                  </ul>
                  <h5 className="left">Performance</h5>
                  <div className="contact-activity">
                    <div className="badge-day"><i className="fa-regular fa-calendar-check"></i>15 Feb 2024</div>
                    <ul>
                      <li className="activity-wrap">
                        <span className="activity-icon bg-info">
                          <i className="las la-comment-alt"></i>
                        </span>
                        <div className="activity-info">
                          <h6>You sent 1 Message to the contact.</h6>
                          <p>10:25 pm</p>
                        </div>
                      </li>
                      <li className="activity-wrap">
                        <span className="activity-icon bg-success">
                          <i className="las la-phone"></i>
                        </span>
                        <div className="activity-info">
                          <h6>Denwar responded to your appointment schedule question by call at 09:30pm.</h6>
                          <p>09:25 pm</p>
                        </div>
                      </li>

                    </ul>
                  </div>



                </div>
              </div>


              <div className="col-xl-9">
                <div className="contact-tab-wrap">
                  <ul className="contact-nav nav">
                    <li>
                      <a href="#" data-bs-toggle="tab" data-bs-target="#activities" className="active"><i className="las la-user-clock"></i>Activities</a>
                    </li>
                    <li>
                      <a href="#" data-bs-toggle="tab" data-bs-target="#notes"><i className="las la-file"></i>Deals {`(${dealList.length})`}</a>
                    </li>
                    <li>
                      <a href="#" data-bs-toggle="tab" data-bs-target="#calls"><i className="las la-file"></i>Memo (9)</a>
                    </li>

                  </ul>
                </div>

                <div className="contact-tab-view">
                  <div className="tab-content pt-0">
                    <div className="tab-pane active show" id="activities">
                      <div className="view-header">
                        <h4>Activities</h4>
                      </div>
                    <LeadDetailsActivityLog />
                    </div>

                    <div className="tab-pane fade" id="notes">
                      <div className="view-header">
                        <h4>Deals</h4>

                      </div>
                     <LeadDetailsDealsList dealsList={dealList} />
                    </div>


                    <div className="tab-pane fade" id="calls">
                      <div className="view-header">
                        <h4>Memo </h4>
                       
                      </div>
                    <LeadDetailsMemo />
                    </div>




                  </div>
                </div>

              </div>

            </div>
          </div>

        </div>

      </div>
    </div >


  );
}

export default LeadDetails;