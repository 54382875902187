import React, { useEffect, useRef, useState } from "react";
import './LeadList.css';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import NavbarPage from "../../../components/navbar/navbar";
import Select from 'react-select';
import GridTable from "../../../components/common/GridTable";
import { createPortal } from 'react-dom';
import { CSVLink } from "react-csv";

import { getLeadListApi, deleteLeadByIDApi } from "../ApiCall";
import {
    getCompanyDetailsApi,
    getCompanyListApi,
    getCountryListApi,
    getStateListApi
} from "../../Company/ApiCall";
import {toast} from "react-toastify";

function LeadList() {

    const [listtype, setListType] = useState("List");
    const [leadList, setLeadList] = useState([]);
    const [IntialLeadList, setIntialLeadList] = useState([]);
    const [popupMenu, setPopupMenu] = useState({
        isOpen: false,
        x: 0,
        y: 0,
        rowId: null,
    });
    const [tempcolumn, setTempcolumn] = useState([])

    const menuRef = useRef(null);
    const [bgColor, setBgColor] = useState("white");
    const [bgColor2, setBgColor2] = useState("white");
    const [bgColor3, setBgColor3] = useState("white");
    const [bgColor5, setBgColor5] = useState("white");
    const [bgColor6, setBgColor6] = useState("white");
    const [bgColor7, setBgColor7] = useState("white");
    const [stageList, setStageList] = useState([
        { value: 'Target', label: 'Target' },
        { value: 'Potential', label: 'Potential' },
        { value: 'Client', label: 'Client' },
    ]);
    const [statusList, setStatusList] = useState([
        { value: 'Active', label: 'Active' },
        { value: 'InActive', label: 'InActive' },
    ]);
    const [IntialCompanyList, setIntialCompanyList] = useState([])
    const [companyList, setCompanyList] = useState([]);
    const [ countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [bgColors, setBgColors] = useState(Array(7).fill("white"));
    const [selectedValues, setSelectedValues] = useState({
        myleads: null,
        status: null,
        stages: null,
        company: null,
        country: null,
        state: null,
    });
    const navigate = useNavigate();
    const location = useLocation();
    const [csvData, setCsvData] = useState([]);
    const currentDate = new Date().toISOString().split('T')[0];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setPopupMenu({ ...popupMenu, isOpen: false });
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [popupMenu]);

    useEffect(() => {
        getCompanyList();
        getCountryList();
        getLeadList();
    }, [])

    const LeadDeleteFunc = async (id, index) => {
        const { response, err } = await deleteLeadByIDApi(id);

        try {
            if (!err) {
                getLeadList()
                toast.success("Record Deleted Successfully!");
                window.location.reload();
            }
            console.log("err", err)
        }
        catch (error) {
            return { error }
        }
    }

    const CustomCellRenderer = ({ data, node }) => {
        const [showPopup, setShowPopup] = useState(false);
        const [position, setPosition] = useState({ top: 0, left: 0 });

        const handleButtonClick = (event) => {
            const rect = event.target.getBoundingClientRect();
            setPosition({
                top: rect.top + window.scrollY + rect.height,
                left: rect.left + window.scrollX,
            });
            setShowPopup(!showPopup);
        };

        const handleClosePopup = () => {
            setShowPopup(false);
        };

        return (
            <div>
                <a onClick={handleButtonClick}> <i style={{ fontSize: 30 }} class="las la-ellipsis-v"></i></a>
                {showPopup && createPortal(
                    <div style={{ ...popupStyle, top: position.top, left: position.left }}>
                        <ul>
                            <Link
                                className="dropdown-item"
                                to={`/lead-edit/${data.id}`}
                            >
                                <i className="fa-solid fa-pencil m-r-5" /> Edit
                            </Link>
                            <Link
                                className="dropdown-item"
                                to={`/lead-details/${data.id}`}
                            >
                                <i className="fa-regular fa-eye" /> View
                            </Link>

                            <a
                                onClick={() => LeadDeleteFunc(data.id, node.rowIndex)}
                                className="dropdown-item"

                            >
                                <i className="las la-trash" /> Delete
                            </a>
                        </ul>
                    </div>,
                    document.body 
                )}
            </div>
        );
    };

    const getCompanyList = async() => {
        const { response, err } = await getCompanyListApi();
        console.log("getCompanyList ", response)
        if (!err) {
            const mapped = response.map(({ id, companyen, ...rest }) => ({
                value: id,
                label: companyen,
                ...rest,
            }))
            setCompanyList(mapped)
        }
        console.log("err", err)
    }

    const getCountryList = async () => {
        const { response, err } = await getCountryListApi();
        if (!err) {
          const mappedCountries = response.map(({ id, name, ...rest }) => ({
            value: id,
            label: name,
            ...rest,
          }));
          setCountryList([...mappedCountries]);
        }
        console.log("err", err);
      };
    

      const SelectCountryFunc = async (id) => {
        console.log('SelectCpunty Func', id);
        const { response, err } = await getStateListApi(id);
        console.log("responsetrtrtrtr", response);

        if (response != "State not found") {
            const mappedSates = response.map(({ id, name, ...rest }) => ({
                value: id,
                label: name,
                ...rest,
            }));
            setSelectedValues({
                ...selectedValues,
                state: null
            })
            setStateList(mappedSates);
        }
        console.log("err", err);
    };

    const getLeadList = async () => {
        const { response, err } = await getLeadListApi();
        console.log("response 1", response)
        if (!err) {
            let leadDetails = response;
            const updatedLeadList = await Promise.all(
                leadDetails.map(async (value, index) => {
                    try {
                        const companyName = await getCompanyDetailsApi(value.companyId);
                        const compData = companyName.response;
                        return {
                            ...value,
                            companyname: compData.companyen 
                        };
                    } catch (error) {
                        console.error("Error fetching company name", error);
                        return value; 
                    }
                })
            );
    
            setLeadList(updatedLeadList); 
            setIntialLeadList(updatedLeadList); 
            setCsvData(formatDataToCsv(updatedLeadList));
        }
    }
  
    const [columnDefs, setColumnsDefs] = useState([
        {
            headerName: 'Name', field: 'nameEnglish',
            cellRenderer: (props) => (
                <Link
                    to={`/lead-details/${props.data.id}`}
                >
                    {props.value}
                </Link>

            ),
        },
        {
            headerName: 'Company Name', field: 'companyname',
        },
        { headerName: 'Position', field: 'position', },
        { headerName: 'Department', field: 'department', },
        { headerName: 'Email', field: 'email', },
        {
            headerName: 'Telephone', field: 'telephone', cellRenderer: (props) => (
                <a > {props.value ? props.value : "-"} </a >
            )
        },

        { headerName: 'Stage', field: 'stage', },
        {
            headerName: 'Status',
            field: 'status',
            cellRenderer: (props) => (
                <a class={props.value == "Active" ? "outline-status" : "outline-inactive-status"}> {props.value} </a >
            )
        },
    
        {
            headerName: 'Deal Count', field: 'dealcount', cellRenderer: (props) => (
                <a > {props.value ? props.value : 0} </a >
            )
        },
        {
            headerName: 'Revenue Generated', field: 'revenueGenerated', cellRenderer: (props) => (
                <a > {props.value ? props.value : 0} </a >
            )
        },
        {
            headerName: '',
            field: 'actions',
            cellRenderer: CustomCellRenderer
        },
    ]);


    const popupStyle = {
        position: 'absolute',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        padding: '10px',
        zIndex: 1000, // Ensure it appears above the grid
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    };
    
    const formatDataToCsv = (data) => {

        const headers = columnDefs.map((col) => col.headerName).filter(header => header !== '');
        const rows = data.map((item) => columnDefs.map(col => {
            if (col.field === 'country') {
                return item.address.countryId?.name || ''; 
            }
            if (col.field === 'state') {
                return item.address.stateId?.name || ''; 
            }
            if (col.field === 'dealcount') {
                return item[col.field] || 0; 
            }
            if(col.field === 'revenueGenerated') {
                return item[col.field] || 0; 
            }
            return item[col.field] || ''; 
        }));
        return [headers, ...rows];
    };

   
    const addQueryParameter = (params) => {
        const searchParams = new URLSearchParams(location.search);
        Object.entries(params).forEach(([key, value]) => {
            searchParams.set(key, value);
        });

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        });
    };

    const handleSelectChange = (index, selectedOption, type) => {
        const updatedBgColors = [...bgColors];
        updatedBgColors[index] = selectedOption ? 'lightgrey' : 'white'; // Example logic for changing bg color
        setBgColors(updatedBgColors);

        // Update selected values state
        setSelectedValues((prevValues) => ({
            ...prevValues,
            [type]: selectedOption,
        }));
        // addQueryParameter({ 'company': selectedOption.value });
        console.log("selectedOption.valueselectedOption.value", selectedOption.value)
        // Filter logic based on the type of filter
        let filteredList = companyList;
        switch (type) {
            case 'myleads':
                // if (selectedValues.myleads) {
                //     filteredList = IntialLeadList.filter(person =>
                //         person.status === selectedOption.value
                //     );

                // }
                // else {
                //     filteredList = companyList.filter(person =>
                //         person.status === selectedOption.value
                //     );
                // }
                // addQueryParameter({ 'myleads': selectedOption.value });
                break;
            case 'status':
                // if (selectedValues.status) {
                //     filteredList = IntialLeadList.filter(person =>
                //         person.status === selectedOption.value
                //     );

                // }
                // else {
                //     filteredList = leadList.filter(person =>
                //         person.status === selectedOption.value
                //     );
                // }
                // addQueryParameter({ 'status': selectedOption.value });
                break;
            case 'stages':

                // if (selectedValues.stages) {

                //     filteredList = IntialLeadList.filter(person =>
                //         person.stages === selectedOption.value
                //     );
                // }
                // else {

                //     filteredList = leadList.filter(person =>
                //         person.stages === selectedOption.value
                //     );
                // }
                // addQueryParameter({ 'stages': selectedOption.value });
                break;
            case 'company':
               
                // if (selectedValues.company) {
                //     filteredList = IntialLeadList.filter(person =>
                //         person.address.countryId.id === selectedOption.value
                //     );
                // }
                // else {
                //     filteredList = leadList.filter(person =>
                //         person.address.countryId.id === selectedOption.value
                //     );
                // }
                // addQueryParameter({ 'company': selectedOption.value });

                break;
            case 'country':
                // addQueryParameter({ 'country': selectedOption.value });
                SelectCountryFunc(selectedOption.value)

                // if (selectedValues.country) {

                //     filteredList = IntialLeadList.filter(person =>
                //         person.address.countryId.id === selectedOption.value
                //     );
                // }
                // else {


                //     filteredList = leadList.filter(person =>
                //         person.address.countryId.id === selectedOption.value
                //     );
                // }
                break;
            case 'state':
                // addQueryParameter({ 'state': selectedOption.value });
                // if (selectedValues.state) {
                //     filteredList = IntialLeadList.filter(person =>
                //         person.address.stateId.id === selectedOption.value
                //     );
                // }
                // else {
                //     filteredList = leadList.filter(person =>
                //         person.address.stateId.id === selectedOption.value
                //     );
                // }
                break;
            default:
                filteredList = leadList; // No filter
        }

        setCompanyList(filteredList);

    };

    const handleSearch = (text) => {
        if (text.length == 0) {
            setLeadList(IntialLeadList)

        }
        else {
            const person = IntialLeadList.filter((person) =>
                person.nameEnglish.toLowerCase().includes(text.toLowerCase())
            );
            console.log(person);
            setLeadList(person)
        }
    };

    return (
        <div className="page-wrapper custom-lead-list lead-list">
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col-xl-4">
                            <h3 className="page-title">Lead List</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="#">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">Lead List</li>
                            </ul>
                        </div>
                        <div className="col-xl-8 float-end ms-auto">
                            <div className="d-flex title-head">
                                <Link
                                    className="btn add-btn contrast-button"
                                >
                                    <i className="las la-file-download" />Import
                                </Link>
                                <CSVLink data={csvData} 
                                       filename={`Leadlist_${currentDate}.csv`}
                                        className="btn add-btn contrast-button">
                                        <i className="las la-file-export"/> Export
                                    </CSVLink>

                                <Link
                                    to="/lead-create"
                                    className="btn add-btn"

                                >
                                    <i className="la la-plus-circle" />Create
                                </Link>


                                <div className="search-set">
                                    <div className="search-input">
                                        <a className="btn btn-searchset cursor-none">
                                            <i className="las la-search" />
                                        </a>
                                        <div className="dataTables_filter">
                                            <label>
                                                <input
                                                    type="text"
                                                    className="form-control pd-left-20 ht-35"
                                                    placeholder="Search"
                                                    onChange={(e) => handleSearch(e.target.value)}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>
                </div>
                <div className="row bg-light-blue">

                    <p className="pt-3  heading">New leads this Week</p>
                    {[...Array(3)].map((_, index) => (
                     <div key={index} className="col-md-6 col-sm-12 col-lg-6 col-xl-4 d-flex">
            <div className="card dash-widget bg-white w-100">
                <div className="card-body display-block">
                    <div className="profile-view row align-items-center">
                        <div className="profile-img-wrap col-md-2">
                            <div className="profile-img">
                                <a href="#">
                                    <img src="/assets/img/profiles/avatar-19.jpg" alt="User Image" />
                                </a>
                            </div>
                        </div>
                        <div className="profile-basic col-md-8">
                            <div className="profile-info-left">
                                <h3 className="user-name m-t-0">LN Infotech</h3>
                                <div className="text-muted mt-2">
                                    <img className="capitial-image" src={require("../../../assets/phone-call.png")} /> 79945 45784
                                </div>
                                <div className="text-muted mt-2">
                                    <img className="capitial-image" src={require("../../../assets/email.png")} /> ln123@gmail.com
                                </div>
                                <div className="text-muted mt-2">
                                    <img className="capitial-image" src={require("../../../assets/progress.png")} /> Potential
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ))}
                    {/* <div className="col-md-6 col-sm-12 col-lg-6 col-xl-4">
                        <div className="card dash-widget bg-white">
                            <div className="card-body display-block">
                                <div class="profile-view row   align-items-center">
                                    <div class="profile-img-wrap col-md-2">
                                        <div class="profile-img">
                                            <a href="#">
                                                <img src="/assets/img/profiles/avatar-19.jpg" alt="User Image" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="profile-basic col-md-8">

                                        <div class="profile-info-left">
                                            <h3 class="user-name m-t-0">LN Infotech</h3>
                                            <div class="text-muted mt-2">   <img className="capitial-image" src={require("../../../assets/phone-call.png")} /> 79945 45784</div>
                                            <div class="text-muted mt-2">   <img className="capitial-image" src={require("../../../assets/email.png")} /> ln123@gmail.com</div>
                                            <div class="text-muted mt-2">   <img className="capitial-image" src={require("../../../assets/progress.png")} /> Potential</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-lg-6 col-xl-4">
                        <div className="card dash-widget bg-white">
                            <div className="card-body display-block">
                                <div class="profile-view row   align-items-center">
                                    <div class="profile-img-wrap col-md-2">
                                        <div class="profile-img">
                                            <a href="#">
                                                <img src="/assets/img/profiles/avatar-19.jpg" alt="User Image" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="profile-basic col-md-8">

                                        <div class="profile-info-left">
                                            <h3 class="user-name m-t-0">LN Infotech</h3>
                                            <div class="text-muted mt-2">   <img className="capitial-image" src={require("../../../assets/phone-call.png")} /> 79945 45784</div>
                                            <div class="text-muted mt-2">   <img className="capitial-image" src={require("../../../assets/email.png")} /> ln123@gmail.com</div>
                                            <div class="text-muted mt-2">   <img className="capitial-image" src={require("../../../assets/progress.png")} /> Potential</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-lg-6 col-xl-4">
                        <div className="card dash-widget bg-white">
                            <div className="card-body display-block">
                                <div class="profile-view row   align-items-center">
                                    <div class="profile-img-wrap col-md-2">
                                        <div class="profile-img">
                                            <a href="#">
                                                <img src="/assets/img/profiles/avatar-19.jpg" alt="User Image" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="profile-basic col-md-8">

                                        <div class="profile-info-left">
                                            <h3 class="user-name m-t-0">LN Infotech</h3>
                                            <div class="text-muted mt-2">   <img className="capitial-image" src={require("../../../assets/phone-call.png")} /> 79945 45784</div>
                                            <div class="text-muted mt-2">   <img className="capitial-image" src={require("../../../assets/email.png")} /> ln123@gmail.com</div>
                                            <div class="text-muted mt-2">   <img className="capitial-image" src={require("../../../assets/progress.png")} /> Potential</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>


                <div className="filter-fields mt-5">
                    <div className="row filter-row">
                        <div className="col-xl-1 col-md-3 col-sm-6 pr-0 filter-status">
                            <div className="input-block mb-3 form-focus select-focus" >

                                <Select
                                    style={{ backgroundColor: bgColor, padding: '10px', borderRadius: '4px' }}
                                    placeholder="Leads"
                                    options={[
                                        { value: 'All Leads', label: 'All Leads' },
                                        { value: 'My Leads', label: 'My Leads' },
                                    ]}
                                    value={selectedValues.myleads}  // Show the selected value
                                    onChange={(selectedOption) => handleSelectChange(0, selectedOption, "myleads")}
                                    styles={{
                                        control: (styles) => ({
                                            ...styles,
                                            backgroundColor: bgColor,
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-xl-1 col-md-3 col-sm-6 filter-status pr-0">
                            <div className="input-block mb-3 form-focus select-focus" >
                                <Select
                                    style={{ backgroundColor: bgColor2, padding: '10px', borderRadius: '4px' }}
                                    placeholder="Status"
                                    options={statusList}
                                    value={selectedValues.status}  // Show the selected value
                                    onChange={(selectedOption) => handleSelectChange(1, selectedOption, "status")}
                                    styles={{
                                        control: (styles) => ({
                                            ...styles,
                                            backgroundColor: bgColor2,
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-xl-1 col-md-3 col-sm-6 pr-0 width-15">
                            <div className="input-block mb-3 form-focus select-focus" >
                                <Select
                                    style={{ backgroundColor: bgColor3, padding: '10px', borderRadius: '4px' }}
                                    placeholder="Stage"
                                    options={stageList}
                                    value={selectedValues.stages}  // Show the selected value
                                    onChange={(selectedOption) => handleSelectChange(2, selectedOption, "stages")}
                                    styles={{
                                        control: (styles) => ({
                                            ...styles,
                                            backgroundColor: bgColor3,
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-xl-1 col-md-3 col-sm-6 pr-0 width-15">
                            <div className="input-block mb-3 form-focus select-focus" >
                                <Select
                                    style={{ backgroundColor: bgColor5, padding: '10px', borderRadius: '4px' }}
                                    placeholder="Company"
                                    options={companyList}
                                    value={selectedValues.company}  // Show the selected value
                                    onChange={(selectedOption) => handleSelectChange(3, selectedOption, "company")}
                                    styles={{
                                        control: (styles) => ({
                                            ...styles,
                                            backgroundColor: bgColor5,
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-3 col-sm-6 pr-0 width-15">
                            <div className="input-block mb-3 form-focus select-focus " >
                                <Select
                                    style={{ backgroundColor: bgColor6, padding: '10px', borderRadius: '4px' }}
                                    placeholder="Country"
                                    options={countryList}
                                    // onChange={handleChange6}
                                    value={selectedValues.country}  // Show the selected value
                                    onChange={(selectedOption) => handleSelectChange(4, selectedOption, "country")}
                                    styles={{
                                        control: (styles) => ({
                                            ...styles,
                                            backgroundColor: bgColor6,
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-3 col-sm-6 pr-0 width-15">
                            <div className="input-block mb-3 form-focus select-focus " >
                                <Select
                                    style={{ backgroundColor: bgColor7, padding: '10px', borderRadius: '4px' }}
                                    placeholder="State"
                                    options={stateList}
                                    value={selectedValues.state}
                                    onChange={(selectedOption) => handleSelectChange(5, selectedOption, "state")}
                                    styles={{
                                        control: (styles) => ({
                                            ...styles,
                                            backgroundColor: bgColor7,
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-3 col-sm-6 pr-0 width-16">
                            <div className="view-icons">
                                <a
                                    onClick={() => setListType("List")}
                                    className={`list-view btn btn-link ${listtype == "List" ? "active" : ""} `}
                                >
                                    <i className="las la-list" />
                                </a>
                                <a
                                    onClick={() => setListType("Kanban")}
                                    className={`grid-view btn btn-link ${listtype == "Kanban" ? "active" : ""} `}

                                >
                                    <i className="las la-th" />
                                </a>
                                <a
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasdropcolumn"
                                    aria-controls="offcanvasdropcolumn"
                                    className="list-view btn btn-link filteroptions"
                                >
                                    <i className="fa fa-columns" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasdropcolumn" aria-labelledby="offcanvasRightLabel">
                    <div className="offcanvas-header">
                        <h5 id="offcanvasRightLabel">Choose which columns you see</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <div className="EditColumnsModalPresentational__HalfWidthContainer-tehgaw-1 fsFdXZ">
                            <div className="col-lg-12 text-end form-wizard-button mb-3 mt-0">
                                <button className="button btn-lights reset-btn" type="button" data-bs-dismiss="offcanvas" aria-label="Close">Cancel</button>
                                <button className="btn btn-primary" type="button" onClick={() => {
                                    setColumnsDefs(tempcolumn)
                                }} data-bs-dismiss="offcanvas" aria-label="Close">Apply</button>
                            </div>
                            <div className="private-search-control">
                                <input
                                    data-selenium-test="edit-columns-search"
                                    type="search"
                                    className="form-control private-form__control private-search-control__input"
                                    placeholder="Search columns..."
                                />
                            </div>


                            {columnDefs.filter((e, i) => e.headerName != "").map((e, i) => (

                                <label className="edit-column-checkbox">
                                    <input checked={e.hide ? false : true} onChange={() => {
                                        var data = [...columnDefs]
                                        data[i].hide = data[i].hide ? false : true
                                        setTempcolumn(data)
                                    }} type="checkbox" id="myCheckbox" />
                                    <span>{e.headerName}</span>
                                </label>

                            ))}

                        </div>
                    </div>
                </div>

                {listtype == "List" ?
                    <GridTable
                        columns={columnDefs}
                        data={leadList}
                        pagination={true}
                        perpage={20}
                        popupMenu={popupMenu}
                        menuRef={menuRef}
                        className="ag-ltr .ag-header-cell-resize"
                    />

                    :
                    <div className="row">
                        <div className="col-md-12">
                            <div className="kanban-wrapper">
                                <div className="kanban-list-items">
                                    <div className="kanban-list-head">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="kanban-title-head dot-success">
                                                <h5>TARGET</h5>
                                                <span>45 Leads - $15,44,540</span>
                                            </div>
                                            <div className="kanban-action-btns d-flex align-items-center">

                                                <div className="dropdown dropdown-action">
                                                    <a
                                                        href="#"
                                                        className="action-icon dropdown-toggle"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        <i className="material-icons">more_vert</i>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a
                                                            className="dropdown-item"
                                                            href="#"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit_deals"
                                                        >
                                                            <i className="fa-solid fa-pencil m-r-5" /> Edit
                                                        </a>
                                                        <a
                                                            className="dropdown-item"
                                                            href="#"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#delete_deals"
                                                        >
                                                            <i className="fa-regular fa-trash-can m-r-5" /> Delete
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="kanban-drag-wrap">
                                        <li>
                                            <div className="kanban-card">
                                                <div className="kanban-card-head">
                                                    <span className="bar-design bg-purple" />
                                                    <div className="kanban-card-title">
                                                    </div>
                                                </div>
                                                <div className="kanban-card-body">
                                                    <ul>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-dollar-sign" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-envelope" />
                                                            <a
                                                                href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                className="__cf_email__"
                                                                data-cfemail="1d797c6f717878725d78657c706d7178337e7270"
                                                            >
                                                                [email&nbsp;protected]
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            +1 12445-47878
                                                        </li>
                                                
                                                    </ul>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                
                                                        <span className="badge badge-soft-info">85%</span>
                                                    </div>
                                                </div>
                                                <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                    <span>
                                                        <i className="la la-calendar" /> 10 Jan 2024
                                                    </span>
                                                    <ul className="icons-social">
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-phone-volume" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="lab la-facebook-messenger" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-swatchbook" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="kanban-card">
                                                <div className="kanban-card-head">
                                                    <span className="bar-design bg-purple" />
                                                    <div className="kanban-card-title">

                                                    </div>
                                                </div>
                                                <div className="kanban-card-body">
                                                    <ul>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-dollar-sign" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-envelope" />
                                                            <a
                                                                href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                className="__cf_email__"
                                                                data-cfemail="1d797c6f717878725d78657c706d7178337e7270"
                                                            >
                                                                [email&nbsp;protected]
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            +1 12445-47878
                                                        </li>
                                                    
                                                    </ul>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                    
                                                        <span className="badge badge-soft-info">85%</span>
                                                    </div>
                                                </div>
                                                <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                    <span>
                                                        <i className="la la-calendar" /> 10 Jan 2024
                                                    </span>
                                                    <ul className="icons-social">
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-phone-volume" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="lab la-facebook-messenger" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-swatchbook" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="kanban-card">
                                                <div className="kanban-card-head">
                                                    <span className="bar-design bg-purple" />
                                                    <div className="kanban-card-title">

                                                    </div>
                                                </div>
                                                <div className="kanban-card-body">
                                                    <ul>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-dollar-sign" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-envelope" />
                                                            <a
                                                                href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                className="__cf_email__"
                                                                data-cfemail="1d797c6f717878725d78657c706d7178337e7270"
                                                            >
                                                                [email&nbsp;protected]
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            +1 12445-47878
                                                        </li>
                                                        {/* <li>
                                                            <i className="la la-map-marker-alt" />
                                                            Newyork, United States
                                                        </li> */}
                                                    </ul>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        
                                                        <span className="badge badge-soft-info">85%</span>
                                                    </div>
                                                </div>
                                                <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                    <span>
                                                        <i className="la la-calendar" /> 10 Jan 2024
                                                    </span>
                                                    <ul className="icons-social">
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-phone-volume" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="lab la-facebook-messenger" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-swatchbook" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="kanban-list-items">
                                    <div className="kanban-list-head">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="kanban-title-head dot-warning">
                                                <h5>POTENTIAL</h5>
                                                <span>45 Leads - $15,44,540</span>
                                            </div>
                                            <div className="kanban-action-btns d-flex align-items-center">

                                                <div className="dropdown dropdown-action">
                                                    <a
                                                        href="#"
                                                        className="action-icon dropdown-toggle"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        <i className="material-icons">more_vert</i>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a
                                                            className="dropdown-item"
                                                            href="#"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit_deals"
                                                        >
                                                            <i className="fa-solid fa-pencil m-r-5" /> Edit
                                                        </a>
                                                        <a
                                                            className="dropdown-item"
                                                            href="#"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#delete_deals"
                                                        >
                                                            <i className="fa-regular fa-trash-can m-r-5" /> Delete
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="kanban-drag-wrap">
                                        <li>
                                            <div className="kanban-card">
                                                <div className="kanban-card-head">
                                                    <span className="bar-design bg-purple" />
                                                    <div className="kanban-card-title">
                                                    </div>
                                                </div>
                                                <div className="kanban-card-body">
                                                    <ul>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-dollar-sign" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-envelope" />
                                                            <a
                                                                href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                className="__cf_email__"
                                                                data-cfemail="1d797c6f717878725d78657c706d7178337e7270"
                                                            >
                                                                [email&nbsp;protected]
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            +1 12445-47878
                                                        </li>
                                                    
                                                    </ul>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                    
                                                        <span className="badge badge-soft-info">85%</span>
                                                    </div>
                                                </div>
                                                <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                    <span>
                                                        <i className="la la-calendar" /> 10 Jan 2024
                                                    </span>
                                                    <ul className="icons-social">
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-phone-volume" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="lab la-facebook-messenger" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-swatchbook" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="kanban-card">
                                                <div className="kanban-card-head">
                                                    <span className="bar-design bg-purple" />
                                                    <div className="kanban-card-title">
                                                    </div>
                                                </div>
                                                <div className="kanban-card-body">
                                                    <ul>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-dollar-sign" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-envelope" />
                                                            <a
                                                                href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                className="__cf_email__"
                                                                data-cfemail="1d797c6f717878725d78657c706d7178337e7270"
                                                            >
                                                                [email&nbsp;protected]
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            +1 12445-47878
                                                        </li>
                                                    
                                                    </ul>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                    
                                                        <span className="badge badge-soft-info">85%</span>
                                                    </div>
                                                </div>
                                                <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                    <span>
                                                        <i className="la la-calendar" /> 10 Jan 2024
                                                    </span>
                                                    <ul className="icons-social">
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-phone-volume" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="lab la-facebook-messenger" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-swatchbook" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="kanban-card">
                                                <div className="kanban-card-head">
                                                    <span className="bar-design bg-purple" />
                                                    <div className="kanban-card-title">

                                                        {/* <h6>Howell, Tremblay and Rath</h6> */}
                                                    </div>
                                                </div>
                                                <div className="kanban-card-body">
                                                    <ul>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-dollar-sign" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-envelope" />
                                                            <a
                                                                href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                className="__cf_email__"
                                                                data-cfemail="1d797c6f717878725d78657c706d7178337e7270"
                                                            >
                                                                [email&nbsp;protected]
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            +1 12445-47878
                                                        </li>
                                                    
                                                    </ul>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                    
                                                        <span className="badge badge-soft-info">85%</span>
                                                    </div>
                                                </div>
                                                <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                    <span>
                                                        <i className="la la-calendar" /> 10 Jan 2024
                                                    </span>
                                                    <ul className="icons-social">
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-phone-volume" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="lab la-facebook-messenger" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-swatchbook" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="kanban-list-items">
                                    <div className="kanban-list-head">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="kanban-title-head dot-info">
                                                <h5>CLIENT</h5>
                                                <span>45 Leads - $15,44,540</span>
                                            </div>
                                            <div className="kanban-action-btns d-flex align-items-center">

                                                <div className="dropdown dropdown-action">
                                                    <a
                                                        href="#"
                                                        className="action-icon dropdown-toggle"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        <i className="material-icons">more_vert</i>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a
                                                            className="dropdown-item"
                                                            href="#"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit_deals"
                                                        >
                                                            <i className="fa-solid fa-pencil m-r-5" /> Edit
                                                        </a>
                                                        <a
                                                            className="dropdown-item"
                                                            href="#"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#delete_deals"
                                                        >
                                                            <i className="fa-regular fa-trash-can m-r-5" /> Delete
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="kanban-drag-wrap">
                                        <li>
                                            <div className="kanban-card">
                                                <div className="kanban-card-head">
                                                    <span className="bar-design bg-purple" />
                                                    <div className="kanban-card-title">

                                                        {/* <h6>Howell, Tremblay and Rath</h6> */}
                                                    </div>
                                                </div>
                                                <div className="kanban-card-body">
                                                    <ul>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-dollar-sign" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-envelope" />
                                                            <a
                                                                href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                className="__cf_email__"
                                                                data-cfemail="1d797c6f717878725d78657c706d7178337e7270"
                                                            >
                                                                [email&nbsp;protected]
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            +1 12445-47878
                                                        </li>
                                                        {/* <li>
                                                            <i className="la la-map-marker-alt" />
                                                            Newyork, United States
                                                        </li> */}
                                                    </ul>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        {/* <div className="card-user-info">
                                                            <a href="contact-details.html" className="avatar">

                                                                <img src="assets/img/avatar/avatar-19.jpg" alt="Img" />

                                                            </a>
                                                            <a href="contact-details.html" className="user-name">
                                                                Darlee Robertson
                                                            </a>
                                                        </div> */}
                                                        <span className="badge badge-soft-info">85%</span>
                                                    </div>
                                                </div>
                                                <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                    <span>
                                                        <i className="la la-calendar" /> 10 Jan 2024
                                                    </span>
                                                    <ul className="icons-social">
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-phone-volume" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="lab la-facebook-messenger" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-swatchbook" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="kanban-card">
                                                <div className="kanban-card-head">
                                                    <span className="bar-design bg-purple" />
                                                    <div className="kanban-card-title">

                                                        {/* <h6>Howell, Tremblay and Rath</h6> */}
                                                    </div>
                                                </div>
                                                <div className="kanban-card-body">
                                                    <ul>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-dollar-sign" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-envelope" />
                                                            <a
                                                                href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                className="__cf_email__"
                                                                data-cfemail="1d797c6f717878725d78657c706d7178337e7270"
                                                            >
                                                                [email&nbsp;protected]
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            +1 12445-47878
                                                        </li>
                                                        {/* <li>
                                                            <i className="la la-map-marker-alt" />
                                                            Newyork, United States
                                                        </li> */}
                                                    </ul>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        {/* <div className="card-user-info">
                                                            <a href="contact-details.html" className="avatar">

                                                                <img src="assets/img/avatar/avatar-19.jpg" alt="Img" />

                                                            </a>
                                                            <a href="contact-details.html" className="user-name">
                                                                Darlee Robertson
                                                            </a>
                                                        </div> */}
                                                        <span className="badge badge-soft-info">85%</span>
                                                    </div>
                                                </div>
                                                <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                    <span>
                                                        <i className="la la-calendar" /> 10 Jan 2024
                                                    </span>
                                                    <ul className="icons-social">
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-phone-volume" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="lab la-facebook-messenger" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-swatchbook" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="kanban-card">
                                                <div className="kanban-card-head">
                                                    <span className="bar-design bg-purple" />
                                                    <div className="kanban-card-title">

                                                        {/* <h6>Howell, Tremblay and Rath</h6> */}
                                                    </div>
                                                </div>
                                                <div className="kanban-card-body">
                                                    <ul>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-dollar-sign" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-envelope" />
                                                            <a
                                                                href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                className="__cf_email__"
                                                                data-cfemail="1d797c6f717878725d78657c706d7178337e7270"
                                                            >
                                                                [email&nbsp;protected]
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            +1 12445-47878
                                                        </li>
                                                        {/* <li>
                                                            <i className="la la-map-marker-alt" />
                                                            Newyork, United States
                                                        </li> */}
                                                    </ul>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        {/* <div className="card-user-info">
                                                            <a href="contact-details.html" className="avatar">

                                                                <img src="assets/img/avatar/avatar-19.jpg" alt="Img" />

                                                            </a>
                                                            <a href="contact-details.html" className="user-name">
                                                                Darlee Robertson
                                                            </a>
                                                        </div> */}
                                                        <span className="badge badge-soft-info">85%</span>
                                                    </div>
                                                </div>
                                                <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                    <span>
                                                        <i className="la la-calendar" /> 10 Jan 2024
                                                    </span>
                                                    <ul className="icons-social">
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-phone-volume" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="lab la-facebook-messenger" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-swatchbook" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="kanban-list-items">
                                    <div className="kanban-list-head">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="kanban-title-head dot-danger">
                                                <h5>OTHERS</h5>
                                                <span>45 Leads - $15,44,540</span>
                                            </div>
                                            <div className="kanban-action-btns d-flex align-items-center">

                                                <div className="dropdown dropdown-action">
                                                    <a
                                                        href="#"
                                                        className="action-icon dropdown-toggle"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        <i className="material-icons">more_vert</i>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a
                                                            className="dropdown-item"
                                                            href="#"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit_deals"
                                                        >
                                                            <i className="fa-solid fa-pencil m-r-5" /> Edit
                                                        </a>
                                                        <a
                                                            className="dropdown-item"
                                                            href="#"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#delete_deals"
                                                        >
                                                            <i className="fa-regular fa-trash-can m-r-5" /> Delete
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="kanban-drag-wrap">
                                        <li>
                                            <div className="kanban-card">
                                                <div className="kanban-card-head">
                                                    <span className="bar-design bg-purple" />
                                                    <div className="kanban-card-title">

                                                        {/* <h6>Howell, Tremblay and Rath</h6> */}
                                                    </div>
                                                </div>
                                                <div className="kanban-card-body">
                                                    <ul>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-dollar-sign" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-envelope" />
                                                            <a
                                                                href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                className="__cf_email__"
                                                                data-cfemail="1d797c6f717878725d78657c706d7178337e7270"
                                                            >
                                                                [email&nbsp;protected]
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            +1 12445-47878
                                                        </li>
                                                        {/* <li>
                                                            <i className="la la-map-marker-alt" />
                                                            Newyork, United States
                                                        </li> */}
                                                    </ul>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        {/* <div className="card-user-info">
                                                            <a href="contact-details.html" className="avatar">

                                                                <img src="assets/img/avatar/avatar-19.jpg" alt="Img" />

                                                            </a>
                                                            <a href="contact-details.html" className="user-name">
                                                                Darlee Robertson
                                                            </a>
                                                        </div> */}
                                                        <span className="badge badge-soft-info">85%</span>
                                                    </div>
                                                </div>
                                                <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                    <span>
                                                        <i className="la la-calendar" /> 10 Jan 2024
                                                    </span>
                                                    <ul className="icons-social">
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-phone-volume" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="lab la-facebook-messenger" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-swatchbook" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="kanban-card">
                                                <div className="kanban-card-head">
                                                    <span className="bar-design bg-purple" />
                                                    <div className="kanban-card-title">

                                                        {/* <h6>Howell, Tremblay and Rath</h6> */}
                                                    </div>
                                                </div>
                                                <div className="kanban-card-body">
                                                    <ul>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-dollar-sign" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-envelope" />
                                                            <a
                                                                href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                className="__cf_email__"
                                                                data-cfemail="1d797c6f717878725d78657c706d7178337e7270"
                                                            >
                                                                [email&nbsp;protected]
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            +1 12445-47878
                                                        </li>
                                                        {/* <li>
                                                            <i className="la la-map-marker-alt" />
                                                            Newyork, United States
                                                        </li> */}
                                                    </ul>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        {/* <div className="card-user-info">
                                                            <a href="contact-details.html" className="avatar">

                                                                <img src="assets/img/avatar/avatar-19.jpg" alt="Img" />

                                                            </a>
                                                            <a href="contact-details.html" className="user-name">
                                                                Darlee Robertson
                                                            </a>
                                                        </div> */}
                                                        <span className="badge badge-soft-info">85%</span>
                                                    </div>
                                                </div>
                                                <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                    <span>
                                                        <i className="la la-calendar" /> 10 Jan 2024
                                                    </span>
                                                    <ul className="icons-social">
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-phone-volume" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="lab la-facebook-messenger" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-swatchbook" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="kanban-card">
                                                <div className="kanban-card-head">
                                                    <span className="bar-design bg-purple" />
                                                    <div className="kanban-card-title">

                                                        {/* <h6>Howell, Tremblay and Rath</h6> */}
                                                    </div>
                                                </div>
                                                <div className="kanban-card-body">
                                                    <ul>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-dollar-sign" />
                                                            $03,50,000
                                                        </li>
                                                        <li>
                                                            <i className="la la-envelope" />
                                                            <a
                                                                href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                className="__cf_email__"
                                                                data-cfemail="1d797c6f717878725d78657c706d7178337e7270"
                                                            >
                                                                [email&nbsp;protected]
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <i className="la la-phone" />
                                                            +1 12445-47878
                                                        </li>
                                                        {/* <li>
                                                            <i className="la la-map-marker-alt" />
                                                            Newyork, United States
                                                        </li> */}
                                                    </ul>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        {/* <div className="card-user-info">
                                                            <a href="contact-details.html" className="avatar">

                                                                <img src="assets/img/avatar/avatar-19.jpg" alt="Img" />

                                                            </a>
                                                            <a href="contact-details.html" className="user-name">
                                                                Darlee Robertson
                                                            </a>
                                                        </div> */}
                                                        <span className="badge badge-soft-info">85%</span>
                                                    </div>
                                                </div>
                                                <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                    <span>
                                                        <i className="la la-calendar" /> 10 Jan 2024
                                                    </span>
                                                    <ul className="icons-social">
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-phone-volume" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="lab la-facebook-messenger" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <i className="la la-swatchbook" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>






    );
}

export default LeadList;