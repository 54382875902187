import React, { useEffect, useState } from "react";
import './DealDetails.css';
import { DealsDetailsActivityLogs } from "./component/DealDetailsActivityLogs";
import { DealDetailsLead } from "./component/DealDetailsLead";
import { DealDetailsMemo } from "./component/DealDetailsMemo";
import { useParams } from "react-router-dom";
import { getDealDetailsApi } from "../ApiCall";
import { getLeadDetailsByID, getLeadListApi } from "../../Leads/ApiCall";


function DealDetails() {

    const [leadDetails, setLeadDetails] = useState({});
    const [dealDetails, setDealDeatils] = useState({});
    const [leadsList, setLeadsList] = useState([]);
    const { id } = useParams();

    useEffect( () => {
        getDealDetails()
    }, [])

    const getDealDetails = async () => {
        const { response, err } = await getDealDetailsApi(id)
        if (!err) {
            var data = { ...response }
            data.closingDate = formatDate(data.closingDate)
            data.date = formatDate(data.date)
            data.nextAction = formatDate(data.nextAction)
            getLeadDetail(data.leadId)
            setDealDeatils(data);
            console.log(response, "Deal Response")
            // console.log(dealInfo, "Deal Ifo")
        }
    }


    const getLeadDetail = async (leadId) => {
        const { response, err } = await getLeadDetailsByID(leadId);
        let leadlist = [];
        if(!err) {
            console.log("lead Response ",response);
            setLeadDetails(response);
            leadlist.push(response);
            setLeadsList(leadlist);

        }
    };

    const formatDate = (dateString) => {
        console.log('dateString', dateString);
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1; 
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (

        <div className="main-wrapper company_view">

            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div class="content container-fluid">
                        <div class="page-header">
                            <div class="row align-items-center">
                                <div class="col-md-4">
                                    <h3 class="page-title">Deal List</h3>
                                    <ul class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="/">Dashboard</a></li>
                                        <li class="breadcrumb-item "><a href="/deal-list">Deal List</a></li>
                                        <li class="breadcrumb-item active">Deal Detaills</li>
                                    </ul>
                                </div>
                                <div class="col-md-8 float-end ms-auto">

                                </div>
                            </div>
                        </div>

                        <hr />
                        <div class="row">

                            <div class="col-md-12">
                                <div class="contact-head">
                                    <div class="row align-items-center">
                                        <div class="col-md-12">
                                            <ul class="contact-breadcrumb">
                                                <li><a href="/deal-list"><i class="las la-arrow-left"></i> Deal List</a></li>
                                                <li>Deal Details</li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <div class="contact-wrap row">
                                    <div class="contact-profile col-md-6">
                                        <div class="avatar company-avatar">
                                        <img src="/assets/img/company/company-01.svg" alt="Default Company Logo" />
                                        </div>
                                        <div class="name-user">
                                            <h4>{dealDetails.name}</h4>
                                            <p><i class="las la-map-marker"></i> 22, Ave Street, Newyork, USA</p>
                                            <div class="badge-rate">
                                                <p><i class="fa-solid fa-star"></i> 5.0</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div class="project-members m-b-15">
                                            <div>Created By:</div>
                                            <ul class="team-members">
                                                <li>
                                                    <a href="#" data-bs-toggle="tooltip" title="John Doe">
                                                    <img src="/assets/img/profiles/avatar-02.jpg"  alt="User Image" /></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div class="project-members m-b-15">
                                            <div>Managed By:</div>
                                            <ul class="team-members">
                                                <li>
                                                    <a href="#" data-bs-toggle="tooltip" title="John Doe">
                                                    <img src="/assets/img/profiles/avatar-02.jpg"  alt="User Image" /></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-xl-3">
                                <div class="card contact-sidebar">

                                    <h5 className="left">General Info</h5>
                                    <ul class="other-info">

                                        <li><span class="other-titlenew">Deal ID</span><span>com 003</span></li>
                                        <li><span class="other-titlenew">Stage</span><span className="badge-soft-info">{dealDetails.stage}</span></li>
                                        <li><span class="other-titlenew">Probability</span><span>{dealDetails.probability}</span></li>
                                        <li><span class="other-titlenew">Company</span><span>Ln Software</span></li>
                                        <li><span class="other-titlenew">Lead Name</span><span>{leadDetails.nameEnglish}</span></li>
                                        <li><span class="other-titlenew">Estimated Revenue</span><span>JPY 150 M</span></li>
                                        <li><span class="other-titlenew">Start Date</span><span>{dealDetails.date}</span></li>
                                        <li><span class="other-titlenew">Closing Date</span><span>{dealDetails.closingDate}</span></li>
                                        <li><span class="other-titlenew">Deal Value</span><span>{dealDetails.value}</span></li>

                                    </ul>
                                    <h5 className="left">Top Deal</h5>
                                    <ul class="other-info">

                                        <div className="card w-100">

                                            <ul className="personal-info" style={{ margin: "8px" }}>

                                                <li className="mb-20">
                                                    <div className="title margintplt wdth0">Revenue generated
                                                    </div>
                                                    <div className="text margintplt">
                                                        Japan
                                                    </div>
                                                </li>

                                            </ul>

                                        </div>
                                        <div className="card w-100 crdbckclr">

                                            <ul className="personal-info" style={{ margin: "8px" }}>

                                                <li className="mb-20">
                                                    <div className="title margintplt wdth0">Invoices created
                                                    </div>
                                                    <div className="text margintplt">
                                                        Japan
                                                    </div>
                                                </li>

                                            </ul>

                                        </div>
                                        <div className="card w-100 crdbckclr2">

                                            <ul className="personal-info" style={{ margin: "8px" }}>

                                                <li className="mb-20">
                                                    <div className="title margintplt wdth0">Estimations create
                                                    </div>
                                                    <div className="text margintplt">
                                                        Japan
                                                    </div>

                                                </li>

                                            </ul>

                                        </div>

                                    </ul>
                                    <h5 class="left">Upcoming Events</h5>
                                    <div class="contact-activity">
                                        <div class="badge-day"><i class="fa-regular fa-calendar-check"></i>15 Feb 2024</div>
                                        <ul>
                                            <li class="activity-wrap">
                                                <span class="activity-icon bg-info">
                                                    <i class="las la-comment-alt"></i>
                                                </span>
                                                <div class="activity-info">
                                                    <h6>You sent 1 Message to the contact.</h6>
                                                    <p>10:25 pm</p>
                                                </div>
                                            </li>
                                            <li class="activity-wrap">
                                                <span class="activity-icon bg-success">
                                                    <i class="las la-phone"></i>
                                                </span>
                                                <div class="activity-info">
                                                    <h6>Denwar responded to your appointment schedule question by call at 09:30pm.</h6>
                                                    <p>09:25 pm</p>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>



                                </div>
                            </div>


                            <div class="col-xl-9">
                                <div class="contact-tab-wrap">
                                    <ul class="contact-nav nav">
                                        <li>
                                            <a href="#" data-bs-toggle="tab" data-bs-target="#activities" class="active"><i class="las la-user-clock"></i>Activities</a>
                                        </li>
                                        <li>
                                            <a href="#" data-bs-toggle="tab" data-bs-target="#notes"><i class="las la-file"></i>Leads (9)</a>
                                        </li>
                                        <li>
                                            <a href="#" data-bs-toggle="tab" data-bs-target="#calls"><i class="las la-file"></i>Memo (6)</a>
                                        </li>

                                    </ul>
                                </div>

                                <div class="contact-tab-view">
                                    <div class="tab-content pt-0">

                                        <div class="tab-pane active show" id="activities">
                                            <div class="view-header">
                                                <h4>Activities</h4>

                                            </div>
                                           <DealsDetailsActivityLogs />
                                        </div>


                                        <div class="tab-pane fade" id="notes">
                                            <div class="view-header">
                                                <h4>Leads</h4>

                                            </div>
                                           <DealDetailsLead leadsList={leadsList}/>
                                        </div>


                                        <div class="tab-pane fade" id="calls">
                                            <div class="view-header">
                                                <h4>Memo </h4>
                                            
                                            </div>
                                           <DealDetailsMemo />
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div >


    );
}

export default DealDetails;