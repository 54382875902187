import React, { useState } from "react";
import './SalesDashboard.css';
import HalfDoughnutChart from "../../components/common/BarChart";
import GraphChart from "../../components/common/GraphChart";
import BarChart from "../../components/common/GraphChart";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";


function SalesDashboard() {

    const [effortconversation, setEffortConversation] = useState('weekly');
    const [data, setData] = useState({
        yearly: { totalDeals: 100, totalRevenue: 50000, totalCustomers: 300 },
        quarterly: { totalDeals: 25, totalRevenue: 12500, totalCustomers: 75 },
        monthly: { totalDeals: 8, totalRevenue: 4000, totalCustomers: 25 },
    });

    const handleClick = (viewType) => {
        setEffortConversation(viewType);
    };

    const [dealconversation, setDealConversation] = useState('weekly');

    const onhandleClick = (item) => {

        setDealConversation(item);
    }


    return (
        <div className="page-wrapper custom-salesdashboard">
            <div className="content container-fluid pb-0">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-12 col-lg-4 col-md-4 col-xl-4">
                            <h3 className="page-title">Welcome Sales!</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ul>
                        </div>
                        {/* <div className="col-sm-12 col-lg-8 col-md-8 col-xl-8">
                            <Link to="/estimate-create" className="btn add-btn mb-3"><i className="fa-solid fa-plus" />Add Lead</Link>
                            <Link to="/estimate-create" className="btn add-btn mb-3"><i className="fa-solid fa-plus" />Add Deal</Link>
                            <Link to="/create-invoice" className="btn add-btn mb-3"><i className="fa-solid fa-plus" />Add Company</Link>

                        </div> */}

                    </div>
                </div>
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6">       
                        {/* <div className="row mb-4"> */}
                            {/* <div className="col-12"> */}
                                {/* <div className="d-flex"> */}
                                    <div className="btn box-icon">
                                        <i className="fa fa-users" />
                                        <span className="ms-3">01</span>
                                    </div>
                                    <div className="btn box-icon">
                                        <i className="fa fa-fire" />
                                        <span className="ms-3">01</span>
                                    </div>
                                    <div className="btn box-icon ">
                                        <i className="fa fa-spinner" />
                                        <span className="ms-3">01</span>
                                    </div>
                                    <div className="btn box-icon">
                                        <i className="fa fa-cog" />
                                        <span className="ms-3">01</span>
                                    </div>
                                    <div className="btn box-icon">
                                        <i className="fa fa-fire" />
                                        <span className="ms-3">01</span>
                                    </div>
                                    <div className="btn box-icon">
                                        <i className="fa fa-link" />
                                        <span className="ms-3">01</span>
                                    </div>
                                    <div className="btn box-icon">
                                        <i className="fa fa-ellipsis-h" />
                                        <span className="ms-3">01</span>
                                    {/* </div> */}
                                {/* </div> */}
                            </div>
                       
                    {/* </div> */}
                        </div>
                        <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6">
                            <Link to="/estimate-create" className="btn add-btn mb-3"><i className="fa-solid fa-plus" />Add Lead</Link>
                            <Link to="/estimate-create" className="btn add-btn mb-3"><i className="fa-solid fa-plus" />Add Deal</Link>
                            <Link to="/create-invoice" className="btn add-btn mb-3"><i className="fa-solid fa-plus" />Add Company</Link>

                        </div>

                    </div>
                </div>
                <div className="row">
                    {/* <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6"> */}
                    <div className="row">
                        <div className="col-md-4 col-sm-6 col-lg-4 col-xl-4">
                            <div className="card dash-widget">
                                <div className="card-body">
                                    <span className="dash-widget-icon"><i className="fa-solid fa-cubes" /></span>
                                    <div className="dash-widget-info">
                                        <h4>01</h4>
                                        <span>Total Company</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-lg-4 col-xl-4">
                            <div className="card dash-widget">
                                <div className="card-body">
                                    <span className="dash-widget-icon"><i className="fa-solid fa-dollar-sign" /></span>
                                    <div className="dash-widget-info">

                                        <h4>05</h4>
                                        <span>Total Leads</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-lg-4 col-xl-4">
                            <div className="card dash-widget">
                                <div className="card-body">
                                    <span className="dash-widget-icon"><i className="fa-regular fa-gem" /></span>
                                    <div className="dash-widget-info">

                                        <h4>0</h4>
                                        <span>Total Deals</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* </div> */}
                    {/* <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6">
                        <div className="row mb-4">
                            <div className="col-12">
                                <div className="d-flex">
                                    <div className="btn box-icon me-3 mb-3">
                                        <i className="fa fa-users" />
                                        <span className="ms-3">01</span>
                                    </div>
                                    <div className="btn box-icon me-3 mb-3">
                                        <i className="fa fa-fire" />
                                        <span className="ms-3">01</span>
                                    </div>
                                    <div className="btn box-icon me-3 mb-3">
                                        <i className="fa fa-spinner" />
                                        <span className="ms-3">01</span>
                                    </div>
                                    <div className="btn box-icon me-3 mb-3">
                                        <i className="fa fa-cog" />
                                        <span className="ms-3">01</span>
                                    </div>
                                    <div className="btn box-icon me-3 mb-3">
                                        <i className="fa fa-fire" />
                                        <span className="ms-3">01</span>
                                    </div>
                                    <div className="btn box-icon me-3 mb-3">
                                        <i className="fa fa-link" />
                                        <span className="ms-3">01</span>
                                    </div>
                                    <div className="btn box-icon me-3 mb-3">
                                        <i className="fa fa-ellipsis-h" />
                                        <span className="ms-3">01</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>



                {/* <div className="row mb-4">
                    <div className="col-6 col-md-4 col-lg-2 mb-3">
                        <div className="btn box-icon">
                            <i className="fa fa-users" />
                            <span className="ms-3">01</span>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 mb-3">
                        <div className="btn box-icon">
                            <i className="fa fa-fire" />
                            <span className="ms-3">01</span>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 mb-3">
                        <div className="btn box-icon">
                            <i className="fa fa-spinner" />
                            <span className="ms-3">01</span>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 mb-3">
                        <div className="btn box-icon">
                            <i className="fa fa-cog" />
                            <span className="ms-3">01</span>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 mb-3">
                        <div className="btn box-icon">
                            <i className="fa fa-fire" />
                            <span className="ms-3">01</span>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 mb-3">
                        <div className="btn box-icon">
                            <i className="fa fa-link" />
                            <span className="ms-3">01</span>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 mb-3">
                        <div className="btn box-icon">
                            <i className="fa fa-ellipsis-h" />
                            <span className="ms-3">01</span>
                        </div>
                    </div>
                </div> */}


                <div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-xl-6 col-sm-12">
                            <div className="background-target border-to-graph">
                                <div className="border-width">
                                    <div className="statistic-header">
                                        <h4>Lead Generation</h4>
                                    </div>
                                    <GraphChart />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-6 col-sm-12 d-flex">
                            <div className="card analytics-card w-100">
                                <div className="card-body">
                                    <div className="statistic-header">
                                        <h4>Deal Conversation</h4>
                                        <div>
                                            <button
                                                className={`deal-btn ${dealconversation === 'weekly' ? 'deal-active' : ''}`}
                                                onClick={() => onhandleClick('weekly')}
                                            >
                                                Weekly
                                            </button>
                                            <button
                                                className={`deal-btn ${dealconversation === 'quarterly' ? 'deal-active' : ''}`}
                                                onClick={() => onhandleClick('quarterly')}
                                            >
                                                Quarterly
                                            </button>
                                        </div>
                                    </div>
                                    {dealconversation === 'weekly' && (
                                        <div className="table-responsive">
                                            <table className="table custom-table table-nowrap mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Weekly</th>
                                                        <th>Client</th>
                                                        <th>Hot</th>
                                                        <th>Warm</th>
                                                        <th>Cold</th>
                                                        <th>Contact</th>
                                                        <th>Dead</th>
                                                        <th>Other</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>This Week</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                    </tr>
                                                    <tr>
                                                        <td>1 Week Ago</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2 Weeks Ago</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3 Weeks Ago</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Total</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>)}
                                    {dealconversation === 'quarterly' && (
                                        <div className="table-responsive">
                                            <table className="table custom-table table-nowrap mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Quarterly</th>
                                                        <th>Client</th>
                                                        <th>Hot</th>
                                                        <th>Warm</th>
                                                        <th>Cold</th>
                                                        <th>Contact</th>
                                                        <th>Dead</th>
                                                        <th>Other</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>This Week</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                    </tr>
                                                    <tr>
                                                        <td>1 Week Ago</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2 Weeks Ago</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3 Weeks Ago</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Total</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>)}
                                </div>
                            </div>
                        </div>


                        {/* ----second section---- */}



                        <div className="col-md-12 col-lg-12 col-xl-6 col-sm-12 mt-3">
                            <div className="background-target border-to-graph">
                                <div className="border-width">
                                    <div className="statistic-header">
                                        <div className="col-md-6 col-sm-12"><h4 className="pd-10">Invoice of the Month</h4></div>
                                        <div className="col-md-6 col-sm-12"><div className="text-end"><i className="fas fa-chevron-left border-arrow" /><span className="border-page">2019/6 </span> <i className="fas fa-chevron-right border-arrow" /></div></div>


                                    </div>
                                    <table className="table custom-table table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th>Lead Name</th>
                                                <th>Company Name</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>N/A</td>
                                                <td>N/A</td>

                                            </tr>
                                            <tr>
                                                <td>N/A</td>
                                                <td>N/A</td>


                                            </tr>
                                            <tr>
                                                <td>N/A</td>
                                                <td>N/A</td>

                                            </tr>
                                            <tr>
                                                <td>N/A</td>
                                                <td>N/A</td>

                                            </tr>
                                            <tr>
                                                <td>N/A</td>
                                                <td>N/A</td>

                                            </tr>
                                            <tr>
                                                <td>N/A</td>
                                                <td>N/A</td>

                                            </tr>
                                            <tr>
                                                <td>N/A</td>
                                                <td>N/A</td>


                                            </tr>
                                            <tr>
                                                <td>N/A</td>
                                                <td>N/A</td>

                                            </tr>
                                            <tr>
                                                <td>N/A</td>
                                                <td>N/A</td>

                                            </tr>
                                            <tr>
                                                <td>N/A</td>
                                                <td>N/A</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>




                        <div className="col-md-12 col-lg-12 col-xl-6 col-sm-12 mt-3">
                            <div className="background-target border-to-graph">
                                <div className="border-width">
                                    <div className="statistic-header">
                                        <div className="col-md-6 col-sm-12"><h4 className="pd-10">Deal of the Month</h4></div>
                                        <div className="col-md-6 col-sm-12"><div className="text-end"><i className="fas fa-chevron-left border-arrow" /><span className="border-page">2019/6 </span> <i className="fas fa-chevron-right border-arrow" /></div></div>

                                    </div>
                                    <table className="table custom-table table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th>Lead Name</th>
                                                <th>Company Name</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>N/A</td>
                                                <td>N/A</td>

                                            </tr>
                                            <tr>
                                                <td>N/A</td>
                                                <td>N/A</td>


                                            </tr>
                                            <tr>
                                                <td>N/A</td>
                                                <td>N/A</td>

                                            </tr>
                                            <tr>
                                                <td>N/A</td>
                                                <td>N/A</td>

                                            </tr>
                                            <tr>
                                                <td>N/A</td>
                                                <td>N/A</td>

                                            </tr>
                                            <tr>
                                                <td>N/A</td>
                                                <td>N/A</td>

                                            </tr>
                                            <tr>
                                                <td>N/A</td>
                                                <td>N/A</td>


                                            </tr>
                                            <tr>
                                                <td>N/A</td>
                                                <td>N/A</td>

                                            </tr>
                                            <tr>
                                                <td>N/A</td>
                                                <td>N/A</td>

                                            </tr>
                                            <tr>
                                                <td>N/A</td>
                                                <td>N/A</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className="row mt-4">
                        <div className="col-md-12 col-lg-12 col-xl-6 col-sm-12">
                            <div className="background-target border-to-graph">
                                <div className="border-width">
                                    <div className="statistic-header">
                                        <h4>Lead Generation</h4>
                                    </div>
                                    <GraphChart />
                                </div>
                            </div>
                        </div>


                        <div className="col-md-12 col-lg-12 col-xl-6 col-sm-12 d-flex">
                            <div className="card analytics-card w-100">
                                <div className="card-body">
                                    <div className="statistic-header">
                                        <h4>Effort Measurement</h4>
                                        <div>
                                            <button
                                                className={`deal-btn ${effortconversation === 'weekly' ? 'deal-active' : ''}`}
                                                onClick={() => handleClick('weekly')}
                                            >
                                                Weekly
                                            </button>
                                            <button
                                                className={`deal-btn ${effortconversation === 'quarterly' ? 'deal-active' : ''}`}
                                                onClick={() => handleClick('quarterly')}
                                            >
                                                Quarterly
                                            </button>
                                        </div>
                                    </div>
                                    {effortconversation === 'weekly' && (
                                        <div className="table-responsive">
                                            <table className="table custom-table table-nowrap mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Weekly</th>
                                                        <th>Client</th>
                                                        <th>Hot</th>
                                                        <th>Warm</th>
                                                        <th>Cold</th>
                                                        <th>Contact</th>
                                                        <th>Dead</th>
                                                        <th>Other</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>This Week</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                    </tr>
                                                    <tr>
                                                        <td>1 Week Ago</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2 Weeks Ago</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3 Weeks Ago</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Total</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>)}
                                    {effortconversation === 'quarterly' && (
                                        <div className="table-responsive">
                                            <table className="table custom-table table-nowrap mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Quarterly</th>
                                                        <th>CLIENT</th>
                                                        <th>HOT</th>
                                                        <th>WARM</th>
                                                        <th>COLD</th>
                                                        <th>CONTACT</th>
                                                        <th>DEAD</th>
                                                        <th>OTHER</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>This Week</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                    </tr>
                                                    <tr>
                                                        <td>1 Week Ago</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2 Weeks Ago</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3 Weeks Ago</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Total</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                        <td><strong>-</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>)}
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 mt-4">
                        <div className="card analytics-card w-100">
                            <div className="card-body">
                                <div className="statistic-header">
                                    <h4>Todo</h4>
                                </div>
                                <div className="table-responsive">
                                    <table className="table custom-table table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th>Company Name</th>
                                                <th>Lead</th>
                                                <th>Status</th>
                                                <th>Action Type</th>
                                                <th>Date</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Bi Worldwide India Pvt Ltd</td>
                                                <td>New Contact person</td>
                                                <td><button className="Hot-btn">HOT</button></td>
                                                <td>Tel</td>
                                                <td>2019 / 11 / 30 </td>

                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>





            </div>
            <Footer />
        </div >



    )
}
export default SalesDashboard;