import React, { useEffect, useRef, useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import "./DealList.css"
import Select from 'react-select';
import GridTable from "../../../components/common/GridTable";
import { createPortal } from 'react-dom';
import { getCompanyListApi, getCountryListApi, getStateListApi, getCompanyDetailsApi } from "../../Company/ApiCall";
import { getDealListApi, deleteDealByIDApi } from "../ApiCall";
import { getLeadDetailsByID } from "../../Leads/ApiCall";
import {toast} from "react-toastify";
import { CSVLink } from "react-csv";

function DealList() {
    const [listtype, setListType] = useState("List")
    const [selectedfilter, setSelectedfilter] = useState("active")
    const [bgColor, setBgColor] = useState("white");
    const [bgColor2, setBgColor2] = useState("white");
    const [bgColor3, setBgColor3] = useState("white");
    const [companyList, setCompanyList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const currentDate = new Date().toISOString().split('T')[0];

    const [selectedValues, setSelectedValues] = useState({
        myleads: null,
        status: null,
        stages: null,
        company: null,
        country: null,
        state: null,
    });
     const [dealList, setDealList] = useState([]);
    const [IntialDealList, setIntialDealList] = useState([]);
    const navigate = useNavigate();

     useEffect(() => {
        getCompanyList();
        getCountryList();
        getDealsList();
    }, [])

const getCompanyList = async() => {
        const { response, err } = await getCompanyListApi();
        console.log("getCompanyList ", response)
        if (!err) {
            const mapped = response.map(({ id, companyen, ...rest }) => ({
                value: id,
                label: companyen,
                ...rest,
            }))
            setCompanyList(mapped)
        }
        console.log("err", err)
    }

    const getCountryList = async () => {
        const { response, err } = await getCountryListApi();
        if (!err) {
          const mappedCountries = response.map(({ id, name, ...rest }) => ({
            value: id,
            label: name,
            ...rest,
          }));
          setCountryList([...mappedCountries]);
        }
        console.log("err", err);
      };
    

      const SelectCountryFunc = async (id) => {
        console.log('SelectCpunty Func', id);
        const { response, err } = await getStateListApi(id);
        console.log("responsetrtrtrtr", response);

        if (response != "State not found") {
            const mappedSates = response.map(({ id, name, ...rest }) => ({
                value: id,
                label: name,
                ...rest,
            }));
            setSelectedValues({
                ...selectedValues,
                state: null
            })
            setStateList(mappedSates);
        }
        console.log("err", err);
    };

    const getDealsList = async () => {
        const { response, err } = await getDealListApi();
        console.log("response 1", response)
        if (!err) {
            let leadDetails = response;
            const updatedLeadList = await Promise.all(
                leadDetails.map(async (value, index) => {
                    try {
                        const leadData = await getLeadDetailsByID(value.leadId);
                        const compData = leadData.response;
                        return {
                            ...value,
                            leadname: compData.nameEnglish 
                        };
                    } catch (error) {
                        console.error("Error fetching company name", error);
                        return value; 
                    }
                })
            );
    
            setDealList(updatedLeadList); 
            setIntialDealList(updatedLeadList); 
            setCsvData(formatDataToCsv(updatedLeadList))
        }
    }

    const DealDeleteFunc = async (id, index) => {
        const {response, err} = await deleteDealByIDApi(id);
        if (!err) {
            console.log(response, "Deal deleted response")
            toast.success("Record deleted successfully!")
            window.location.reload();
            // navigate('/deal-list')
        }
    }

    const CustomCellRenderer = ({ data, node }) => {

        const [showPopup, setShowPopup] = useState(false);
        const [position, setPosition] = useState({ top: 0, left: 0 });
        const handleButtonClick = (event) => {
            const rect = event.target.getBoundingClientRect();
            setPosition({
                top: rect.top + window.scrollY + rect.height,
                left: rect.left + window.scrollX,
            });
            setShowPopup(!showPopup);
        };

        const handleClosePopup = () => {
            setShowPopup(false);
        };

        return (
            <div>
                <a onClick={handleButtonClick}>  <i className="material-icons">more_vert</i></a>
                {showPopup && createPortal(
                    <div style={{ ...popupStyle, top: position.top, left: position.left }}>
                        <ul>
                            <Link
                                className="dropdown-item"
                                to={`/deal-edit/${data.id}`}
                            >
                                <i className="fa-solid fa-pencil m-r-5" /> Edit
                            </Link>
                            <Link
                                className="dropdown-item"
                                to={`/deal-details/${data.id}`}
                            >
                                <i className="fa-regular fa-eye" /> View
                            </Link>
                            <a
                                onClick={() => DealDeleteFunc(data.id, node.rowIndex)}
                                className="dropdown-item"
                            >
                                <i className="las la-trash" /> Delete
                            </a>
                        </ul>
                    </div>,
                    document.body
                )}
            </div>
        );
    };

    const handleChange = (selectedOption) => {
        setBgColor("lightgrey");
        console.log(`Selected: ${selectedOption.label}`);
    };
    const handleChange2 = (selectedOption) => {
        setBgColor2("lightgrey");
        console.log(`Selected: ${selectedOption.label}`);
    };
    const handleChange3 = (selectedOption) => {
        setBgColor3("lightgrey");
        console.log(`Selected: ${selectedOption.label}`);
    };

    const [popupMenu, setPopupMenu] = useState({
        isOpen: false,
        x: 0,
        y: 0,
        rowId: null,
    });
    const [columnDefs, setColumnsDefs] = useState([
        {
            headerName: 'Name', field: 'name', hide: false
        },
        {
            headerName: 'Lead Name', field: 'leadname',
            cellRenderer: (props) => (
                <Link
                    to={`/deal-details/${props.data.id}`}
                >
                    {props.value}
                </Link>

            ),
            hide: false
        },
    
        { headerName: 'Stage', field: 'stage', },
        { headerName: 'Deal Value', field: 'value', },
        { headerName: 'Probability', field: 'probability', 
            valueFormatter: (params) => `${(params.value).toFixed(0)}%` 
        },
        { headerName: 'Date', field: 'date', 
            valueFormatter: (params) => {
                const date = new Date(params.value);
                return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
            }
        },
        { headerName: 'Closing Date', field: 'closingDate', 
            valueFormatter: (params) => {
                const date = new Date(params.value);
                return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
            }
        },

        {
            headerName: '',
            field: 'actions',
            cellRenderer: CustomCellRenderer
        },
    ]);
   
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setPopupMenu({ ...popupMenu, isOpen: false });
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [popupMenu]);

    const popupStyle = {
        position: 'absolute',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        padding: '10px',
        zIndex: 1000, // Ensure it appears above the grid
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    };

    const formatDataToCsv = (data) => {

        const headers = columnDefs.map((col) => col.headerName).filter(header => header !== '');
        const rows = data.map((item) => columnDefs.map(col => {
            if (col.field === 'country') {
                return item.address.countryId?.name || ''; 
            }
            if (col.field === 'state') {
                return item.address.stateId?.name || ''; 
            }
            if (col.field === 'value') {
                return item[col.field] || 0; 
            }
            if(col.field === 'probability') {
                return `${(item[col.field]).toFixed(0)}%`  || '0%'; 
            }
            if(col.field === 'date') {
                const date = new Date(item[col.field]);
                return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }); 
            }
            if(col.field === 'closingDate') {
                const date = new Date(item[col.field]);
                return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }); 
            }
            return item[col.field] || ''; 
        }));
        return [headers, ...rows];
    };

    return (

        <div className="main-wrapper deal-list">

            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col-xl-4">
                                <h3 className="page-title">Deal List</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Deal List</li>
                                </ul>
                            </div>
                            <div className="col-xl-8 float-end ms-auto">
                                <div className="d-flex title-head">

                                    <Link

                                        className="btn add-btn contrast-button "

                                    >
                                        <i className="las la-file-download" />Import
                                    </Link>
                                    <CSVLink data={csvData} 
                                       filename={`Deallist_${currentDate}.csv`}
                                        className="btn add-btn contrast-button">
                                        <i className="las la-file-export"/> Export
                                    </CSVLink>
                                    <Link
                                        to="/deal-create"
                                        className="btn add-btn "

                                    >
                                        <i className="la la-plus-circle" />Create
                                    </Link>


                                    <div className="search-set">
                                        <div className="search-input">
                                            <a className="btn btn-searchset cursor-none">
                                                <i className="las la-search" />
                                            </a>
                                            <div className="dataTables_filter">
                                                <label>
                                                    {" "}
                                                    <input

                                                        type="text"
                                                        className="form-control pd-left-20 ht-35"
                                                        placeholder="Search"
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row bg-light-blue">

                        <p className="pt-3  heading">New deals this Week</p>
                        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-4">
                            <div className="card dash-widget bg-white">
                                <div className="card-body display-block">
                                    <div class="profile-view row   align-items-center">
                                        <div class="profile-img-wrap col-md-2">
                                            <div class="profile-img">
                                                <a href="#">
                                                    <img src="/assets/img/profiles/avatar-19.jpg" alt="User Image" />
                                                </a>
                                            </div>
                                        </div>
                                        <div class="profile-basic col-md-8">

                                            <div class="profile-info-left">
                                                <h3 class="user-name m-t-0">LN Infotech</h3>
                                                <div class="text-muted mt-2">   <img className="capitial-image" src={require("../../../assets/phone-call.png")} /> 79945 45784</div>
                                                <div class="text-muted mt-2">   <img className="capitial-image" src={require("../../../assets/email.png")} /> ln123@gmail.com</div>
                                                <div class="text-muted mt-2">   <img className="capitial-image" src={require("../../../assets/progress.png")} /> Potential</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-4">
                            <div className="card dash-widget bg-white">
                                <div className="card-body display-block">
                                    <div class="profile-view row   align-items-center">
                                        <div class="profile-img-wrap col-md-2">
                                            <div class="profile-img">
                                                <a href="#">
                                                    <img src="/assets/img/profiles/avatar-19.jpg" alt="User Image" />
                                                </a>
                                            </div>
                                        </div>
                                        <div class="profile-basic col-md-8">

                                            <div class="profile-info-left">
                                                <h3 class="user-name m-t-0">LN Infotech</h3>
                                                <div class="text-muted mt-2">   <img className="capitial-image" src={require("../../../assets/phone-call.png")} /> 79945 45784</div>
                                                <div class="text-muted mt-2">   <img className="capitial-image" src={require("../../../assets/email.png")} /> ln123@gmail.com</div>
                                                <div class="text-muted mt-2">   <img className="capitial-image" src={require("../../../assets/progress.png")} /> Potential</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-4">
                            <div className="card dash-widget bg-white">
                                <div className="card-body display-block">
                                    <div class="profile-view row   align-items-center">
                                        <div class="profile-img-wrap col-md-2">
                                            <div class="profile-img">
                                                <a href="#">
                                                    <img src="/assets/img/profiles/avatar-19.jpg" alt="User Image" />
                                                </a>
                                            </div>
                                        </div>
                                        <div class="profile-basic col-md-8">

                                            <div class="profile-info-left">
                                                <h3 class="user-name m-t-0">LN Infotech</h3>
                                                <div class="text-muted mt-2">   <img className="capitial-image" src={require("../../../assets/phone-call.png")} /> 79945 45784</div>
                                                <div class="text-muted mt-2">   <img className="capitial-image" src={require("../../../assets/email.png")} /> ln123@gmail.com</div>
                                                <div class="text-muted mt-2">   <img className="capitial-image" src={require("../../../assets/progress.png")} /> Potential</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="filter-fields mt-5 " style={{ marginLeft: '20px' }}>
                        <div className="row filter-row">
                            <div className="col-xl-1 col-md-3 col-sm-6 pr-0 company-filter">
                                <div className="input-block mb-3 form-focus select-focus" >
                                    <Select
                                        style={{ backgroundColor: bgColor, padding: '10px', borderRadius: '4px' }}
                                        placeholder="Leads"
                                        options={[
                                            { value: 'All Leads', label: 'All Leads' },
                                            { value: 'My Leads', label: 'My Leads' },
                                        ]}
                                        onChange={handleChange}
                                        styles={{
                                            control: (styles) => ({
                                                ...styles,
                                                backgroundColor: bgColor,
                                            }),
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-1 col-md-3 col-sm-6 filter-status pr-0">
                                <div className="input-block mb-3 form-focus select-focus" >
                                    <Select
                                        style={{ backgroundColor: bgColor2, padding: '10px', borderRadius: '4px' }}
                                        placeholder="Status"
                                        options={[
                                            // { value: 'Status', label: 'Status' },
                                            { value: 'Active', label: 'Active' },
                                            { value: 'InActive', label: 'InActive' },
                                        ]}
                                        onChange={handleChange2}
                                        styles={{
                                            control: (styles) => ({
                                                ...styles,
                                                backgroundColor: bgColor2,
                                            }),
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-1 col-md-3 col-sm-6 pr-0 width-14">
                                <div className="input-block mb-3 form-focus select-focus" >
                                    <Select
                                        style={{ backgroundColor: bgColor3, padding: '10px', borderRadius: '4px' }}
                                        placeholder="Stage"
                                        options={[
                                            // { value: 'Stage', label: 'Stage' },
                                            { value: 'Cold', label: 'Cold' },
                                            { value: 'Warm', label: 'Warm' },
                                            { value: 'Hot', label: 'Hot' },
                                            { value: 'Client', label: 'Client' },
                                            { value: 'Lost', label: 'Lost' },

                                        ]}
                                        onChange={handleChange3}
                                        styles={{
                                            control: (styles) => ({
                                                ...styles,
                                                backgroundColor: bgColor3,
                                            }),
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 pr-0 width-14">
                                <div className="view-icons">
                                    <a
                                        onClick={() => setListType("List")}
                                        className={`list-view btn btn-link ${listtype == "List" ? "active" : ""} `}
                                    >
                                        <i className="las la-list" />
                                    </a>
                                    <a
                                        onClick={() => setListType("Kanban")}
                                        className={`grid-view btn btn-link ${listtype == "Kanban" ? "active" : ""} `}

                                    >
                                        <i className="las la-th" />
                                    </a>
                                    <a
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasdropcolumn"
                                        aria-controls="offcanvasdropcolumn"
                                        className="list-view btn btn-link filteroptions"
                                    >
                                        <i className="fa fa-columns" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasdropcolumn" aria-labelledby="offcanvasRightLabel">
                        <div class="offcanvas-header">
                            <h5 id="offcanvasRightLabel">Choose which columns you see</h5>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <div className="EditColumnsModalPresentational__HalfWidthContainer-tehgaw-1 fsFdXZ">
                                <div class="col-lg-12 text-end form-wizard-button mb-3 mt-0">
                                    <button class="button btn-lights reset-btn" type="button" data-bs-dismiss="offcanvas" aria-label="Close">Cancel</button>
                                    <button data-bs-dismiss="offcanvas" aria-label="Close" class="btn btn-primary" type="submit">Apply</button>
                                </div>
                                <div className="private-search-control">
                                    <input
                                        data-selenium-test="edit-columns-search"
                                        type="search"
                                        className="form-control private-form__control private-search-control__input"
                                        placeholder="Search columns..."
                                        defaultValue=""
                                    />

                                </div>

                                <div className="list-content-checkbox">


                                    {columnDefs.filter((e, i) => e.headerName != "").map((e, i) => (

                                        < label className="edit-column-checkbox">
                                            <input checked={e.hide ? false : true} onChange={() => {

                                                var data = [...columnDefs]
                                                data[i].hide = data[i].hide ? false : true
                                                setColumnsDefs(data)

                                            }} type="checkbox" id="myCheckbox" />
                                            <span>{e.headerName}</span>
                                        </label>

                                    ))}

                                </div>

                            </div>

                        </div>
                    </div>

                    {listtype == "List" ?
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive ">

                                    <GridTable
                                        columns={columnDefs}
                                        data={dealList}
                                        pagination={true}
                                        perpage={20}
                                        popupMenu={popupMenu}
                                        menuRef={menuRef}
                                        className="ag-ltr .ag-header-cell-resize"
                                    />
                                </div>
                            </div>
                        </div>
                        :
                        <div className="row">
                            <div className="col-md-12">
                                <div className="kanban-wrapper">
                                    <div className="kanban-list-items">
                                        <div className="kanban-list-head">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="kanban-title-head dot-success">
                                                    <h5>TARGET</h5>
                                                    {/* <span>45 Leads - $15,44,540</span> */}
                                                </div>
                                                <div className="kanban-action-btns d-flex align-items-center">

                                                    <div className="dropdown dropdown-action">
                                                        <a
                                                            href="#"
                                                            className="action-icon dropdown-toggle"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            <i className="material-icons">more_vert</i>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <a
                                                                className="dropdown-item"
                                                                href="#"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#edit_deals"
                                                            >
                                                                <i className="fa-solid fa-pencil m-r-5" /> Edit
                                                            </a>
                                                            <a
                                                                className="dropdown-item"
                                                                href="#"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#delete_deals"
                                                            >
                                                                <i className="fa-regular fa-trash-can m-r-5" /> Delete
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ul className="kanban-drag-wrap">
                                            <li>
                                                <div className="kanban-card">
                                                    <div className="kanban-card-head">
                                                        <span className="bar-design bg-purple" />
                                                        <div className="kanban-card-title">
                                                            <span>HT</span>
                                                            <h6>Howell, Tremblay and Rath</h6>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-body">
                                                        <ul>
                                                            <li>
                                                                <i className="la la-dollar-sign" />
                                                                $03,50,000
                                                            </li>
                                                            <li>
                                                                <i className="la la-envelope" />
                                                                <a
                                                                    href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                    className="__cf_email__"
                                                                    data-cfemail="1d797c6f717878725d78657c706d7178337e7270"
                                                                >
                                                                    [email&nbsp;protected]
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <i className="la la-phone" />
                                                                +1 12445-47878
                                                            </li>
                                                            <li>
                                                                <i className="la la-map-marker-alt" />
                                                                Newyork, United States
                                                            </li>
                                                        </ul>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="card-user-info">
                                                                <a href="contact-details.html" className="avatar">
                                                                    <img src="/assets/img/avatar/avatar-19.jpg" alt="Img" />
                                                                </a>
                                                                <a href="contact-details.html" className="user-name">
                                                                    Darlee Robertson
                                                                </a>
                                                            </div>
                                                            <span className="badge badge-soft-info">85%</span>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                        <span>
                                                            <i className="la la-calendar" /> 10 Jan 2024
                                                        </span>
                                                        <ul className="icons-social">
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-phone-volume" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="lab la-facebook-messenger" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-swatchbook" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="kanban-card">
                                                    <div className="kanban-card-head">
                                                        <span className="bar-design bg-warning" />
                                                        <div className="kanban-card-title">
                                                            <span>RJ</span>
                                                            <h6>Robert, John and Carlos</h6>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-body">
                                                        <ul>
                                                            <li>
                                                                <i className="la la-dollar-sign" />
                                                                $02,10,000
                                                            </li>
                                                            <li>
                                                                <i className="la la-envelope" />
                                                                <a
                                                                    href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                    className="__cf_email__"
                                                                    data-cfemail="82f1eae7f0edecc2e7fae3eff2eee7ace1edef"
                                                                >
                                                                    [email&nbsp;protected]
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <i className="la la-phone" />
                                                                +1 12445-47878
                                                            </li>
                                                            <li>
                                                                <i className="la la-map-marker-alt" />
                                                                Exeter, United States
                                                            </li>
                                                        </ul>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="card-user-info">
                                                                <a href="contact-details.html" className="avatar">
                                                                    <img src="/assets/img/avatar/avatar-20.jpg" alt="Img" />
                                                                </a>
                                                                <a href="contact-details.html" className="user-name">
                                                                    Darlee Robertson
                                                                </a>
                                                            </div>
                                                            <span className="badge badge-soft-info">15%</span>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                        <span>
                                                            <i className="la la-calendar" /> 12 Jan 2024
                                                        </span>
                                                        <ul className="icons-social">
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-phone-volume" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="lab la-facebook-messenger" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-swatchbook" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="kanban-card">
                                                    <div className="kanban-card-head">
                                                        <span className="bar-design bg-success" />
                                                        <div className="kanban-card-title">
                                                            <span>WS</span>
                                                            <h6>Wendy, Star and David</h6>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-body">
                                                        <ul>
                                                            <li>
                                                                <i className="la la-dollar-sign" />
                                                                $04,22,000
                                                            </li>
                                                            <li>
                                                                <i className="la la-envelope" />
                                                                <a
                                                                    href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                    className="__cf_email__"
                                                                    data-cfemail="c1b7a0b481a4b9a0acb1ada4efa2aeac"
                                                                >
                                                                    [email&nbsp;protected]
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <i className="la la-phone" />
                                                                +1 12445-47878
                                                            </li>
                                                            <li>
                                                                <i className="la la-map-marker-alt" />
                                                                Phoenix, United States
                                                            </li>
                                                        </ul>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="card-user-info">
                                                                <a href="contact-details.html" className="avatar">
                                                                    <img src="/assets/img/avatar/avatar-21.jpg" alt="Img" />
                                                                </a>
                                                                <a href="contact-details.html" className="user-name">
                                                                    Darlee Robertson
                                                                </a>
                                                            </div>
                                                            <span className="badge badge-soft-info">95%</span>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                        <span>
                                                            <i className="la la-calendar" /> 14 Jan 2024
                                                        </span>
                                                        <ul className="icons-social">
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-phone-volume" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="lab la-facebook-messenger" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-swatchbook" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="kanban-list-items">
                                        <div className="kanban-list-head">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="kanban-title-head dot-info">
                                                    <h5>POTENTIAL</h5>

                                                </div>
                                                <div className="kanban-action-btns d-flex align-items-center">

                                                    <div className="dropdown dropdown-action">
                                                        <a
                                                            href="#"
                                                            className="action-icon dropdown-toggle"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            <i className="material-icons">more_vert</i>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <a
                                                                className="dropdown-item"
                                                                href="#"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#edit_deals"
                                                            >
                                                                <i className="fa-solid fa-pencil m-r-5" /> Edit
                                                            </a>
                                                            <a
                                                                className="dropdown-item"
                                                                href="#"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#delete_deals"
                                                            >
                                                                <i className="fa-regular fa-trash-can m-r-5" /> Delete
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ul className="kanban-drag-wrap">
                                            <li>
                                                <div className="kanban-card">
                                                    <div className="kanban-card-head">
                                                        <span className="bar-design bg-success" />
                                                        <div className="kanban-card-title">
                                                            <span>BR</span>
                                                            <h6>Byron, Roman and Bailey</h6>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-body">
                                                        <ul>
                                                            <li>
                                                                <i className="la la-dollar-sign" />
                                                                $02,45,000
                                                            </li>
                                                            <li>
                                                                <i className="la la-envelope" />
                                                                <a
                                                                    href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                    className="__cf_email__"
                                                                    data-cfemail="82e8e7f1f1ebe1e3b3b1c2e7fae3eff2eee7ace1edef"
                                                                >
                                                                    [email&nbsp;protected]
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <i className="la la-phone" />
                                                                +1 89351-90346
                                                            </li>
                                                            <li>
                                                                <i className="la la-map-marker-alt" />
                                                                Chester, United States
                                                            </li>
                                                        </ul>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="card-user-info">
                                                                <a href="contact-details.html" className="avatar">
                                                                    <img src="/assets/img/avatar/avatar-1.jpg" alt="Img" />
                                                                </a>
                                                                <a href="contact-details.html" className="user-name">
                                                                    Jessica
                                                                </a>
                                                            </div>
                                                            <span className="badge badge-soft-info">47%</span>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                        <span>
                                                            <i className="la la-calendar" /> 06 Feb 2024
                                                        </span>
                                                        <ul className="icons-social">
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-phone-volume" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="lab la-facebook-messenger" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-swatchbook" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="kanban-card">
                                                    <div className="kanban-card-head">
                                                        <span className="bar-design bg-purple" />
                                                        <div className="kanban-card-title">
                                                            <span>CH</span>
                                                            <h6>Robert, John and Carlos</h6>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-body">
                                                        <ul>
                                                            <li>
                                                                <i className="la la-dollar-sign" />
                                                                $01,17,000
                                                            </li>
                                                            <li>
                                                                <i className="la la-envelope" />
                                                                <a
                                                                    href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                    className="__cf_email__"
                                                                    data-cfemail="325153405d5e465a5d0172574a535f425e571c515d5f"
                                                                >
                                                                    [email&nbsp;protected]
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <i className="la la-phone" />
                                                                +1 78982-09163
                                                            </li>
                                                            <li>
                                                                <i className="la la-map-marker-alt" />
                                                                Charlotte, United States
                                                            </li>
                                                        </ul>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="card-user-info">
                                                                <a href="contact-details.html" className="avatar">
                                                                    <img src="/assets/img/avatar/avatar-16.jpg" alt="Img" />
                                                                </a>
                                                                <a href="contact-details.html" className="user-name">
                                                                    Carol Thomas
                                                                </a>
                                                            </div>
                                                            <span className="badge badge-soft-info">98%</span>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                        <span>
                                                            <i className="la la-calendar" /> 15 Feb 2024
                                                        </span>
                                                        <ul className="icons-social">
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-phone-volume" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="lab la-facebook-messenger" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-swatchbook" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="kanban-card">
                                                    <div className="kanban-card-head">
                                                        <span className="bar-design bg-danger" />
                                                        <div className="kanban-card-title">
                                                            <span>IC</span>
                                                            <h6>Irene, Charles and Wilston</h6>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-body">
                                                        <ul>
                                                            <li>
                                                                <i className="la la-dollar-sign" />
                                                                $02,12,000
                                                            </li>
                                                            <li>
                                                                <i className="la la-envelope" />
                                                                <a
                                                                    href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                    className="__cf_email__"
                                                                    data-cfemail="ed898c9a8380889f8e858cad88958c809d8188c38e8280"
                                                                >
                                                                    [email&nbsp;protected]
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <i className="la la-phone" />
                                                                +1 27691-89246
                                                            </li>
                                                            <li>
                                                                <i className="la la-map-marker-alt" />
                                                                Bristol, United States
                                                            </li>
                                                        </ul>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="card-user-info">
                                                                <a href="contact-details.html" className="avatar">
                                                                    <img src="/assets/img/avatar/avatar-22.jpg" alt="Img" />
                                                                </a>
                                                                <a href="contact-details.html" className="user-name">
                                                                    Dawn Mercha
                                                                </a>
                                                            </div>
                                                            <span className="badge badge-soft-info">78%</span>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                        <span>
                                                            <i className="la la-calendar" /> 25 Jan 2024
                                                        </span>
                                                        <ul className="icons-social">
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-phone-volume" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="lab la-facebook-messenger" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-swatchbook" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="kanban-list-items">
                                        <div className="kanban-list-head">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="kanban-title-head dot-warning">
                                                    <h5>CLIENT</h5>

                                                </div>
                                                <div className="kanban-action-btns d-flex align-items-center">

                                                    <div className="dropdown dropdown-action">
                                                        <a
                                                            href="#"
                                                            className="action-icon dropdown-toggle"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            <i className="material-icons">more_vert</i>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <a
                                                                className="dropdown-item"
                                                                href="#"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#edit_deals"
                                                            >
                                                                <i className="fa-solid fa-pencil m-r-5" /> Edit
                                                            </a>
                                                            <a
                                                                className="dropdown-item"
                                                                href="#"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#delete_deals"
                                                            >
                                                                <i className="fa-regular fa-trash-can m-r-5" /> Delete
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ul className="kanban-drag-wrap">
                                            <li>
                                                <div className="kanban-card">
                                                    <div className="kanban-card-head">
                                                        <span className="bar-design bg-warning" />
                                                        <div className="kanban-card-title">
                                                            <span>HT</span>
                                                            <h6>Jody, Powell and Cecil</h6>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-body">
                                                        <ul>
                                                            <li>
                                                                <i className="la la-dollar-sign" />
                                                                $01,84,043
                                                            </li>
                                                            <li>
                                                                <i className="la la-envelope" />
                                                                <a
                                                                    href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                    className="__cf_email__"
                                                                    data-cfemail="4e3c2f2d262b220e2b362f233e222b602d2123"
                                                                >
                                                                    [email&nbsp;protected]
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <i className="la la-phone" />
                                                                +1 17839-93617
                                                            </li>
                                                            <li>
                                                                <i className="la la-map-marker-alt" />
                                                                Baltimore, United States
                                                            </li>
                                                        </ul>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="card-user-info">
                                                                <a href="contact-details.html" className="avatar">
                                                                    <img src="/assets/img/avatar/avatar-23.jpg" alt="Img" />
                                                                </a>
                                                                <a href="contact-details.html" className="user-name">
                                                                    Rachel Hampton
                                                                </a>
                                                            </div>
                                                            <span className="badge badge-soft-info">25%</span>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                        <span>
                                                            <i className="la la-calendar" /> 18 Mar 2024
                                                        </span>
                                                        <ul className="icons-social">
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-phone-volume" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="lab la-facebook-messenger" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-swatchbook" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="kanban-card">
                                                    <div className="kanban-card-head">
                                                        <span className="bar-design bg-success" />
                                                        <div className="kanban-card-title">
                                                            <span>BL</span>
                                                            <h6>Bonnie, Linda and Mullin</h6>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-body">
                                                        <ul>
                                                            <li>
                                                                <i className="la la-dollar-sign" />
                                                                $09,35,189
                                                            </li>
                                                            <li>
                                                                <i className="la la-envelope" />
                                                                <a
                                                                    href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                    className="__cf_email__"
                                                                    data-cfemail="7c161312191010193c19041d110c1019521f1311"
                                                                >
                                                                    [email&nbsp;protected]
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <i className="la la-phone" />
                                                                +1 16739-47193
                                                            </li>
                                                            <li>
                                                                <i className="la la-map-marker-alt" />
                                                                Coventry, United States
                                                            </li>
                                                        </ul>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="card-user-info">
                                                                <a href="contact-details.html" className="avatar">
                                                                    <img src="/assets/img/avatar/avatar-24.jpg" alt="Img" />
                                                                </a>
                                                                <a href="contact-details.html" className="user-name">
                                                                    Jonelle Curtiss
                                                                </a>
                                                            </div>
                                                            <span className="badge badge-soft-info">70%</span>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                        <span>
                                                            <i className="la la-calendar" /> 15 Feb 2024
                                                        </span>
                                                        <ul className="icons-social">
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-phone-volume" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="lab la-facebook-messenger" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-swatchbook" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="kanban-card">
                                                    <div className="kanban-card-head">
                                                        <span className="bar-design bg-purple" />
                                                        <div className="kanban-card-title">
                                                            <span>CJ</span>
                                                            <h6>
                                                                Carlos, Jones and <br /> Jim
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-body">
                                                        <ul>
                                                            <li>
                                                                <i className="la la-dollar-sign" />
                                                                $04,27,940
                                                            </li>
                                                            <li>
                                                                <i className="la la-envelope" />
                                                                <a
                                                                    href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                    className="__cf_email__"
                                                                    data-cfemail="8ee4e1e0effae6efe0ceebf6efe3fee2eba0ede1e3"
                                                                >
                                                                    [email&nbsp;protected]
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <i className="la la-phone" />
                                                                +1 18390-37153
                                                            </li>
                                                            <li>
                                                                <i className="la la-map-marker-alt" />
                                                                Seattle
                                                            </li>
                                                        </ul>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="card-user-info">
                                                                <a href="contact-details.html" className="avatar">
                                                                    <img src="/assets/img/avatar/avatar-25.jpg" alt="Img" />
                                                                </a>
                                                                <a href="contact-details.html" className="user-name">
                                                                    Jonathan
                                                                </a>
                                                            </div>
                                                            <span className="badge badge-soft-info">45%</span>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                        <span>
                                                            <i className="la la-calendar" /> 30 Jan 2024
                                                        </span>
                                                        <ul className="icons-social">
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-phone-volume" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="lab la-facebook-messenger" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-swatchbook" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="kanban-list-items">
                                        <div className="kanban-list-head">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="kanban-title-head dot-purple">
                                                    <h5>OTHERS</h5>

                                                </div>
                                                <div className="kanban-action-btns d-flex align-items-center">

                                                    <div className="dropdown dropdown-action">
                                                        <a
                                                            href="#"
                                                            className="action-icon dropdown-toggle"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            <i className="material-icons">more_vert</i>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <a
                                                                className="dropdown-item"
                                                                href="#"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#edit_deals"
                                                            >
                                                                <i className="fa-solid fa-pencil m-r-5" /> Edit
                                                            </a>
                                                            <a
                                                                className="dropdown-item"
                                                                href="#"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#delete_deals"
                                                            >
                                                                <i className="fa-regular fa-trash-can m-r-5" /> Delete
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ul className="kanban-drag-wrap">
                                            <li>
                                                <div className="kanban-card">
                                                    <div className="kanban-card-head">
                                                        <span className="bar-design bg-danger" />
                                                        <div className="kanban-card-title">
                                                            <span>FJ</span>
                                                            <h6>Freda,Jennfier and Thompson</h6>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-body">
                                                        <ul>
                                                            <li>
                                                                <i className="la la-dollar-sign" />
                                                                $04,17,593
                                                            </li>
                                                            <li>
                                                                <i className="la la-envelope" />
                                                                <a
                                                                    href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                    className="__cf_email__"
                                                                    data-cfemail="99eaf0fdf7fce0d9fce1f8f4e9f5fcb7faf6f4"
                                                                >
                                                                    [email&nbsp;protected]
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <i className="la la-phone" />
                                                                +1 11739-38135
                                                            </li>
                                                            <li>
                                                                <i className="la la-map-marker-alt" />
                                                                London, United States
                                                            </li>
                                                        </ul>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="card-user-info">
                                                                <a href="contact-details.html" className="avatar">
                                                                    <img src="/assets/img/avatar/avatar-17.jpg" alt="Img" />
                                                                </a>
                                                                <a href="contact-details.html" className="user-name">
                                                                    Sidney Franks
                                                                </a>
                                                            </div>
                                                            <span className="badge badge-soft-info">59%</span>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                        <span>
                                                            <i className="la la-calendar" />
                                                            11 Apr 2024
                                                        </span>
                                                        <ul className="icons-social">
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-phone-volume" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="lab la-facebook-messenger" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-swatchbook" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="kanban-card">
                                                    <div className="kanban-card-head">
                                                        <span className="bar-design bg-purple" />
                                                        <div className="kanban-card-title">
                                                            <span>BF</span>
                                                            <h6>Bruce, Faulkner and Lela</h6>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-body">
                                                        <ul>
                                                            <li>
                                                                <i className="la la-dollar-sign" />
                                                                $08,81,389
                                                            </li>
                                                            <li>
                                                                <i className="la la-envelope" />
                                                                <a
                                                                    href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                    className="__cf_email__"
                                                                    data-cfemail="f193839e9e9ab19489909c819d94df929e9c"
                                                                >
                                                                    [email&nbsp;protected]
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <i className="la la-phone" />
                                                                +1 19302-91043
                                                            </li>
                                                            <li>
                                                                <i className="la la-map-marker-alt" />
                                                                Detroit, United States
                                                            </li>
                                                        </ul>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="card-user-info">
                                                                <a href="contact-details.html" className="avatar">
                                                                    <img src="/assets/img/avatar/avatar-26.jpg" alt="Img" />
                                                                </a>
                                                                <a href="contact-details.html" className="user-name">
                                                                    Brook
                                                                </a>
                                                            </div>
                                                            <span className="badge badge-soft-info">72%</span>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                        <span>
                                                            <i className="la la-calendar" /> 17 Apr 2024
                                                        </span>
                                                        <ul className="icons-social">
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-phone-volume" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="lab la-facebook-messenger" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-swatchbook" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="kanban-card">
                                                    <div className="kanban-card-head">
                                                        <span className="bar-design bg-warning" />
                                                        <div className="kanban-card-title">
                                                            <span>LP</span>
                                                            <h6>Lawrence, Patrick and Vandorn</h6>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-body">
                                                        <ul>
                                                            <li>
                                                                <i className="la la-dollar-sign" />
                                                                $09,27,193
                                                            </li>
                                                            <li>
                                                                <i className="la la-envelope" />
                                                                <a
                                                                    href="https://smarthr.dreamstechnologies.com/cdn-cgi/l/email-protection"
                                                                    className="__cf_email__"
                                                                    data-cfemail="1e73777d757b675e7b667f736e727b307d7173"
                                                                >
                                                                    [email&nbsp;protected]
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <i className="la la-phone" />
                                                                +1 17280-92016
                                                            </li>
                                                            <li>
                                                                <i className="la la-map-marker-alt" />
                                                                Manchester, United States
                                                            </li>
                                                        </ul>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="card-user-info">
                                                                <a href="contact-details.html" className="avatar">
                                                                    <img src="/assets/img/avatar/avatar-15.jpg" alt="Img" />
                                                                </a>
                                                                <a href="contact-details.html" className="user-name">
                                                                    Mickey
                                                                </a>
                                                            </div>
                                                            <span className="badge badge-soft-info">20%</span>
                                                        </div>
                                                    </div>
                                                    <div className="kanban-card-footer d-flex align-items-center justify-content-between">
                                                        <span>
                                                            <i className="la la-calendar" /> 10 Feb 2024
                                                        </span>
                                                        <ul className="icons-social">
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-phone-volume" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="lab la-facebook-messenger" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="la la-swatchbook" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {listtype == "List" ?
                        <div className="row">
                            <div className="col-sm-12 col-md-5">
                                <div
                                    className="dataTables_info"
                                    id="DataTables_Table_0_info"
                                    role="status"
                                    aria-live="polite"
                                >
                                    Number of entries per page:

                                </div>
                            </div>
                            <div className="col-sm-12 col-md-7">
                                <div
                                    className="dataTables_paginate paging_simple_numbers"
                                    id="DataTables_Table_0_paginate"
                                >
                                    <ul className="pagination">
                                        <li
                                            className="paginate_button page-item previous disabled"
                                            id="DataTables_Table_0_previous"
                                        >
                                            <a
                                                href="#"
                                                aria-controls="DataTables_Table_0"
                                                data-dt-idx={0}
                                                tabIndex={0}
                                                className="page-link"
                                            >
                                                <i className="fa fa-angle-double-left" />{" "}
                                            </a>
                                        </li>
                                        <li className="paginate_button page-item active">
                                            <a
                                                href="#"
                                                aria-controls="DataTables_Table_0"
                                                data-dt-idx={1}
                                                tabIndex={0}
                                                className="page-link"
                                            >
                                                1
                                            </a>
                                        </li>
                                        <li
                                            className="paginate_button page-item next disabled"
                                            id="DataTables_Table_0_next"
                                        >
                                            <a
                                                href="#"
                                                aria-controls="DataTables_Table_0"
                                                data-dt-idx={2}
                                                tabIndex={0}
                                                className="page-link"
                                            >
                                                {" "}
                                                <i className=" fa fa-angle-double-right" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
            </div>
        </div>
    )
}
export default DealList;