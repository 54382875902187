// src/components/AdminLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebarpage from './sidebar/sidebar';
import NavbarPage from './navbar/navbar';
// import NavBar from './NavBar';
import './AdminLayout.css';
// import SideBar from './SideBar';
// import Footer from './Footer';
// import Header from './Header';
// import MobileMenu from './MobileMenu';
const AdminLayout = () => {
  return (
    <>
      <div className="admin-layout main-wrapper ">
        {/* <MobileMenu /> */}
        <Sidebarpage />
        <NavbarPage />
        <Outlet />

      </div>
      {/* <Footer /> */}
    </>
  );
};

export default AdminLayout;
