import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import './EstimateList.css';
import { Calendar } from 'primereact/calendar';

function EstimateList() {

    const location = useLocation();
    const datePickerRefFrom = useRef(null);
    const datePickerRefTo = useRef(null);
    const [date, setDate] = useState(null);
    const [date1, setDate1] = useState(null);
    const [selectedfilter, setSelectedfilter] = useState("active")
    // useEffect(() => {
    //     if (datePickerRefFrom.current) {
    //         window.flatpickr(datePickerRefFrom.current, {
    //             enableTime: true,
    //             dateFormat: 'Y-m-d H:i',
    //         });
    //     }

    //     if (datePickerRefTo.current) {
    //         window.flatpickr(datePickerRefTo.current, {
    //             enableTime: true,
    //             dateFormat: 'Y-m-d H:i',
    //         });
    //     }
    // }, []);

    return (
        <div>
            <div className="page-wrapper custom-estimate-list">
                <div className="content container-fluid">
                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title">Estimate</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                    <li className={`breadcrumb-item active`}>
                                        Estimate List
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-8 float-end ms-auto">
                                <div className="d-flex title-head">
                                    {/* <div className="view-icons">
                                        <a href="#" className="grid-view btn btn-link">
                                            <i className="las la-redo-alt" />
                                        </a>
                                        <a
                                            href="#"
                                            className="list-view btn btn-link"
                                            id="collapse-header"
                                        >
                                            <i className="las la-expand-arrows-alt" />
                                        </a>
                                        <a

                                            className="list-view btn btn-link"
                                            id="filter_search"
                                        >
                                            <i className="las la-filter" />
                                        </a>
                                    </div> */}
                                    <div className="form-sort">
                                        <a

                                            className="list-view btn btn-link"

                                        >
                                            <i className="las la-file-export" />
                                            Export
                                        </a>
                                    </div>


                                    <div className="search-set">
                                        <div className="search-input">
                                            <a href="#" className="btn btn-searchset">
                                                <i className="las la-search" />
                                            </a>
                                            <div className="dataTables_filter">
                                                <label>
                                                    {" "}
                                                    <input

                                                        type="search"
                                                        className="form-control form-control-sm"
                                                        placeholder="Search"
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <Link
                                        to="/estimate-create"
                                        className="btn add-btn"

                                    >
                                        <i className="la la-plus-circle" /> Create Estimate
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>



                    <div className="filter-filelds" id="filter_inputs">
                        <div className="row filter-row">

                            <div className="col-xl-2">
                                <div className="input-block mb-3 form-focus select-focus">
                                    <Calendar dateFormat="yy/mm/dd" placeholder="YYYY / MM / DD" value={date} onChange={(e) => setDate(e.value)} />


                                </div>
                            </div>
                            <div className="col-xl-2">
                                <div className="input-block mb-3 form-focus select-focus">
                                    <Calendar dateFormat="yy/mm/dd" placeholder="YYYY / MM / DD" value={date} onChange={(e) => setDate(e.value)} />


                                </div>
                            </div>
                            <div className="col-xl-2">
                                <div className="input-block mb-3 form-focus select-focus">
                                    <select className="select floating select-status">
                                        <option>Select Status</option>
                                        <option>Pending</option>
                                        <option>Paid</option>
                                        <option>Partially Paid</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-xl-2">
                                <a href="#" className="btn btn-success w-100">
                                    {" "}
                                    Search{" "}
                                </a>
                            </div>
                        </div>
                    </div>
                    <hr />




                    <div className="filter-section">
                        <ul>
                            {/* <li>
                            <a
                                onClick={() => setSelectedfilter("all")}
                                className={selectedfilter == "all" ? "btn filter-selected" : "btn filter-unselected"} >
                                All Companies
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={() => setSelectedfilter("my")}
                                className={selectedfilter == "my" ? "btn filter-selected" : "btn filter-unselected"}
                            >
                                My Companies
                            </a>
                        </li> */}
                            <li>
                                <a
                                    onClick={() => setSelectedfilter("active")}
                                    className={selectedfilter == "active" ? "btn filter-selected" : "btn filter-unselected"} >
                                    Active
                                </a>
                            </li>
                            <li>
                                <a
                                    onClick={() => setSelectedfilter("inacive")}
                                    className={selectedfilter == "inacive" ? "btn filter-selected" : "btn filter-unselected"} >
                                    Inactive
                                </a>
                            </li>



                        </ul>


                        <ul>

                            <li>
                                <a

                                    className="list-view btn btn-link filteroptions"

                                >
                                    <i className="fa fa-columns" />
                                </a>
                            </li>
                            <li>
                                <a

                                    className="list-view btn btn-link filteroptions"
                                    id="filter_search"
                                >
                                    <i className="las la-filter" />
                                </a>
                            </li>

                        </ul>
                    </div>





                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table  custom-table datatable contact-table mt-10">
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Estimation ID</th>
                                            <th>Deal ID</th>
                                            <th>Issue Date</th>
                                            <th>Company Name</th>
                                            <th>Deal Name</th>
                                            <th>Amount</th>
                                            <th className="text-end">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>01</td>
                                            <td><Link to="/estimate-view">JPE.180030401</Link></td>
                                            <td>180030401</td>
                                            <td>2019 / 01 / 01</td>
                                            <td>Sony Big</td>
                                            <td>Digital Marketing</td>
                                            <td>1,00,000</td>
                                            <td className="text-end">
                                                <div className="dropdown dropdown-action">
                                                    <Link to="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></Link>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <Link className="dropdown-item" to=""><i className="fa-solid fa-pencil m-r-5" /> Edit</Link>
                                                        <Link className="dropdown-item" to="/estimate-view"><i className="fa-solid fa-eye m-r-5" />  View</Link>
                                                        <Link className="dropdown-item" to="#" data-bs-toggle="modal" data-bs-target="#delete_estimate"><i className="fa-regular fa-trash-can m-r-5" /> Delete</Link>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>02</td>
                                            <td><Link to="/estimate-view">JPE.180030402</Link></td>
                                            <td>180030402</td>
                                            <td>2019 / 01 / 02</td>
                                            <td>XYZ Corporation</td>
                                            <td>Software Development</td>
                                            <td>5,000</td>
                                            <td className="text-end">
                                                <div className="dropdown dropdown-action">
                                                    <Link to="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></Link>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <Link className="dropdown-item" to=""><i className="fa-solid fa-pencil m-r-5" /> Edit</Link>
                                                        <Link className="dropdown-item" to="/estimate-view"><i className="fa-solid fa-eye m-r-5" />  View</Link>
                                                        <Link className="dropdown-item" to="#" data-bs-toggle="modal" data-bs-target="#delete_estimate"><i className="fa-regular fa-trash-can m-r-5" /> Delete</Link>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>03</td>
                                            <td><Link to="/estimate-view">JPE.180030403</Link></td>
                                            <td>180030403</td>
                                            <td>2019 / 01 / 03</td>
                                            <td>Indo Sakura</td>
                                            <td>Digital Marketing</td>
                                            <td>1,00,000</td>
                                            <td className="text-end">
                                                <div className="dropdown dropdown-action">
                                                    <Link to="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></Link>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <Link className="dropdown-item" to=""><i className="fa-solid fa-pencil m-r-5" /> Edit</Link>
                                                        <Link className="dropdown-item" to="/estimate-view"><i className="fa-solid fa-eye m-r-5" />  View</Link>
                                                        <Link className="dropdown-item" to="#" data-bs-toggle="modal" data-bs-target="#delete_estimate"><i className="fa-regular fa-trash-can m-r-5" /> Delete</Link>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>04</td>
                                            <td><Link to="/estimate-view">JPE.180030404</Link></td>
                                            <td>180030404</td>
                                            <td>2019 / 01 / 04</td>
                                            <td>AM Consulting</td>
                                            <td>Software Development</td>
                                            <td>5,000</td>
                                            <td className="text-end">
                                                <div className="dropdown dropdown-action">
                                                    <Link to="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></Link>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <Link className="dropdown-item" to="edit-estimate.html"><i className="fa-solid fa-pencil m-r-5" /> Edit</Link>
                                                        <Link className="dropdown-item" to="/estimate-view"><i className="fa-solid fa-eye m-r-5" />  View</Link>
                                                        <Link className="dropdown-item" to="#" data-bs-toggle="modal" data-bs-target="#delete_estimate"><i className="fa-regular fa-trash-can m-r-5" /> Delete</Link>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-5">
                            <div
                                className="dataTables_info"
                                id="DataTables_Table_0_info"
                                role="status"
                                aria-live="polite"
                            >
                                Showing 1 to 10 of 10 entries
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-7">
                            <div
                                className="dataTables_paginate paging_simple_numbers"
                                id="DataTables_Table_0_paginate"
                            >
                                <ul className="pagination">
                                    <li
                                        className="paginate_button page-item previous disabled"
                                        id="DataTables_Table_0_previous"
                                    >
                                        <a
                                            href="#"
                                            aria-controls="DataTables_Table_0"
                                            data-dt-idx={0}
                                            tabIndex={0}
                                            className="page-link"
                                        >
                                            <i className="fa fa-angle-double-left" />{" "}
                                        </a>
                                    </li>
                                    <li className="paginate_button page-item active">
                                        <a
                                            href="#"
                                            aria-controls="DataTables_Table_0"
                                            data-dt-idx={1}
                                            tabIndex={0}
                                            className="page-link"
                                        >
                                            1
                                        </a>
                                    </li>
                                    <li
                                        className="paginate_button page-item next disabled"
                                        id="DataTables_Table_0_next"
                                    >
                                        <a
                                            href="#"
                                            aria-controls="DataTables_Table_0"
                                            data-dt-idx={2}
                                            tabIndex={0}
                                            className="page-link"
                                        >
                                            {" "}
                                            <i className=" fa fa-angle-double-right" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>




                </div>


            </div>


        </div>
    )
}
export default EstimateList;