import { handleApiCall } from "../../Config/api"

export const createDealApi = async (body) => {
  try {
      const response = await handleApiCall("POST", "/deal", body)
      return { response }
  }
  catch (err) {
      return { err }
  }

}

export const getDealListApi = async () => {
  try {
      const response = await handleApiCall("GET", "/deal")
      return { response }
  }
  catch (err) {
      return { err }
  }
}

export const getDealDetailsApi = async (id) => {
    try {
        const response = await handleApiCall("GET", "/deal/byId?id=" + id)
        return { response }
    }
    catch (err) {
        return { err }
    }
}

export const updateDealApi = async (body) => {
    try {
        const response = await handleApiCall("PUT", "/deal", body)
        return { response }
    }
    catch (err) {
        return { err }
    }
}

export const deleteDealByIDApi = async (id) => {
    try {
        const response = await handleApiCall("DELETE", "/deal?id=" + id)
        return { response }
    }
    catch (err) {
        return { err }
    }
}
