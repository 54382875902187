import React, { useState } from "react";
import './BillingDashboard.css';
import HalfDoughnutChart from "../../components/common/BarChart";
import GraphChart from "../../components/common/GraphChart";
import BarChart from "../../components/common/GraphChart";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";

function BillingDashboard() {

    const [view, setView] = useState('yearly');
    const [data, setData] = useState({
        yearly: { totalDeals: 100, totalRevenue: 50000, totalCustomers: 300 },
        quarterly: { totalDeals: 25, totalRevenue: 12500, totalCustomers: 75 },
        monthly: { totalDeals: 8, totalRevenue: 4000, totalCustomers: 25 },
    });

    const handleClick = (viewType) => {
        setView(viewType);
    };

    const [progress, setProgress] = useState('table');

    const onhandleClick = (item) => {
        setProgress(item);
    }

    const currentData = data[view];
    return (

        <div className="page-wrapper custom-builing">
            <div className="content container-fluid pb-0">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6">
                            <h3 className="page-title">Welcome Admin!</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ul>
                        </div>

                        <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6">
                            <Link to="/create-invoice" className="btn add-btn mb-2"><i className="fa-solid fa-plus" /> Create Invoice</Link>
                            <Link to="/estimate-create" className="btn add-btn"><i className="fa-solid fa-plus" /> Create Estimation</Link>
                        </div>



                    </div>
                </div>
                <div className="row">

                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                        <div className="card dash-widget">
                            <div className="card-body">
                                <span className="dash-widget-icon"><i className="fa-solid fa-cubes" /></span>
                                <div className="dash-widget-info">

                                    <h4>10</h4>
                                    <span>Total Deals</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                        <div className="card dash-widget">
                            <div className="card-body">
                                <span className="dash-widget-icon"><i className="fa-solid fa-dollar-sign" /></span>
                                <div className="dash-widget-info">

                                    <h4>Rs.150,000k</h4>
                                    <span>Estimation</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                        <div className="card dash-widget">
                            <div className="card-body">
                                <span className="dash-widget-icon"><i className="fa-regular fa-gem" /></span>
                                <div className="dash-widget-info">

                                    <h4>Rs.150,000k</h4>
                                    <span>Invoice</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                        <div className="card dash-widget">
                            <div className="card-body">
                                <span className="dash-widget-icon"><i className="fa-solid fa-user" /></span>
                                <div className="dash-widget-info">
                                    <h4>Rs. 180,000 K</h4>
                                    <span>Forecast</span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="row mb-4">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-4">
                                <div className="background-target border-to-graph">
                                    <div className="row">
                                        <div className="col-md-6 col-xl-6 border-width">
                                            <h4 className="mb-4">Target Vs Achievement</h4>
                                        </div>
                                        <div className="col-md-6 col-xl-6 mb-4 float-right">
                                            <button
                                                className={`target-btn ${view === 'yearly' ? 'target-active' : ''}`}
                                                onClick={() => handleClick('yearly')}
                                            >
                                                Yearly
                                            </button>
                                            <button
                                                className={`target-btn ${view === 'quarterly' ? 'target-active' : ''}`}
                                                onClick={() => handleClick('quarterly')}
                                            >
                                                Quarterly
                                            </button>
                                            <button
                                                className={`target-btn ${view === 'monthly' ? 'target-active' : ''}`}
                                                onClick={() => handleClick('monthly')}
                                            >
                                                Monthly
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row custm-card">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                            <div className="card dash-widget">
                                                <div className="card-body">
                                                    <span className="dash-widget-icon"><i className="fa-solid fa-cubes" /></span>
                                                    <div className="dash-widget-info">
                                                        <h4>{currentData.totalDeals}</h4>
                                                        <span>Total Deals</span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="card dash-widget">
                                                <div className="card-body">
                                                    <span className="dash-widget-icon"><i className="fa-solid fa-cubes" /></span>
                                                    <div className="dash-widget-info">
                                                        <h4>{currentData.totalRevenue}</h4>
                                                        <span>Total Deals</span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="card dash-widget">
                                                <div className="card-body">
                                                    <span className="dash-widget-icon"><i className="fa-solid fa-cubes" /></span>
                                                    <div className="dash-widget-info">
                                                        <h4>{currentData.totalCustomers}</h4>
                                                        <span>Total Deals</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                            <HalfDoughnutChart />
                                        </div>
                                    </div>

                                </div>

                            </div>
                            {/* <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                <div className="card analytics-card w-100">
                                    <div className="card-body">
                                        <div className="statistic-header">


                                            <div className="col-md-6">
                                                <h5 className="mb-4">PROGRESS</h5>
                                            </div>
                                            <div className="col-md-6 mb-4 float-right" >
                                                <button
                                                    className={`progress-btn ${progress === 'graph' ? 'progress-active' : ''}`}
                                                    onClick={() => onhandleClick('graph')}
                                                >
                                                    <i className="fas fa-chart-line" /> Graph
                                                </button>
                                                <button
                                                    className={`progress-btn ${progress === 'graph' ? 'progress-active' : ''}`}
                                                    onClick={() => onhandleClick('table')}
                                                >
                                                    <i className="fas fa-table" /> Table
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {progress === 'table' && (
                                        <table className="table table-nowrap custom-table mb-0">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Target</th>
                                                    <th>Achievement</th>
                                                    <th>%</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><a>Current Month</a></td>
                                                    <td><a>0</a></td>
                                                    <td><a>0</a></td>
                                                    <td>0.00</td>
                                                </tr>
                                                <tr>
                                                    <td><a>1st Quarter</a></td>
                                                    <td><a>0</a></td>
                                                    <td><a>0</a></td>
                                                    <td>0.00</td>
                                                </tr>
                                                <tr>
                                                    <td><a>2nd Quarter</a></td>
                                                    <td><a>0</a></td>
                                                    <td><a>0</a></td>
                                                    <td>0.00</td>
                                                </tr>
                                                <tr>
                                                    <td><a>3rd Quarter</a></td>
                                                    <td><a>0</a></td>
                                                    <td><a>0</a></td>
                                                    <td>0.00</td>
                                                </tr>
                                                <tr>
                                                    <td><a>4th Quarter</a></td>
                                                    <td><a>0</a></td>
                                                    <td><a>0</a></td>
                                                    <td>0.00</td>
                                                </tr>
                                                <tr>
                                                    <td><a>Total</a></td>
                                                    <td><a>0</a></td>
                                                    <td><a>0</a></td>
                                                    <td>0.00</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    )}
                                    {progress === 'graph' && (
                                        <BarChart /> // Ensure you have BarChart component imported and defined
                                    )}

                                </div>
                            </div> */}
                            <div className="col-md-12 col-lg-12 col-xl-6 col-sm-12 d-flex">
                                <div className="card analytics-card w-100">
                                    <div className="card-body">
                                        <div className="statistic-header">
                                            <h4>Effort Measurement</h4>
                                            <div>
                                                <button
                                                    className={`progress-btn mb-2 ${progress === 'graph' ? 'progress-active' : ''}`}
                                                    onClick={() => onhandleClick('graph')}
                                                >
                                                    <i className="fas fa-chart-line" /> Graph
                                                </button>
                                                <button
                                                    className={`progress-btn ${progress === 'table' ? 'progress-active' : ''}`}
                                                    onClick={() => onhandleClick('table')}
                                                >
                                                    <i className="fas fa-table" /> Table
                                                </button>
                                            </div>
                                        </div>
                                        {progress === 'table' && (
                                            <div className="table-responsive">
                                                <table className="table custom-table table-nowrap mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Target</th>
                                                            <th>Achievement</th>
                                                            <th>%</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td><a>Current Month</a></td>
                                                            <td><a>0</a></td>
                                                            <td><a>0</a></td>
                                                            <td>0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td><a>1st Quarter</a></td>
                                                            <td><a>0</a></td>
                                                            <td><a>0</a></td>
                                                            <td>0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td><a>2nd Quarter</a></td>
                                                            <td><a>0</a></td>
                                                            <td><a>0</a></td>
                                                            <td>0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td><a>3rd Quarter</a></td>
                                                            <td><a>0</a></td>
                                                            <td><a>0</a></td>
                                                            <td>0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td><a>4th Quarter</a></td>
                                                            <td><a>0</a></td>
                                                            <td><a>0</a></td>
                                                            <td>0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td><a>Total</a></td>
                                                            <td><a>0</a></td>
                                                            <td><a>0</a></td>
                                                            <td>0.00</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>)}
                                        {progress === 'graph' && (
                                            <div className="table-responsive">
                                                <BarChart />
                                            </div>)}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* <div>
                    <div className="row mb-4">
                        <h4 className="forcast-head">FORECAST</h4>
                        <table className="table table-nowrap custom-table mb-0">
                            <thead>
                                <tr>
                                    <th>Company</th>
                                    <th>Lead Name</th>
                                    <th>Deal Name</th>
                                    <th>Value</th>
                                    <th>Category</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>XYZ Corporation</td>
                                    <td>Mohan Kumar</td>
                                    <td>IOT Project</td>
                                    <td>1,00,000</td>
                                    <td><button className="Hot-btn">HOT</button></td>
                                </tr>
                                <tr>
                                    <td>XYZ Corporation</td>
                                    <td>Mohan Kumar</td>
                                    <td>IOT Project</td>
                                    <td>1,00,000</td>
                                    <td><button className="Hot-btn">WARM</button></td>
                                </tr>
                                <tr>
                                    <td>XYZ Corporation</td>
                                    <td>Mohan Kumar</td>
                                    <td>IOT Project</td>
                                    <td>1,00,000</td>
                                    <td><button className="Hot-btn">HOT</button></td>
                                </tr>
                                <tr>
                                    <td>XYZ Corporation</td>
                                    <td>Mohan Kumar</td>
                                    <td>IOT Project</td>
                                    <td>1,00,000</td>
                                    <td><button className="Hot-btn">WARM</button></td>
                                </tr>
                                <tr>
                                    <td>XYZ Corporation</td>
                                    <td>Mohan Kumar</td>
                                    <td>IOT Project</td>
                                    <td>1,00,000</td>
                                    <td><button className="Hot-btn">HOT</button></td>
                                </tr>
                                <tr>
                                    <td><a>Total</a></td>
                                    <td></td>
                                    <td></td>
                                    <td>4,00,000</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> */}
                <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12">
                    <div className="card analytics-card w-100">
                        <div className="card-body">
                            <div className="statistic-header">
                                <h4>Forecast</h4>
                            </div>
                            <div className="table-responsive">
                                <table className="table custom-table table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th>Company</th>
                                            <th>Lead Name</th>
                                            <th>Deal Name</th>
                                            <th>Value</th>
                                            <th>Category</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>XYZ Corporation</td>
                                            <td>Mohan Kumar</td>
                                            <td>IOT Project</td>
                                            <td>1,00,000</td>
                                            <td><button className="Hot-btn">HOT</button></td>
                                        </tr>
                                        <tr>
                                            <td>XYZ Corporation</td>
                                            <td>Mohan Kumar</td>
                                            <td>IOT Project</td>
                                            <td>1,00,000</td>
                                            <td><button className="Hot-btn">WARM</button></td>
                                        </tr>
                                        <tr>
                                            <td>XYZ Corporation</td>
                                            <td>Mohan Kumar</td>
                                            <td>IOT Project</td>
                                            <td>1,00,000</td>
                                            <td><button className="Hot-btn">HOT</button></td>
                                        </tr>
                                        <tr>
                                            <td>XYZ Corporation</td>
                                            <td>Mohan Kumar</td>
                                            <td>IOT Project</td>
                                            <td>1,00,000</td>
                                            <td><button className="Hot-btn">WARM</button></td>
                                        </tr>
                                        <tr>
                                            <td>XYZ Corporation</td>
                                            <td>Mohan Kumar</td>
                                            <td>IOT Project</td>
                                            <td>1,00,000</td>
                                            <td><button className="Hot-btn">HOT</button></td>
                                        </tr>
                                        <tr>
                                            <td><a>Total</a></td>
                                            <td></td>
                                            <td></td>
                                            <td>4,00,000</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12">
                    <div className="card analytics-card w-100">
                        <div className="card-body">
                            <div className="statistic-header">
                                <h4>Invoice of the Month</h4>
                            </div>
                            <div className="table-responsive">
                                <table className="table custom-table table-nowrap mb-0">
                                    <thead style={{ backgroundColor: 'red' }}>
                                        <tr>
                                            <th>Client</th>
                                            <th>Lead Name</th>
                                            <th>Deal Name</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>XYZ Corporation</td>
                                            <td>Mohan Kumar</td>
                                            <td>IOT Project</td>
                                            <td>1,00,000</td>
                                            <td><button className="issued-btn">Issued</button></td>
                                        </tr>
                                        <tr>
                                            <td>XYZ Corporation</td>
                                            <td>Mohan Kumar</td>
                                            <td>IOT Project</td>
                                            <td>1,00,000</td>
                                            <td><button className="pending-btn">Pending</button></td>
                                        </tr>
                                        <tr>
                                            <td>XYZ Corporation</td>
                                            <td>Mohan Kumar</td>
                                            <td>IOT Project</td>
                                            <td>1,00,000</td>
                                            <td><button className="issued-btn">Issued</button></td>
                                        </tr>
                                        <tr>
                                            <td>XYZ Corporation</td>
                                            <td>Mohan Kumar</td>
                                            <td>IOT Project</td>
                                            <td>1,00,000</td>
                                            <td><button className="pending-btn">Pending</button></td>
                                        </tr>
                                        <tr>
                                            <td>XYZ Corporation</td>
                                            <td>Mohan Kumar</td>
                                            <td>IOT Project</td>
                                            <td>1,00,000</td>
                                            <td><button className="issued-btn">Issued</button></td>
                                        </tr>
                                        <tr>
                                            <td><a>Total</a></td>
                                            <td></td>
                                            <td></td>
                                            <td>4,00,000</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <div>
                    <div className="row mb-4">
                        <div className="invoice-head">
                            <div className="col-md-6 col-sm-12"><h4 className="pd-10">INVOICE OF THE MONTH</h4></div>
                            <div className="col-md-6 col-sm-12"><div className="float-right"><i className="fas fa-chevron-left border-arrow" /><span className="border-page">2019/6 </span> <i className="fas fa-chevron-right border-arrow" /></div></div>
                        </div>


                        <table className="table table-nowrap custom-table mb-0">
                            <thead style={{ backgroundColor: 'red' }}>
                                <tr>
                                    <th>Client</th>
                                    <th>Lead Name</th>
                                    <th>Deal Name</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>XYZ Corporation</td>
                                    <td>Mohan Kumar</td>
                                    <td>IOT Project</td>
                                    <td>1,00,000</td>
                                    <td><button className="issued-btn">Issued</button></td>
                                </tr>
                                <tr>
                                    <td>XYZ Corporation</td>
                                    <td>Mohan Kumar</td>
                                    <td>IOT Project</td>
                                    <td>1,00,000</td>
                                    <td><button className="pending-btn">Pending</button></td>
                                </tr>
                                <tr>
                                    <td>XYZ Corporation</td>
                                    <td>Mohan Kumar</td>
                                    <td>IOT Project</td>
                                    <td>1,00,000</td>
                                    <td><button className="issued-btn">Issued</button></td>
                                </tr>
                                <tr>
                                    <td>XYZ Corporation</td>
                                    <td>Mohan Kumar</td>
                                    <td>IOT Project</td>
                                    <td>1,00,000</td>
                                    <td><button className="pending-btn">Pending</button></td>
                                </tr>
                                <tr>
                                    <td>XYZ Corporation</td>
                                    <td>Mohan Kumar</td>
                                    <td>IOT Project</td>
                                    <td>1,00,000</td>
                                    <td><button className="issued-btn">Issued</button></td>
                                </tr>
                                <tr>
                                    <td><a>Total</a></td>
                                    <td></td>
                                    <td></td>
                                    <td>4,00,000</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> */}
            </div>
            <Footer />
        </div >

    )

}
export default BillingDashboard;