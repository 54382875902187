import React, { useState } from "react";
import Sidebarpage from "../sidebar/sidebar";
import { RiMenu3Line } from "react-icons/ri";
import './navbar.css';
import { Link, useNavigate } from "react-router-dom";

function NavbarPage({ toggleSidebar }) {

    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const navigate = useNavigate();

    return (
        <div className="header">
            <div className="header-left">
                <a className="logo">
                    <img src={require("../../assets/indo-logo.png")} alt="Logo" />
                </a>
                <a className="logo collapse-logo">
                    <img src={require("../../assets/indo-logo.png")} alt="Logo" />
                </a>
                <a className="logo2">
                    <img src={require("../../assets/indo-logo.png")} width={40} height={40} alt="Logo" />
                </a>
            </div>
            <a id="toggle_btn" className="hide-toggle">
                <span className="bar-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
            </a>
            <div className="page-title-box">
                <h3>SalesConnect</h3>
            </div>
            <a id="mobile_btn" className="mobile_btn" href="#sidebar"><i className="fa-solid fa-bars" /></a>
            <ul className="nav user-menu">
                <li className="nav-item dropdown has-arrow flag-nav">
                    <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button">
                        <img src="/assets/img/flags/us.png" alt="Flag" height={20} /> <span>English</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item">
                            <img src="/assets/img/flags/us.png" alt="Flag" height={16} /> English
                        </a>
                        <a className="dropdown-item">
                            <img src={require("../../assets/Flag_of_Japan.png")} alt="Flag" style={{ height: 15 }} /> Japanese
                        </a>
                    </div>
                </li>
                <li className="nav-item dropdown">
                    <a href="#" className="dropdown-toggle">
                        <i className="fa-regular fa-bell" /> <span className="badge rounded-pill">3</span>
                    </a>
                </li>
                <li className="nav-item dropdown has-arrow main-drop profile-nav">
                    <a data-bs-toggle="dropdown">
                        <span className="user-img"><img src="/assets/img/avatar/avatar-27.jpg" alt="User Image" /></span>
                    </a>
                    <div className="dropdown-menu">
                        <a className="dropdown-item" href="">My Profile</a>
                        <a className="dropdown-item" href="">Settings</a>
                        <a onClick={() => {
                            localStorage.removeItem("accesstoken");
                            localStorage.removeItem("userid");    
                            window.location = "/login"
                        }} className="dropdown-item">Logout</a>
                    </div>
                </li>
            </ul>
            <div className="dropdown mobile-user-menu">
                <a href="#" className="nav-link dropdown-toggle custom-icon" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-ellipsis-vertical" /></a>
                <div className="dropdown-menu dropdown-menu-right">
                    <a className="dropdown-item" href="">My Profile</a>
                    <a className="dropdown-item" href="">Settings</a>
                    <a onClick={() => {
                        localStorage.removeItem("accesstoken");
                        localStorage.removeItem("userid");
                        window.location = "/login"
                    }} 
                    className="dropdown-item">Logout</a>
                </div>
            </div>
        </div>
    );
}

export default NavbarPage;