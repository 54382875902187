import React from "react"
const CompanyLeadsList = ({leadsList = []}) => {

  const cardData = [
    {
        name: "John Doe",
        status: "Active",
        stage: "Negotiation",
        position: "Team Leader",
        department: "Telecommunication"
    },
    {
        name: "Jane Smith",
        status: "InActive",
        stage: "Closed",
        position: "Team Leader",
        department: "Telecommunication"
    },
    {
        name: "Robert Brown",
        status: "Active",
        stage: "Qualification",
        position: "Team Leader",
        department: "Telecommunication"
    },
    {
        name: "Emily Johnson",
        status: "InActive",
        stage: "Proposal",
        position: "Team Leader",
        department: "Telecommunication"
    },
    {
        name: "Michael Williams",
        status: "Active",
        stage: "Negotiation",
        position: "Team Leader",
        department: "Telecommunication"
    },
    {
        name: "Sarah Lee",
        status: "InActive",
        stage: "Initiation",
        position: "Team Leader",
        department: "Telecommunication"
    }
];


const Card = ({ name, status, stage, position, department }) => {
  return (
      <div className="col-md-6 col-lg-4 mb-4">
          <div className="card cardbgclr">
              <div className="card-body">
                  <div className="textalgend">
                      <div className="dropdown">
                          <button className="btn btn-secondary drpclr" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              <i className="las la-ellipsis-v"></i>
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li><a className="dropdown-item" href="#">Edit</a></li>
                              <li><a className="dropdown-item" href="#">View</a></li>
                          </ul>
                      </div>
                  </div>
                  <p className="card-text mb-1 d-flex justify-content-between">
                      <strong className="cardinclr">Name</strong>
                      <span className="cardinclr2">{name}</span>
                  </p>
                  <p className="card-text mb-1 d-flex justify-content-between mt-3">
                      <strong className="cardinclr">Status</strong>
                      <span className={status === "Active" ? "outline-status" : "outline-inactive-status"}>{status}</span>
                  </p>
                  <p className="card-text mb-1 d-flex justify-content-between mt-3">
                      <strong className="cardinclr">Stage</strong>
                      <span>{stage}</span>
                  </p>
                  <p className="card-text mb-1 d-flex justify-content-between mt-3">
                      <strong className="cardinclr">Position</strong>
                      <span>{position}</span>
                  </p>
                  <p className="card-text mb-1 d-flex justify-content-between mt-3">
                      <strong className="cardinclr">Department</strong>
                      <span>{department}</span>
                  </p>
                  <div className="d-flex justify-content-between mt-3">
                      <i className="fas fa-edit mx-2 carditagclr"></i>
                      <i className="fas fa-eye mx-2 carditagclr2"></i>
                  </div>
              </div>
          </div>
      </div>
  );
};

  return(
    <div className="deal-list-content">
    <div className="row align-items-center mt-3 mb-3">
        <div className="col-xl-8 col-md-10 ms-auto">
            <div className="d-flex justify-content-end title-head"><a className="btn add-btn" href="/company-create"><i className="la la-plus-circle"></i>Create</a></div>
        </div>
    </div>
    <div className="row">
    {leadsList.map((card, index) => (
                <Card
                    key={index}
                    name={card.nameEnglish}
                    status={card.status}
                    stage={card.stage}
                    position={card.position}
                    department={card.department}
                />
              ))}
    </div>
</div>
  )
}

export {
  CompanyLeadsList
}