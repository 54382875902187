import React, {useState } from "react";
import { Link } from "react-router-dom";
import { fetchlogin } from "./ApiCall";
function Login() {

    const [formValues, setFormValues] = useState({
        username: '',
        password: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const validate = () => {
        const newErrors = {};
        Object.keys(formValues).forEach(key => {
            if (!formValues[key]) {
                newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const LoginApiCall = async () => {
        if (validate()) {
            console.log('Form submitted', formValues);
            const { response, err } = await fetchlogin(formValues)
            if (response != undefined) {
                const accessToken = response.tokenType + " " + response.accessToken
                console.log("accessToken", accessToken)
                localStorage.setItem("accesstoken", accessToken)
                localStorage.setItem("userid", response.id)
                window.location = "/company-list"
            }

        }
    }
    return (
        <div className="account-page">
            <div className="main-wrapper">
                <div className="account-content">
                    <div className="container">
                        <div className="account-logo">
                            <a href="admin-dashboard.html">
                                <img src={require("../../assets/indo-logo.png")} alt="Indo Sakura" />
                            </a>
                        </div>
                        <form>
                            <div className="account-box">
                                <div className="account-wrapper">
                                    <h3 className="account-title">Login</h3>
                                    <p className="account-subtitle">Access to our dashboard</p>

                                    <div className="input-block mb-4">
                                        <label className="col-form-label">Username</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="username"
                                            name="username"
                                            value={formValues.username}
                                            onChange={handleChange}
                                        />
                                        {errors.username && <p className="error-text">{errors.username}</p>}

                                    </div>

                                    <div className="input-block mb-4">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <label className="col-form-label">Password</label>
                                            </div>
                                            <div className="col-auto">
                                                <Link to={"/forget-password"} className="text-muted" >
                                                    Forgot password?
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="position-relative">
                                            <input
                                                type="password"

                                                className="form-control"
                                                id="password"
                                                name="password"
                                                value={formValues.password}
                                                onChange={handleChange}
                                            />
                                            <span
                                                className="fa-solid fa-eye-slash"
                                                id="toggle-password"
                                            />

                                        </div>
                                        {errors.password && <p className="error-text">{errors.password}</p>}
                                    </div>
                                    <div className="input-block mb-4 text-center">
                                        <button onClick={() => LoginApiCall()} className="btn btn-primary account-btn" type="button">
                                            Login
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>

    )
}
export default Login;