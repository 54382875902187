import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./CompanyList.css"
import GridTable from "../../../components/common/GridTable";
import { deleteCompanyByIDApi, getCompanyListApi, getCountryListApi, getStateListApi, importCompanyApi } from "../ApiCall";
import Select from 'react-select';
import { createPortal } from 'react-dom';
import { toast } from 'react-toastify';
import { CSVLink } from "react-csv";

function CompanyList() {
    const [InitialCompanyList, setInitialCompanyList] = useState([])
    const [CompanyList, setCompanyList] = useState([]);
    const [popupMenu, setPopupMenu] = useState({
        isOpen: false,
        x: 0,
        y: 0,
        rowId: null,
    });
    const menuRef = useRef(null);
    const [tempColumn, setTempColumn] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const currentDate = new Date().toISOString().split('T')[0];
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [bgColors, setBgColors] = useState(Array(7).fill("white"));
    const [selectedValues, setSelectedValues] = useState({
        company: null,
        status: null,
        stages: null,
        employees: null,
        industry: null,
        country: null,
        state: null,
    });
    const navigate = useNavigate();
    const location = useLocation();
    const user_id = localStorage.getItem("userid")

    const CompanyDeleteFunc = async (id, index) => {
        const { response, err } = await deleteCompanyByIDApi(id);
        if (!err) {
            getCompanyListApi();
            toast.success("Record Deleted Successfully!");
            window.location.reload();
        }
    }

    const CustomCellRenderer = ({ data, node }) => {
        const [showPopup, setShowPopup] = useState(false);
        const [position, setPosition] = useState({ top: 0, left: 0 });

        const handleButtonClick = (event) => {
            const rect = event.target.getBoundingClientRect();
            setPosition({
                top: rect.top + window.scrollY + rect.height,
                left: rect.left + window.scrollX,
            });
            setShowPopup(!showPopup);
        };

        const popupStyle = {
            position: 'absolute',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '10px',
            zIndex: 1000,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        };

        return (
            <div>
                <a onClick={handleButtonClick}> <i style={{ fontSize: 30 }} className="las la-ellipsis-v"></i></a>
                {showPopup && createPortal(
                    <div style={{ ...popupStyle, top: position.top, left: position.left }}>
                        <ul>
                            <Link
                                className="dropdown-item"
                                to={`/company-edit/${data.id}`}
                            >
                                <i className="fa-solid fa-pencil m-r-5" /> Edit
                            </Link>
                            <Link
                                className="dropdown-item"
                                to={`/company-details/${data.id}`}
                            >
                                <i className="fa-regular fa-eye" /> View
                            </Link>
                            <a
                                onClick={() => CompanyDeleteFunc(data.id, node.rowIndex)}
                                className="dropdown-item"
                            >
                                <i className="las la-trash" /> Delete
                            </a>
                        </ul>
                    </div>,
                    document.body
                )}
            </div>
        );
    };

    const [columnDefs, setColumnsDefs] = useState([
        { headerName: 'Company ID', field: 'companyid', hide: false },
        { headerName: 'Name', field: 'companyen', hide: false, cellRenderer: (props) => {
                console.log("props", props)
                return (
                    <Link
                        to={`/company-details/${props.data.id}`}
                    >
                        {props.value}
                    </Link>
                )}
        },
        { headerName: 'Employee Size', field: 'employees', hide: false },
        { headerName: 'Capital', field: 'capital', hide: false, cellRenderer: (props) => (
                <div>
                    <a > {props.data.capitalCurrency} </a >
                    <a > {props.data.capital} </a >
                    <a > {props.data.capitalDenomination} </a >
                </div>
            ),
        },
        { headerName: 'Revenue Generated', field: 'revenueGenerated', hide: false },
        { headerName: 'Stage', field: 'stages', hide: false, },
        { headerName: 'Status', field: 'status', cellRenderer: (props) => (
                <a className={props.value == "Active" ? "outline-status" : "outline-inactive-status"}> {props.value} </a >
            )
        },
        { headerName: 'Country', field: 'country', hide: false, cellRenderer: (props) => (
                <a> {props.data.address.countryId.name} </a >
            ),
        },
        { headerName: 'State', field: 'state', hide: false, cellRenderer: (props) => (
                <a> {props.data.address.stateId.name} </a >
            ),
        },
        { headerName: 'Industry', field: 'industry', hide: false },
        { headerName: 'Deal Count', field: 'dealCount', hide: false, cellRenderer: (props) => (
                <a > {props.value ? props.value : 0} </a >
            ),
        },
        { headerName: 'Lead Count', field: 'leadCount', hide: false, cellRenderer: (props) => (
                <a > {props.value ? props.value : 0} </a >
            ),
        },
        { headerName: '', field: 'actions', cellRenderer: CustomCellRenderer, hide: false },
    ])


    useEffect(() => {
        getCompanyList()
        getCountryList()
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setPopupMenu({ ...popupMenu, isOpen: false });
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [popupMenu]);

    const getCountryList = async () => {
        const { response, err } = await getCountryListApi();

        if (!err) {
            const mappedCountries = response.map(({ id, name, ...rest }) => ({
                value: id,
                label: name,
                ...rest,
            }));
            setCountryList(mappedCountries);
        }
        console.log("err", err);
    };

    const getCompanyList = async () => {
        const { response, err } = await getCompanyListApi()
        if (!err) {
            console.log("response.reverse()", response.reverse())
            setCompanyList(response.reverse())
            setInitialCompanyList(response.reverse());
            setCsvData(formatDataToCsv(response))
        }
        console.log("err", err)
    }

    const ResetFilters = () => {
        setSelectedValues({
            company: null,
            status: null,
            stages: null,
            employees: null,
            industry: null,
            country: null,
            state: null,
        })
        setBgColors(Array(7).fill("white"))
        setCompanyList(InitialCompanyList);
    }

    const handleOpenMenu = (e, rowId) => {
        e.preventDefault();
        const { clientX: x, clientY: y } = e;
        setPopupMenu({ isOpen: true, x, y, rowId });
    };

    const salesInChargesData = (data) => {
        var userList = []
        data.map((user) => {
            var name = user.username
            userList.push(name)
        })
        return userList
    }

    const companySubsidiaryOfficeData = (data) => {
        var dataList = []
        data.map((company) => {
            var data = formatCompanySubsidiaryOffice(company)
            dataList.push(data)
        });

        return dataList;
    }

    const companyBranchOfficeData = (data) => {
        var dataList = []
        data.map((company) => {
            var data = formatCompanyBranchOffice(company)
            dataList.push(data)
        });
        return dataList;
    }

    const formatCompanySubsidiaryOffice = (companySubsidiary) => {
        const { address, countryId, name, stateId } = companySubsidiary;

        let formattedCompany = name.toUpperCase();

        if (address) {
            formattedCompany += `, ${address}`;
        }

        if (stateId && countryId) {
            formattedCompany += `, ${stateId.name}, ${countryId.name}`;
        } else if (countryId) {
            formattedCompany += `, ${countryId.name}`;
        }

        return formattedCompany;
    }

    const formatCompanyBranchOffice = (companyBranch) => {
        const { name, countryId, stateId, address } = companyBranch;

        let formattedCompany = name.toUpperCase();

        if (address) {
            formattedCompany += `, ${address}`;
        }

        if (stateId && countryId) {
            formattedCompany += `, ${stateId.name}, ${countryId.name}`;
        } else if (countryId) {
            formattedCompany += `, ${countryId.name}`;
        }

        return formattedCompany;
    }

    const formatAddress = (address) => {
        const { addressline1, addressline2, countryId, stateId, zip } = address;
        let formattedAddress = addressline1;
        if (addressline2) {
            formattedAddress += `, ${addressline2}`;
        }
        if (stateId && countryId) {
            formattedAddress += `, ${stateId.name}, ${countryId.name}`;
        } else if (countryId) {
            formattedAddress += `, ${countryId.name}`;
        }

        if (zip) {
            formattedAddress += `, ${zip}`;
        }

        return formattedAddress;
    };

    const formatDataToCsv = (data) => {
        const headers = columnDefs.map((col) => col.headerName).filter(header => header !== '');
        const rows = data.map((item) => columnDefs.map(col => {
            if (col.field === 'country') {
                return item.address.countryId?.name || ''; 
            }
            if (col.field === 'state') {
                return item.address.stateId?.name || ''; 
            }
            if (col.field === 'dealCount') {
                return item[col.field] || 0; 
            }
            if(col.field === 'leadCount') {
                return item[col.field] || 0; 
            }
            return item[col.field] || ''; 
        }));
        return [headers, ...rows];
    };

    const SelectCountryFunc = async (id) => {
        const { response, err } = await getStateListApi(id);
        console.log("responsetrtrtrtr", response);
        if (response != "State not found") {
            const mappedSates = response.map(({ id, name, ...rest }) => ({
                value: id,
                label: name,
                ...rest,
            }));
            setStateList(mappedSates);
        }
        console.log("err", err);
    };

    const addQueryParameter = (params) => {
        const searchParams = new URLSearchParams(location.search);
        Object.entries(params).forEach(([key, value]) => {
            searchParams.set(key, value);
        });

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        });
    };

    const handleSelectChange = (index, selectedOption, type) => {
        const updatedBgColors = [...bgColors];
        updatedBgColors[index] = selectedOption ? 'lightgrey' : 'white'; 
        setBgColors(updatedBgColors);
        setSelectedValues((prevValues) => ({
            ...prevValues,
            [type]: selectedOption,
        }));
        addQueryParameter({ 'company': selectedOption.value });
        let filteredList = CompanyList;
        switch (type) {
            case 'company':
                break;
            case 'status':
                if (selectedValues.status) {
                    filteredList = InitialCompanyList.filter(person =>
                        person.status === selectedOption.value
                    );
                }
                else {
                    filteredList = CompanyList.filter(person =>
                        person.status === selectedOption.value
                    );
                }
                addQueryParameter({ 'status': selectedOption.value });
                break;
            case 'stages':
                if (selectedValues.stages) {
                    filteredList = InitialCompanyList.filter(person =>
                        person.stages === selectedOption.value
                    );
                }
                else {
                    filteredList = CompanyList.filter(person =>
                        person.stages === selectedOption.value
                    );
                }
                addQueryParameter({ 'stages': selectedOption.value });
                break;
            case 'employees':
                const range = selectedOption.value.split('-');
                const min = range[0] === '1000' ? 1000 : parseInt(range[0], 10);
                const max = range[1] === 'more' ? Infinity : parseInt(range[1], 10);
                addQueryParameter({ 'employees': selectedOption.value });

                if (selectedValues.employees) {
                    filteredList = InitialCompanyList.filter(person => {
                        const employeeSize = parseInt(person.employees, 10);
                        return employeeSize >= min && employeeSize <= max;
                    });
                }
                else {
                    filteredList = CompanyList.filter(person => {
                        const employeeSize = parseInt(person.employees, 10);
                        return employeeSize >= min && employeeSize <= max;
                    });
                }
                break;
            case 'industry':
                if (selectedValues.industry) {
                    filteredList = InitialCompanyList.filter(person =>
                        person.industry === selectedOption.value
                    );
                }
                else {
                    filteredList = CompanyList.filter(person =>
                        person.industry === selectedOption.value
                    );
                }
                addQueryParameter({ 'industry': selectedOption.value });
                break;
            case 'country':
                addQueryParameter({ 'country': selectedOption.value });
                SelectCountryFunc(selectedOption.value)

                if (selectedValues.country) {
                    filteredList = InitialCompanyList.filter(person =>
                        person.address.countryId.id === selectedOption.value
                    );
                }
                else {
                    filteredList = CompanyList.filter(person =>
                        person.address.countryId.id === selectedOption.value
                    );
                }
                break;
            case 'state':
                addQueryParameter({ 'state': selectedOption.value });
                if (selectedValues.state) {
                    filteredList = InitialCompanyList.filter(person =>
                        person.address.stateId.id === selectedOption.value
                    );
                }
                else {
                    filteredList = CompanyList.filter(person =>
                        person.address.stateId.id === selectedOption.value
                    );
                }
                break;
            default:
                filteredList = CompanyList; // No filter
        }
        setCompanyList(filteredList);
    };

    const handleSearch = (text) => {
        if (text.length == 0) {
            setCompanyList(InitialCompanyList)
        }
        else {
            const person = InitialCompanyList.filter((person) =>
                person.companyen.toLowerCase().includes(text.toLowerCase())
            );
            console.log(person);
            setCompanyList(person)
        }
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        console.log(selectedFile, "SELECTED FILE");

        if (selectedFile) {
            setFileName(selectedFile.name);
            if (selectedFile.size > 5 * 1024 * 1024) { // 5MB limit
                toast.error('File size exceeds 5MB');
                setFile(null);
            } else if (!['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(selectedFile.type)) {
                toast.error('Invalid file type. Only CSV and XLSX files are allowed.');
                setFile(null);
            } else {
                setFile(selectedFile);
            }
        }
    };

    const handleImport = async (event) => {
        event.preventDefault();

        if (!file) {
            toast.error('No file selected');
            return;
        }

        try {
            const response = await importCompanyApi(file);

            if (response !== undefined) {
                toast.success('File Imported successfully');
            } else {
                toast.error('Error processing file')
            }
        } catch (error) {
            toast.error('Error uploading file');
            console.error(error);
        }
    };

    return (
        <div className="main-wrapper company-list">
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col-xl-4">
                                <h3 className="page-title">Company List</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Company List</li>
                                </ul>
                            </div>
                            <div className="col-xl-8 float-end ms-auto">
                                <div className="d-flex title-head">

                                    <Link
                                        className="btn add-btn contrast-button"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasRight"
                                        aria-controls="offcanvasRight"
                                    >
                                        <i className="las la-file-download" />Import
                                    </Link>

                                    <CSVLink data={csvData} 
                                       filename={`company_${currentDate}.csv`}
                                        className="btn add-btn contrast-button">
                                        <i className="las la-file-export"/> Export
                                    </CSVLink>

                                    <Link
                                        to="/company-create"
                                        className="btn add-btn "
                                    >
                                        <i className="la la-plus-circle" />Create
                                    </Link>
                                    <div className="search-set">
                                        <div className="search-input">
                                            <a className="btn btn-searchset cursor-none">
                                                <i className="las la-search" />
                                            </a>
                                            <div className="dataTables_filter">
                                                <label>
                                                    {" "}
                                                    <input
                                                        onChange={(e) => handleSearch(e.target.value)}
                                                        type="text"
                                                        className="form-control pd-left-20 ht-35"
                                                        placeholder="Search"
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row bg-light-blue">
                        <p className="pt-3  heading"> Top companies this Quarter</p>
                        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-4">
                            <div className="card dash-widget bg-white">
                                <div className="card-body display-block">
                                    <div className="profile-view row   align-items-center">
                                        <div className="profile-img-wrap col-md-2">
                                            <div className="profile-img">
                                                <a href="#">
                                                    <img src="/assets/img/profiles/avatar-19.jpg" alt="User Image" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="profile-basic col-md-8">

                                            <div className="profile-info-left">
                                                <h3 className="user-name m-t-0">Global Infotech</h3>

                                                <div className="text-muted mt-2">
                                                    <img className="capitial-image" src={require("../../../assets/capital.png")} />
                                                    JPY 150 M
                                                </div>

                                                <div className="text-muted mt-2">
                                                    <img className="capitial-image" src={require("../../../assets/location.png")} />
                                                    Turkey, Angola
                                                </div>

                                                <div className="d-flex">
                                                    <div className="staff-id">
                                                        <span className="badge badge-soft-success fs-14">
                                                            <img
                                                                className="capitial-image"
                                                                src={require("../../../assets/revenue2.png")}
                                                                alt="Revenue"
                                                                style={{
                                                                    // margin: 6,
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    marginRight: "5px",
                                                                    alignItems: "center"
                                                                }}
                                                            />
                                                            JPY 646 Million
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-4">
                            <div className="card dash-widget bg-white">
                                <div className="card-body display-block">
                                    <div className="profile-view row align-items-center">
                                        <div className="profile-img-wrap col-md-2">
                                            <div className="profile-img">
                                                <a href="#">
                                                    <img src="/assets/img/profiles/avatar-19.jpg" alt="User Image" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="profile-basic col-md-8">

                                            <div className="profile-info-left">
                                                <h3 className="user-name m-t-0">Global Infotech</h3>
                                                <div className="text-muted mt-2">
                                                    <img className="capitial-image" src={require("../../../assets/capital.png")} />
                                                    JPY 700 B
                                                </div>
                                                <div className="text-muted mt-2">
                                                    <img className="capitial-image" src={require("../../../assets/location.png")} />
                                                    Turkey, Angola
                                                </div>
                                                <div className="d-flex">
                                                    <div className="staff-id">
                                                        <span className="badge badge-soft-success fs-14">
                                                            <img
                                                                className="capitial-image"
                                                                src={require("../../../assets/revenue2.png")}
                                                                alt="Revenue"
                                                                style={{
                                                                    // margin: 6,
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    marginRight: "5px",
                                                                    alignItems: "center"
                                                                }}
                                                            />
                                                            JPY 646 Billion
                                                        </span>
                                                    </div>
                                                </div>

                                                {/* <div class="staff-msg"><a href="chat.html" class="btn btn-custom">Send Message</a></div> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-4">
                            <div className="card dash-widget bg-white">
                                <div className="card-body display-block">
                                    <div className="profile-view row align-items-center">
                                        <div className="profile-img-wrap col-md-2">
                                            <div className="profile-img">
                                                <a href="#">
                                                    <img src="/assets/img/profiles/avatar-19.jpg" alt="User Image" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="profile-basic col-md-8">

                                            <div className="profile-info-left">
                                                <h3 className="user-name m-t-0">Global Infotech</h3>
                                                <div className="text-muted mt-2">
                                                    <img className="capitial-image" src={require("../../../assets/capital.png")} />
                                                    JPY 5000 L
                                                </div>
                                                <div className="text-muted mt-2">
                                                    <img className="capitial-image" src={require("../../../assets/location.png")} />
                                                    Turkey, Angola
                                                </div>
                                                <div className="d-flex">
                                                    <div className="staff-id">
                                                        <span className="badge badge-soft-success fs-14">
                                                            <img
                                                                className="capitial-image"
                                                                src={require("../../../assets/revenue2.png")}
                                                                alt="Revenue"
                                                                style={{
                                                                    // margin: 6,
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    marginRight: "5px",
                                                                    alignItems: "center"
                                                                }}
                                                            />
                                                            JPY 646 Billion
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="filter-fields mt-5">
                        <div className="row filter-row">
                            <div className="col-xl-2 col-md-3 col-sm-6 pr-0 filter-status">
                                <div className="input-block mb-3 form-focus select-focus">
                                    {selectedValues.company ?
                                        <p className="hidden-label">Companies</p>
                                        : null}
                                    <Select
                                        menuPortalTarget={document.body} // Renders the menu outside the component hierarchy
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            control: (styles) => ({
                                                ...styles,
                                                backgroundColor: bgColors[0],
                                            }),
                                        }}
                                        style={{ backgroundColor: bgColors[0], padding: '10px', borderRadius: '4px' }}
                                        placeholder="Companies"
                                        options={[
                                            { value: 'All Companies', label: 'All Companies' },
                                            { value: user_id, label: 'My Companies' },
                                        ]}
                                        value={selectedValues.company}  // Show the selected value
                                        onChange={(selectedOption) => handleSelectChange(0, selectedOption, "company")}

                                    />
                                </div>
                            </div>

                            <div className="col-xl-2 col-md-3 col-sm-6 filter-status pr-0">
                                <div className="input-block mb-3 form-focus select-focus">
                                    {selectedValues.status ?
                                        <p className="hidden-label">Status</p>
                                        : null}
                                    <Select
                                        style={{ backgroundColor: bgColors[1], padding: '10px', borderRadius: '4px' }}
                                        placeholder="Status"
                                        options={[
                                            { value: 'Active', label: 'Active' },
                                            { value: 'Inactive', label: 'Inactive' },
                                        ]}
                                        value={selectedValues.status}  // Show the selected value
                                        onChange={(selectedOption) => handleSelectChange(1, selectedOption, "status")}
                                        menuPortalTarget={document.body} // Renders the menu outside the component hierarchy
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            control: (styles) => ({
                                                ...styles,
                                                backgroundColor: bgColors[1],
                                            }),
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="col-xl-2 col-md-3 col-sm-6 pr-0 filter-status">
                                <div className="input-block mb-3 form-focus select-focus">
                                    {selectedValues.stages ?
                                        <p className="hidden-label">Stage</p>
                                        : null}
                                    <Select
                                        style={{ backgroundColor: bgColors[2], padding: '10px', borderRadius: '4px' }}
                                        placeholder="Stage"
                                        options={[
                                            { value: 'Target', label: 'Target' },
                                            { value: 'Potential', label: 'Potential' },
                                            { value: 'Client', label: 'Client' },
                                            { value: 'Others', label: 'Others' },
                                        ]}
                                        value={selectedValues.stages}  // Show the selected value
                                        onChange={(selectedOption) => handleSelectChange(2, selectedOption, "stages")}
                                        menuPortalTarget={document.body} // Renders the menu outside the component hierarchy
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            control: (styles) => ({
                                                ...styles,
                                                backgroundColor: bgColors[2],
                                            }),
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="col-xl-2 col-md-3 col-sm-6 filter-status pr-0 width-14">
                                <div className="input-block mb-3 form-focus select-focus">
                                    {selectedValues.employees ?
                                        <p className="hidden-label">Employee.Size</p>
                                        : null}
                                    <Select
                                        style={{ backgroundColor: bgColors[3], padding: '10px', borderRadius: '4px' }}
                                        placeholder="Employee.Size"
                                        options={[
                                            { value: "0-500", label: "0-500" },
                                            { value: "500-1000", label: "500-1000" },
                                            { value: "1000-more", label: "1000 & more" },
                                        ]}
                                        value={selectedValues.employees}  // Show the selected value
                                        onChange={(selectedOption) => handleSelectChange(3, selectedOption, "employees")}
                                        menuPortalTarget={document.body} // Renders the menu outside the component hierarchy
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            control: (styles) => ({
                                                ...styles,
                                                backgroundColor: bgColors[3],
                                            }),
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="col-xl-2 col-md-3 col-sm-6 pr-0 width-14">
                                <div className="input-block mb-3 form-focus select-focus">
                                    {selectedValues.industry ?
                                        <p className="hidden-label">Industry</p>
                                        : null}
                                    <Select
                                        style={{ backgroundColor: bgColors[4], padding: '10px', borderRadius: '4px' }}
                                        placeholder="Industry"
                                        options={[
                                            { value: "Information Technology (IT)", label: "Information Technology (IT)" },
                                            { value: "Healthcare", label: "Healthcare" },
                                            { value: "Finance", label: "Finance" },
                                        ]}
                                        value={selectedValues.industry}  // Show the selected value
                                        onChange={(selectedOption) => handleSelectChange(4, selectedOption, "industry")}
                                        menuPortalTarget={document.body} // Renders the menu outside the component hierarchy
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            control: (styles) => ({
                                                ...styles,
                                                backgroundColor: bgColors[4],
                                            }),
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="col-xl-2 col-md-3 col-sm-6 pr-0 filter-status">
                                <div className="input-block mb-3 form-focus select-focus">
                                    {selectedValues.country ?
                                        <p className="hidden-label">Country</p>
                                        : null}
                                    <Select
                                        style={{ backgroundColor: bgColors[5], padding: '10px', borderRadius: '4px' }}
                                        placeholder="Country"
                                        options={countryList}
                                        value={selectedValues.country}  // Show the selected value
                                        onChange={(selectedOption) => handleSelectChange(5, selectedOption, "country")}
                                        menuPortalTarget={document.body} // Renders the menu outside the component hierarchy
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            control: (styles) => ({
                                                ...styles,
                                                backgroundColor: bgColors[5],
                                            }),
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="col-xl-2 col-md-3 col-sm-6 pr-0 filter-status">
                                <div className="input-block mb-3 form-focus select-focus">
                                    {selectedValues.state ?
                                        <p className="hidden-label">State</p>
                                        : null}
                                    <Select
                                        style={{ backgroundColor: bgColors[6], padding: '10px', borderRadius: '4px' }}
                                        placeholder="State"
                                        options={stateList}
                                        value={selectedValues.state}  // Show the selected value
                                        onChange={(selectedOption) => handleSelectChange(6, selectedOption, "state")}
                                        menuPortalTarget={document.body} // Renders the menu outside the component hierarchy
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            control: (styles) => ({
                                                ...styles,
                                                backgroundColor: bgColors[6],
                                            }),
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="col-xl-2 col-md-3 col-sm-6 filter-reload pr-0">
                                <a onClick={ResetFilters} className="list-view btn btn-link filteroptions" style={{ marginRight: "10px" }}>
                                    <i className="fa fa-sync" />
                                </a>
                                <a data-bs-toggle="offcanvas" data-bs-target="#offcanvasdropcolumn" aria-controls="offcanvasRight" className="list-view btn btn-link filteroptions">
                                    <i className="fa fa-columns" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasdropcolumn" aria-labelledby="offcanvasRightLabel">
                        <div className="offcanvas-header">
                            <h5 id="offcanvasRightLabel">Choose which columns you see</h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <div className="EditColumnsModalPresentational__HalfWidthContainer-tehgaw-1 fsFdXZ">
                                <div className="col-lg-12 text-end form-wizard-button mb-3 mt-0">
                                    <button className="button btn-lights reset-btn" type="button" data-bs-dismiss="offcanvas" aria-label="Close">Cancel</button>
                                    <button className="btn btn-primary" type="button" onClick={() => {
                                        setColumnsDefs(tempColumn)
                                    }} data-bs-dismiss="offcanvas" aria-label="Close">Apply</button>
                                </div>
                                <div className="private-search-control">
                                    <input
                                        onChange={(e) => {

                                            const temp = columnDefs.filter((person) =>
                                                person.headerName.toLowerCase().includes(e.target.value.toLowerCase())
                                            );
                                            setColumnsDefs(temp)

                                        }}
                                        type="search"
                                        className="form-control private-form__control private-search-control__input"
                                        placeholder="Search columns..."
                                    />
                                </div>


                                {columnDefs.filter((e, i) => e.headerName != "").map((e, i) => (

                                    < label className="edit-column-checkbox">
                                        <input checked={e.hide ? false : true} onChange={() => {

                                            var data = [...columnDefs]
                                            data[i].hide = data[i].hide ? false : true
                                            setTempColumn(data)
                                        }} type="checkbox" id="myCheckbox" />
                                        <span>{e.headerName}</span>
                                    </label>

                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight">
                        <div className="offcanvas-header">
                            <h3 id="offcanvasRightLabel" className="fw-light">Don't have a file ready?</h3>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <p className="fw-medium">Start with our sample templates</p>
                            <p className="fw-light">Download our templates and add your contacts to it, and apply the format to your own spreadsheet.</p>
                            <div style={{ alignItems: 'center' }}>
                                <div className="d-flex justify-content-center align-items-center">
                                    <button
                                        type="button"
                                        className="down-templates"
                                        style={{ marginBottom: '20px' }}
                                    >
                                        Download sample template
                                        <img
                                            src={require('../../../assets/import.png')}
                                            alt="Import icon"
                                            style={{
                                                marginLeft: '10px',
                                                width: '20px',
                                                height: '20px',
                                            }}
                                        />
                                    </button>
                                </div>
                                <div className="upload-container">
                                    <form className="upload-form" onSubmit={handleImport}>
                                        <label className="file-upload-label">
                                            <input
                                                type="file"
                                                accept=".csv, .xlsx"
                                                onChange={handleFileChange}
                                                className="file-upload-input"
                                            />
                                            <i className="las la-file-download" style={{ paddingRight: '5px', color: 'blue' }}></i>
                                            <span className="file-upload-text">{fileName ? fileName : 'Choose File'}</span>
                                        </label>
                                        <button type="submit" className="upload-button file-import contrast-button">
                                            Import
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <GridTable
                        columns={columnDefs}
                        data={CompanyList}
                        pagination={true}
                        perpage={20}
                        popupMenu={popupMenu}
                        menuRef={menuRef}
                        className="ag-ltr .ag-header-cell-resize"
                    />
                </div>
            </div >
        </div >

    )
}
export default CompanyList;