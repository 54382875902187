import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'Monthly Sales',
            data: [12, 19, 3, 5, 2, 3, 7],
            backgroundColor: '#4c84ed',
            borderColor: '#4c84ed',
            borderWidth: 1
        }
    ]
};

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        tooltip: {
            callbacks: {
                label: (context) => `${context.label}: ${context.raw}`
            }
        }
    },
    scales: {
        x: {
            beginAtZero: true
        },
        y: {
            beginAtZero: true
        }
    }
};

const BarChart = () => {
    return (
        <div width="100%" height="100%">
            <Bar data={data} options={options} />
        </div>
    );
};

export default BarChart;
