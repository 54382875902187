import React, { useState } from "react";
import './sidebar.css';
import { Link, useLocation } from 'react-router-dom';

function Sidebarpage({ isCollapsed }) {


    const [open_sidemenu, setopne_sidemnu] = useState(false)
    const [open_sidemenu1, setopne_sidemnu1] = useState(false)

    const location = useLocation()
    const { pathname } = location;

    var path = location.pathname



    const isActive = (paths) => paths.includes(pathname) ? 'active' : '';

    return (

        // !isCollapsed && (
        //     <div className={`sidebar ${isCollapsed ? 'sidebaractive' : ''}`}>
        //         <div className="sidebar-inner slimscroll">
        //             <div id="sidebar-menu" className="sidebar-menu">
        //                 <ul className="sidebar-vertical">
        //                     <li className={`submenu ${location.pathname === '/' ? 'activemenu' : ''}`}>
        //                         <Link to="/">
        //                             <i className="la la-dashcube" /> <span> Dashboard</span> <span className="menu-arrow" />
        //                         </Link>
        //                     </li>
        //                     <li className={`submenu ${location.pathname === '/sales' ? 'activemenu' : ''}`}>
        //                         <Link to="">
        //                             <i className="la la-cube"></i> <span> Sales</span> <span className="menu-arrow" />
        //                         </Link>
        //                         <ul>
        //                             <li><Link to="">Company List</Link></li>
        //                             <li><Link to="">Deal List</Link></li>
        //                             <li><Link to="/leads" className={`submenu ${location.pathname === '/leads' ? 'activemenu' : ''}`}>Lead List</Link></li>
        //                         </ul>
        //                     </li>
        //                     <li className={`submenu ${location.pathname === '/billing' ? 'activemenu' : ''}`}>
        //                         <Link to="">
        //                             <i className="la la-rocket"></i> <span> Billing</span> <span className="menu-arrow" />
        //                         </Link>
        //                         <ul>
        //                             <li><Link to="">Company List</Link></li>
        //                             <li><Link to="">Estimate List</Link></li>
        //                             <li><Link to="">Invoice List</Link></li>
        //                         </ul>
        //                     </li>
        //                     <li className={`submenu ${location.pathname === '/faq' ? 'active' : ''}`}>
        //                         <Link to="">
        //                             <i className="la la-ticket"></i> <span> FAQ</span> <span className="menu-arrow" />
        //                         </Link>
        //                     </li>
        //                 </ul>
        //             </div>
        //         </div>
        //     </div >
        // )


        <div class="sidebar" id="sidebar">
            <div class="sidebar-inner slimscroll">
                <div id="sidebar-menu" class="sidebar-menu">

                    <ul class="list-inline-item list-unstyled links">

                        <li >
                            <Link className={isActive(['/'])}
                            //  to="/"
                            ><i class="la la-dashcube"></i> <span> Dashboard</span></Link>

                        </li>
                        <li className={`submenu`}>
                            <a ><i class="la la-cube"></i> <span> Sales</span> <span
                                class="menu-arrow"></span></a>
                            <ul style={open_sidemenu ? { display: "block" } : {}}>
                                <li><Link className={isActive(['/salesdashboard'])}
                                //  to="/salesdashboard"
                                >Sales Dashboard</Link></li>

                                <li><Link className={isActive(['/company-list', '/company-create', '/company-details'])}
                                 to="/company-list"
                                 >Company</Link></li>
                                <li><Link className={isActive(['/lead-list', '/lead-create', '/lead-details'])}
                                 to="/lead-list"
                                 >Lead </Link></li>
                                <li><Link className={isActive(['/deal-list', '/deal-create', '/deal-details'])} 
                                to="/deal-list"
                                >Deal </Link></li>

                            </ul>
                        </li>
                        <li class="submenu">
                            <a ><i class="la la-files-o"></i> <span> Billing</span> <span
                                class="menu-arrow"></span></a>
                            <ul style={open_sidemenu1 ? { display: "block" } : {}}>
                                <li><Link className={isActive(['/billingdashboard'])}
                                //  to="/billingdashboard"
                                >
                                    Billing Dashboard</Link></li>

                                <li><Link className={isActive(['/estimate-list', '/estimate-create', '/estimate-view'])}
                                //  to="/estimate-list"
                                 >Estimate  </Link></li>
                                <li><Link className={isActive(['/create-invoice', '/invoice-list', '/invoice-view'])}
                                //  to="/invoice-list"
                                 >Invoice </Link></li>


                            </ul>
                        </li>
                        <li class="submenu">
                            <a ><i class="la la-question"></i> <span> FAQ</span></a>

                        </li>
                    </ul>



                </div>
            </div>
        </div>
    )
}
export default Sidebarpage;