import { handleApiCall } from "../../Config/api"

export const getCountryListApi = async () => {
    try {
        const response = await handleApiCall("GET", "/country")
        return { response }
    }
    catch (err) {
        return { err }
    }
}

export const getUserListApi = async () => {
    try {
        const response = await handleApiCall("GET", "/user")
        return { response }
    }
    catch (err) {
        return { err }
    }
}

export const getStateListApi = async (id) => {
    try {
        const response = await handleApiCall("GET", "/state?id=" + id)
        return { response }
    }
    catch (err) {
        return { err }
    }

}

export const importCompanyApi = async (file) => {
    const formData = new FormData()
    formData.append("file", file)
    try {
        const response = await handleApiCall("POST", "/company/import", formData)
        return { response }
    }
    catch (err) {
        return { err }
    }
}

export const fileUpload = async (imgae) => {

    const formdata = new FormData()
    formdata.append("file", imgae)

    try {
        const response = await handleApiCall("POST", "/upload", formdata)
        return { response }
    }
    catch (err) {
        return { err }
    }
}

export const createCompany = async (body) => {
    try {
        const response = await handleApiCall("POST", "/company", body)
        return { response }
    }
    catch (err) {
        return { err }
    }
}

export const editCompany = async (body) => {
    try {
        const response = await handleApiCall("PUT", "/company", body)
        return { response }
    }
    catch (err) {
        return { err }
    }
}


export const getCompanyListApi = async () => {
    try {
        const response = await handleApiCall("GET", "/company")
        return { response }
    }
    catch (err) {
        return { err }
    }
}


export const getCompanyDetailsApi = async (id) => {
    try {
        const response = await handleApiCall("GET", "/company/byId?id=" + id)
        return { response }
    }
    catch (err) {
        return { err }

    }

}

export const deleteCompanyByIDApi = async (id) => {
    try {
        const response = await handleApiCall("DELETE", "/company?id=" + id)
        return { response }
    }
    catch (err) {
        return { err }

    }

}


export const getImageApi = async (imagepath) => {
    try {
        const response = await handleApiCall("GET", "/upload?filePath=" + imagepath)
        return { response }
    }
    catch (err) {
        return { err }

    }

}

export const getLeadListByCompanyIDApi = async (id) => {
    try {
        const response = await handleApiCall("GET", "/lead/byCompanyId?id=" + id)
        return { response }
    }
    catch (err) {
        return { err }
    }

}
