import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
    fileUpload,
    getCompanyListApi,
    getImageApi,
    getStateListApi,
    getUserListApi
} from "../../Company/ApiCall";
import Select from "react-select";
import {getLeadDetailsByID, leadUpdateApi} from "../ApiCall";
import {toast} from "react-toastify";

function LeadEdit() {
    const [CompanyList, setCompanyList] = useState([{value: "", label: ""}])
    const [salesList, setSalesList] = useState([]);
    const [leadInfo, setLeadInfo] = useState({
        "nameEnglish": "",
        "nameJapanese": "",
        "companyId": "",
        "department": "",
        "position": "",
        "email": "",
        "stage": "",
        "status": "",
        "managedBy": [],
        "remarks": "",
        "businessCard": "",
        "telephone": "",
    });
    const requiredFields = [
        "nameEnglish",
        "nameJapanese",
        "companyId",
        "department",
        "position",
        "email",
        "stage",
        "status",
        "managedBy",
    ];
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [previewImage, setPreviewImage] = useState("");
    const statusList = [
        {value: "Active", label: "Active"},
        {value: "Inactive", label: "Inactive"},
    ]
    const stageList = [
        {value: "Target", label: "Target"},
        {value: "Potential", label: "Potential"},
        {value: "Client", label: "Client"},
        {value: "Other", label: "Other"},
    ]
    const {id} = useParams()

    useEffect(() => {

        getCompanyList();
        getLeadDetails();

    }, []);

    const getCompanyList = async () => {
        const {response, err} = await getCompanyListApi()
        if (!err) {
            const mapped = response.map(({id, ...rest}) => ({
                value: id,
                label: rest.companyen
            }))
            setCompanyList(mapped)
        }
        console.log("err", err)
    }

    const getUserList = async (inCharge) => {
        const {response, err} = await getUserListApi();
        try {
            if (!err) {
                const salesInCharges = response.map(({id, username, ...rest}) => ({
                    value: id,
                    label: username,
                    checked: false,
                    ...rest,
                }));
                setSalesList(salesInCharges);
                const mappedUsers = salesInCharges.forEach((item, index) => {
                    const match = inCharge.find(obj => obj.value === item.value); // Matching by email
                    if (match) {
                        salesInCharges[index] = match;
                    }
                });
                console.log("MAPPED USER = ", mappedUsers)
            } else {
                console.log("err", err);
            }
        } catch (err) {
            return err
        }
    };

    const getLeadDetails = async () => {
        const {response, err} = await getLeadDetailsByID(id);
        console.log(response, "LEAD RES")
        if (!err) {
            let data = {...response};
            if (data.managedBy.length > 0) {
                data.managedBy = data.managedBy.map((e, i) => e.user.id)
                let mappedUser = response.managedBy.map(({user, ...rest}) => ({
                    ...user,
                    value: user.id,
                    label: user.username,
                    checked: true,
                    ...rest,
                }));
                console.log("userList", mappedUser);

                getUserList(mappedUser);
            }
            setLeadInfo(data)
        }
        console.log("err", err);
    };

    const getLeadImage = async (path) => {

        const { response, err } = await getImageApi(path)
        if (!err) {
            console.log("Preview Image of Lead", response)
            setPreviewImage(response)
        }
    }

    const handleChange = (name, value) => {
        setLeadInfo((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const UploadFile = async (file) => {
        handleChange("preview_logo", file);
        const {response, err} = await fileUpload(file);
        if (!err) {
            console.log("response", response);

            handleChange("businessCard", response);
        }
    };

    const handleSubmit = async (e) => {
        const newErrors = {};

        requiredFields.forEach((field) => {
            if (!leadInfo[field]) {
                newErrors[field] = `${field.replace(/_/g, " ")} field is required`;
            }
        });

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            console.log("FORM EDIT", leadInfo)
            const {response, err} = await leadUpdateApi(leadInfo);

            if (!err) {
                console.log("response LEAD", response);
                toast.success("Record Updated Successfully!");

                navigate("/lead-list");
            }
        }
    };


    return (

        <div className="page-wrapper custom-lead">
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            <h3 className="page-title">Lead Edit</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="#">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/lead-list">Lead</Link>
                                </li>
                                <li className="breadcrumb-item active">Lead Edit</li>
                            </ul>
                        </div>
                        {/* <div className="col-md-8 float-end ms-auto">
                            <div className="d-flex title-head">
                                <div className="view-icons">
                                    <a className="grid-view btn btn-link">
                                        <i className="las la-redo-alt" />
                                    </a>
                                    <a

                                        className="list-view btn btn-link"
                                        id="collapse-header"
                                    >
                                        <i className="las la-expand-arrows-alt" />
                                    </a>
                                    <a

                                        className="list-view btn btn-link"
                                        id="filter_search"
                                    >
                                        <i className="las la-filter" />
                                    </a>
                                </div>
                                <div className="form-sort">
                                    <a

                                        className="list-view btn btn-link"
                                        data-bs-toggle="modal"
                                        data-bs-target="#export"
                                    >
                                        <i className="las la-file-export" />
                                        Export
                                    </a>
                                </div>
                                <Link
                                    to={"/"}
                                    className="btn add-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_leads"
                                >
                                    <i className="la la-plus-circle" /> Add Leads
                                </Link>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="">
                    <form>
                        <div className="contact-input-set">
                            <div className="row">

                                <div className="col-md-5">
                                    <div className="input-block mt-3">
                                        <label className="col-form-label">
                                            Lead name (EN) <span className="text-danger">*</span>
                                        </label>
                                        <input placeholder="eg: Jhon Doe"
                                               value={leadInfo.nameEnglish}
                                               onChange={(e) => handleChange("nameEnglish", e.target.value)}
                                               className="form-control"
                                               type="text"
                                        />
                                    </div>
                                    {errors.nameEnglish && (
                                        <p className="error-text">{errors.nameEnglish}</p>
                                    )}
                                </div>

                                <div className="col-md-5">
                                    <div className="input-block mt-3">
                                        <label className="col-form-label">
                                            Lead name (JP) <span className="text-danger">*</span>
                                        </label>
                                        <input placeholder="eg: ジョン・ドゥ"
                                               value={leadInfo.nameJapanese}
                                               onChange={(e) => handleChange("nameJapanese", e.target.value)}
                                               className="form-control"
                                               type="text"
                                        />
                                    </div>
                                    {errors.nameJapanese && (
                                        <p className="error-text">{errors.nameJapanese}</p>
                                    )}
                                </div>

                                <div className="col-md-5 company-add-col">
                                    <div className="input-block mt-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <label className="col-form-label">
                                                Company name<span className="text-danger">*</span>
                                            </label>
                                        </div>
                                        <Select
                                            value={
                                                leadInfo.companyId
                                                    ? CompanyList.find(
                                                        (e, i) => e.value === leadInfo.companyId
                                                    )
                                                    : null
                                            }
                                            onChange={(e) => handleChange("companyId", e.value)}
                                            options={CompanyList}
                                        />
                                        {errors.companyId && (
                                            <p className="error-text">{errors.companyId}</p>
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-5">
                                    <div className="input-block mt-3">
                                        <label className="col-form-label">
                                            Department<span className="text-danger"> *</span>
                                        </label>
                                        <input placeholder="eg: Engineering"
                                               value={leadInfo.department}
                                               onChange={(e) => handleChange("department", e.target.value)}
                                               className="form-control"
                                               type="text"
                                        />
                                    </div>
                                    {errors.department && (
                                        <p className="error-text">{errors.department}</p>
                                    )}
                                </div>

                                <div className="col-md-5">
                                    <div className="input-block mt-3">
                                        <label className="col-form-label">
                                            Position<span className="text-danger">*</span>
                                        </label>
                                        <input placeholder="eg: Software Developer"
                                               value={leadInfo.position}
                                               onChange={(e) => handleChange("position", e.target.value)}
                                               className="form-control"
                                               type="text"
                                        />
                                    </div>
                                    {errors.position && (
                                        <p className="error-text">{errors.position}</p>
                                    )}
                                </div>

                                <div className="col-md-5">
                                    <div className="input-block mt-3">
                                        <label className="col-form-label">
                                            Email <span className="text-danger">*</span>
                                        </label>
                                        <input placeholder="eg: info@gmail.com"
                                               value={leadInfo.email}
                                               onChange={(e) => handleChange("email", e.target.value)}
                                               className="form-control"
                                               type="email"
                                        />
                                    </div>
                                    {errors.email && (
                                        <p className="error-text">{errors.email}</p>
                                    )}
                                </div>

                                <div className="col-md-5">
                                    <div className="input-block mt-3">
                                        <label className="col-form-label">
                                            Telephone
                                        </label>
                                        <input placeholder="eg: +919574636445"
                                               value={leadInfo.telephone}
                                               onChange={(e) => handleChange("telephone", e.target.value)}
                                               className="form-control"
                                               type="tel"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-5">
                                    <div className="input-block mt-3">
                                        <label className="col-form-label">
                                            Stage <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                            value={
                                                leadInfo.stage
                                                    ? stageList.find(
                                                        (e, i) => e.value === leadInfo.stage
                                                    )
                                                    : null
                                            }
                                            onChange={(e) => handleChange("stage", e.value)}
                                            options={stageList}
                                        />
                                    </div>
                                    {errors.stage && (
                                        <p className="error-text">{errors.stage}</p>
                                    )}
                                </div>

                                <div className="col-md-5">
                                    <div className="input-block mt-3">
                                        <label className="col-form-label">
                                            Status <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                            value={
                                                leadInfo.status
                                                    ? statusList.find(
                                                        (e, i) => e.value === leadInfo.status
                                                    )
                                                    : null
                                            }
                                            onChange={(e) => handleChange("status", e.value)}
                                            options={statusList}
                                        />
                                    </div>
                                    {errors.status && (
                                        <p className="error-text">{errors.status}</p>
                                    )}
                                </div>

                                <div className="col-lg-6">
                                    <div className="input-block mt-3">
                                        <label className="col-form-label">
                                            Business Card
                                        </label>
                                        <div className="profile-pic-upload">

                                            <div className="profile-pic">
                                                <div className="file-open">
                                                    <span>
                                                        {leadInfo.preview_logo ? (
                                                            <img src={URL.createObjectURL(leadInfo.businessCard)} alt="Preview" />
                                                            // <img src="/assets/img/icons/profile-upload-img.svg"
                                                            //      alt="Preview"/>
                                                        ) : (
                                                            <img src="/assets/img/icons/profile-upload-img.svg"
                                                                 alt="Preview"/>
                                                        )}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="employee-field">
                                                <div className="mb-0">
                                                    <div className="image-upload mb-0">
                                                        <input
                                                            onChange={(e) =>
                                                                UploadFile(e.target.files[0])
                                                            }
                                                            type="file"
                                                        />
                                                        <div className="image-uploads">
                                                            <h4>Upload</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        handleChange("preview_logo", "");
                                                        handleChange("logo", "");
                                                    }}
                                                    className="img-reset-btn"
                                                >
                                                    <a>Reset</a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row">

                                    <div className="col-md-8">
                                        <div className="input-block mt-3">
                                            <h3 className="heading-subtitles mt-2">
                                                Sales Information
                                            </h3>
                                            <label className="col-form-label">
                                                Managed by <span className="text-danger">*</span>
                                            </label>
                                        </div>
                                        <div className="row">
                                            {salesList.map((e, i) => (
                                                e.checked ?
                                                    <div
                                                        className="w-auto d-flex align-items-center justify-content-between sales-incharge-curve">
                                                        <div
                                                            className=" d-flex align-items-center justify-content-between">
                                                            <div className="list-image-circle ">{e.label.split(' ')
                                                                .map(name => name.charAt(0).toUpperCase())
                                                                .join('')}</div>

                                                            <div className="offset-1">{e.label}</div>
                                                        </div>
                                                        <button onClick={() => {
                                                            var data = [...leadInfo.managedBy];
                                                            data.splice(i, 1)
                                                            setLeadInfo({...leadInfo, managedBy: data})


                                                            var data1 = [...salesList]
                                                            data1[i].checked = false
                                                            setSalesList(data1)

                                                        }} type="button" className="custom-btn-close"><span
                                                            aria-hidden="true">×</span></button>
                                                    </div>

                                                    : null
                                            ))
                                            }

                                            <div className="col-md-3">

                                                <div className="">
                                                    <button data-bs-toggle="modal" data-bs-target="#add_policy"
                                                            className="btn btn-primary" type="button">
                                                        Add Person
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                        {errors.managedBy && (
                                            <p className="error-text">{errors.managedBy}</p>
                                        )}
                                    </div>

                                </div>

                                <div id="add_policy" className="modal custom-modal fade" role="dialog">
                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">SalesInCharge</h5>
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">

                                                <div className=" mt-3">
                                                    <input placeholder="search......" className="form-control"
                                                           type="text"/>
                                                </div>

                                                <div className="list-content-checkbox">
                                                    {salesList.map((e, i) => (
                                                        <div className="d-flex justify-content-between">
                                                            <div className="d-flex align-items-center">
                                                                <div className="image-circle ">{e.label.split(' ')
                                                                    .map(name => name.charAt(0).toUpperCase())
                                                                    .join('')}</div>

                                                                <p className="offset-1">{e.label}</p>
                                                            </div>
                                                            <div className="edit-column-checkbox">
                                                                <input
                                                                    checked={e.checked}
                                                                    onChange={() => {

                                                                        var data = [...leadInfo.managedBy]
                                                                        // data = []
                                                                        data.push(e.value)
                                                                        handleChange("managedBy", data);

                                                                        var data1 = [...salesList]
                                                                        data1[i].checked = !data1[i].checked;
                                                                        setSalesList(data1)

                                                                    }}
                                                                    type="checkbox"
                                                                    id="myCheckbox"
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-10">
                                        <div className="input-block mt-3">
                                            <label className="col-form-label">Remarks</label>
                                            <textarea
                                                placeholder="eg: type some information"
                                                value={leadInfo.remarks}
                                                onChange={(e) => handleChange("remarks", e.target.value)}
                                                className="form-control"
                                                rows="5"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 text-end form-wizard-button">
                                    <button
                                        className="button btn-lights reset-btn"
                                        type="back"
                                    >
                                        Back
                                    </button>
                                    <button
                                        className="button btn-lights reset-btn"
                                        type="reset"
                                    >
                                        Reset
                                    </button>
                                    <button
                                        onClick={() => handleSubmit()}
                                        className="btn btn-primary"
                                        type="button"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default LeadEdit;