import React, { useEffect, useRef, useState } from "react";
import { Container, Col, Row, Navbar, Dropdown, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
import './CompanyDetails.css';
import { Link, useLocation, useParams } from "react-router-dom";
import GridTable from "../../../components/common/GridTable";
import { getcompanyViewapi, getcompanyviewapi, getimageapi, getcompanylistapi, getCompanyDetailsApi, getImageApi, getLeadListByCompanyIDApi } from "../ApiCall";
import { createPortal } from 'react-dom';
import { ToastContainer, toast } from 'react-toastify';
import moment from "moment/moment";
import { CompanyLeadsList } from "./components/CompanyLeadsList";
import { CompanyBillingList } from "./components/CompanyBillingList";
import { CompanyBranchOfficeList } from "./components/CompanyBranchOfficeList";
import { CompanyDealsList } from "./components/CompanyDealsList";
import { getDealListByLeadIDApi } from "../../Leads/ApiCall";


function CompanyDetailsnew() {

    // function changeLanguage(language) {
    //     var element = document.getElementById("url");
    //     element.value = language;
    //     element.innerHTML = language;
    // }

    // function showDropdown() {
    //     document.getElementById("myDropdown").classNameList.toggle("show");
    // }

    const [companyDetails, setCompanyDetails] = useState({})

    const [companyLogo, setCompanyLogo] = useState("");
    const [leadsList, setLeadsList] = useState([]);
    const [dealsList, setDealsList] = useState([]);
    const { id } = useParams()

    useEffect(() => {
        getCompanyDetails();
        getLeadsListByCompanyID();
    }, [])
    useEffect(() => {
        if (leadsList.length > 0) {
            getDealListByLeadID();
        }
    }, [leadsList]);

    // Close the dropdown if the user clicks outside of it
    // window.onclick = function (event) {
    //     if (!event.target.matches(".dropbtn")) {
    //         var dropdowns = document.getElementsByclassName("dropdown-content");
    //         var i;
    //         for (i = 0; i < dropdowns.length; i++) {
    //             var openDropdown = dropdowns[i];
    //             if (openDropdown.classNameList.contains("show")) {
    //                 openDropdown.classNameList.remove("show");
    //             }
    //         }
    //     }
    // };

    const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const day = ('0' + d.getDate()).slice(-2); 
        const month = ('0' + (d.getMonth() + 1)).slice(-2); 
        return `${year}-${day}-${month}`;
    };
    const handleChange = (event) => {
        const { value } = event.target;
        setCompanyDetails((prevCompanyDetails) => ({
            ...prevCompanyDetails, 
            stages: value           
        }));

    };

    window.onclick = function (event) {
        if (!event.target.matches(".dropbtn")) {
            var dropdowns = document.getElementsByClassName("dropdown-content");
            var i;
            for (i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains("show")) {
                    openDropdown.classList.remove("show");
                }
            }
        }
    };

    const getCompanyDetails = async () => {
        const { response, err } = await getCompanyDetailsApi(id)
        console.log("response", response)

        if (!err) {
            if (response.logo !== '') {
                loadCompanyImage(response.logo)
            }
            setCompanyDetails(response);
        }
        console.log("err", err)
    }

    const getLeadsListByCompanyID = async () => {
        const { response, err } = await getLeadListByCompanyIDApi(id)
        console.log(" getLeadsListByCompanyID response", response)
        if (!err) {
            setLeadsList(response);
        }
        console.log("err", err)
    }

    const getDealListByLeadID = async () => {
        try {
            if (!leadsList || leadsList.length === 0) {
                console.warn("No leads available to fetch deals.");
                return;
            }
            const promises = leadsList.map(async (leads) => {
                try {
                    const { response, err } = await getDealListByLeadIDApi(leads.id);
                    if (err) {
                        console.error("Error fetching deals for lead ID", leads.id, err);
                        return [];
                    }
                    return response;
                } catch (error) {
                    console.error("Unexpected error", error);
                    return [];
                }
            });
            const results = await Promise.all(promises);
            const dealsList = results.flat();
            console.log('DealsList ', dealsList);
            setDealsList(dealsList);
        } catch (error) {
            console.error("Error in getDealListByLeadID", error);
        }
    };
    

    const loadCompanyImage = async (path) => {
        const { response, err } = await getImageApi(path)
        if (!err) {
            setCompanyLogo(response)
        }
    }


    return (
        <div className="main-wrapper company_view">
            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="content container-fluid">

                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    <h3 className="page-title">Company Details</h3>
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                                        <li className="breadcrumb-item "><a href="/company-list">Company list</a></li>
                                        <li className="breadcrumb-item active">Company Details</li>
                                    </ul>
                                </div>
                                <div className="col-md-0 float-end ms-auto">

                                </div>
                            </div>
                        </div>

                        <hr />
                        <div className="row">
                            <div className="col-md-12">
                                <div className="contact-head">
                                    <div className="row align-items-center">
                                        <div className="col-sm-6">
                                            <ul className="contact-breadcrumb">
                                                <li><a href="/company-list"><i className="las la-arrow-left"></i> Companies</a></li>
                                                <li>{companyDetails.companyen}</li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <div className="contact-wrap row">
                                    <div className="contact-profile col-md-6">
                                        <div className="avatar company-avatar">
                                        {companyLogo ? (
                                            <img src={`data:image/png;base64,${companyLogo}`} alt="Company Logo" />
                                        ) : (
                                            <img src="/assets/img/company/company-01.svg" alt="Default Company Logo" />
                                        )}
                                        </div>
                                        <div className="name-user">
                                            <h4>{companyDetails.companyen}</h4>
                                            <p><i className="las la-map-marker"></i> {`${companyDetails.address?.addressline1}, 
                                            ${companyDetails.address?.addressline2? companyDetails.address?.addressline2: ""} 
                                            ${companyDetails.address?.stateId?.name}, ${companyDetails.address?.countryId?.name}` }</p>
                                            <div className="badge-rate">
                                                <p><i className="fa-solid fa-star"></i> 5.0</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="col-md-3">
                                            <div className="project-members m-b-15">
                                                <div>Created By:</div>
                                                <ul className="team-members">
                                                    <li>
                                                        <a href="#" data-bs-toggle="tooltip" title="John Doe">
                                                        <img src="/assets/img/profiles/avatar-02.jpg"  alt="User Image" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="project-members m-b-15">
                                            <div>Managed By:</div>
                                            <ul className="team-members">
                                                <li>
                                                {companyDetails?.salesInCharges?.map((e, i) => (
                                                    <a
                                                    href="#"
                                                    key={i}
                                                    data-bs-toggle="tooltip"
                                                    aria-label={e.user.username}
                                                    data-bs-original-title={e.user.username}
                                                    >
                                                    {e.user?.image ?
                                                        <img src="assets/img/avatar/rletter.png" alt="User Image" /> :
                                                        <p>
                                                            {e.user.username.charAt(0).toUpperCase()}
                                                        </p>
                                                    }
                                                    </a>
                                                    ))}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div className="col-xl-3">
                                <div className="card contact-sidebar">
                                    <div className="displyflx">
                                        <div>
                                            <h5 className="left">Basic Information</h5>
                                        </div>
                                        <div> <i className="las la-edit"></i></div>
                                    </div>

                                    <ul className="basic-info">
                                        <li>
                                            <span>
                                                <i className="las la-globe"></i>
                                            </span>
                                            <a href="#"  >{companyDetails.website}</a>
                                        </li>
                                        <li>
                                            <span>
                                                <i className="las la-mobile"></i>
                                            </span>
                                            <p>+1 17892-62982</p>
                                        </li>
                                        <li>
                                            <span>
                                                <i className="las la-calendar-week"></i>
                                            </span>
                                            <p>Created on {companyDetails.createdon? formatDate(companyDetails.createdon) : ""}</p>
                                        </li>
                                    </ul>
                                    <h5 className="left">Other Information</h5>
                                    <ul className="other-info">

                                        {/* <li><span className="other-titlenew">Stage</span>
                                            <select 
                                                className="form-select selttgclr frmwidth"
                                                aria-label="Default select example"
                                                value={companyDetails.stages}
                                                onChange={handleChange}
                                                >
                                                <option value="Target">Target</option>

                                                <option value="Potential"><i className="las la-dot-circle clrblue"></i>Potential</option>
                                                <option value="Client"><i className="las la-dot-circle clrgrnn"></i>Client</option>
                                                <option value="Others"><i className="las la-dot-circle clrgrnn"></i>Others</option>
                                            </select>

                                        </li> */}
                                        <li><span className="other-titlenew">Stage</span><span className="badge-soft-info">{companyDetails.stages}</span></li>
                                        <li><span className="other-titlenew">Status</span><span class={companyDetails.status == "Active" ? "outline-status d-flex float-end" : "outline-inactive-status d-flex float-end d-flex float-end"}>{companyDetails.status}</span></li>
                                        <li><span className="other-titlenew">Employee count</span><span>{companyDetails.employees}</span></li>
                                        <li><span className="other-titlenew">Industry</span><span class="other-titleanswer">{companyDetails.industry}</span></li>
                                        <li><span className="other-titlenew">Country</span><span>{companyDetails.address?.countryId?.name}</span></li>
                                        <li><span className="other-titlenew">State</span><span>{companyDetails.address?.stateId?.name}</span></li>
                                        <li><span className="other-titlenew">Address</span><span>{companyDetails.address?.addressline1} </span></li>

                                    </ul>
                                    <h5 className="left">Latest Leads</h5>

                                    <div className="kanban-card">
                                        <div className="kanban-card-head">
                                            <span className="bar-design bg-purple"></span>
                                            <div className="kanban-card-title"></div>
                                        </div>
                                        <div className="kanban-card-body">
                                            <ul>
                                                <li><i className="las la-user"></i>John Deo</li>
                                                <li><i className="las la-briefcase"></i>Manager </li>
                                                <li><i className="las la-tasks"></i>Sales</li>
                                                <li><i className="las la-signal"></i>Active</li>


                                            </ul>
                                        </div>

                                    </div>
                                    <h5 className="left">Performance</h5>
                                    <div className="kanban-card">
                                        <div class="kanban-card-head">
                                            <span className="bar-design bg-purple"></span>
                                            <div className="kanban-card-title"></div>
                                        </div>
                                        <div className="kanban-card-body">
                                            <ul>
                                                <li><i className="las la-briefcase"></i>jpy 150 M </li>
                                                <li><i className="las la-calendar"></i>2024</li>
                                            </ul>
                                        </div>

                                    </div>

                                    <h5 className="left">Social Profile</h5>
                                    <ul className="social-info">
                                        <li>
                                            <a href="javascript:void(0);"><i className="fa-brands fa-youtube"></i></a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);"><i className="fa-brands fa-facebook-f"></i></a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);"><i className="fa-brands fa-instagram"></i></a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);"><i className="fa-brands fa-whatsapp"></i></a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);"><i className="fa-brands fa-pinterest"></i></a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);"><i className="fa-brands fa-linkedin"></i></a>
                                        </li>
                                    </ul>

                                </div>
                            </div>


                            <div className="col-xl-9">
                                <div className="contact-tab-wrap">
                                    <ul className="contact-nav nav">
                                        <li>
                                            <a href="#" data-bs-toggle="tab" data-bs-target="#activities" className="active"><i className="las la-user-clock"></i>Activity Log</a>
                                        </li>
                                        <li>
                                            <a href="#" data-bs-toggle="tab" data-bs-target="#notes"><i className="las la-file"></i>Deals {`(${dealsList.length})`}</a>
                                        </li>
                                        <li>
                                            <a href="#" data-bs-toggle="tab" data-bs-target="#calls"><i className="las la-file"></i>Leads {`(${leadsList.length})`}</a>
                                        </li>
                                        <li>
                                            <a href="#" data-bs-toggle="tab" data-bs-target="#files"><i className="las la-file-invoice"></i>Billing (6)</a>
                                        </li>
                                        <li>
                                            <a href="#" data-bs-toggle="tab" data-bs-target="#email"><i className="las la-building"></i>Branch Offices (9)</a>
                                        </li>
                                        <li>
                                            <a href="#" data-bs-toggle="tab" data-bs-target="#subsidiaries"><i className="las la-book"></i>Subsidiaries(2)</a>
                                        </li>
                                    </ul>
                                </div>

                                <div className="contact-tab-view">
                                    <div className="tab-content pt-0">

                                        <div className="tab-pane active show" id="activities">
                                            <div className="view-header">
                                                <h4>Activities</h4>

                                            </div>
                                            <div className="profile-box flex-fill bg-white pd-25">
                                                <div className="experience-box"><ul className="experience-list">
                                                    <li className=""><div className="experience-user">
                                                        <div className="before-circle pink-bg">
                                                            <i className="la la-cubes pink-color"></i>
                                                        </div>
                                                    </div>
                                                        <div className="experience-content">
                                                            <div className="timeline-content">
                                                                <a href="#/" className="name">Created Deal </a>
                                                                <div>14.00 - May 20, 2024</div>
                                                                <span className="time">2000 - 2003</span>
                                                                <div className="height-100 mt-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div className="experience-user">
                                                            <div className="before-circle blue-bg">
                                                                <i className="la la-chart-area blue-color"></i>
                                                            </div>
                                                        </div>
                                                        <div className="experience-content">
                                                            <div className="timeline-content">
                                                                <a href="#/" className="name">Created Lead</a>
                                                                <div>12.00 - May 21, 2024</div>
                                                                <div className="height-100 mt-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div className="experience-user">
                                                            <div className="before-circle green-bg">
                                                                <i className="la la-file-text green-color"></i>
                                                            </div>
                                                        </div>
                                                        <div className="experience-content">
                                                            <div className="timeline-content">
                                                                <a href="#/" className="name">Generated invoice</a>
                                                                <div>12.00 - May 21, 2024</div>
                                                                <div className="height-100 mt-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div className="experience-user">
                                                            <div className="before-circle bg-primary">
                                                                <i className="la la-hourglass-start white-color">
                                                                </i>
                                                            </div>
                                                        </div>
                                                        <div className="experience-content">
                                                            <div className="timeline-content">
                                                                <a href="#/" className="name">Change of stage</a>
                                                                <div>12.00 - May 21, 2024</div>
                                                                <div className="height-100 mt-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="no-before">
                                                        <div className="experience-user">
                                                            <div className="before-circle bg-warning">
                                                                <i className="la la-pause green-color"></i>
                                                            </div>
                                                        </div>
                                                        <div className="experience-content">
                                                            <div className="timeline-content">
                                                                <a href="#/" className="name">Change of status</a>
                                                                <div>12.00 - May 21, 2024</div>
                                                                <div className="height-100 mt-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="notes">
                                            <div className="view-header">
                                                <h4>Deals</h4>

                                            </div>
                                            <CompanyDealsList dealsList={dealsList}/>
                                        </div>


                                        <div className="tab-pane fade" id="calls">
                                            <div className="view-header">
                                                <h4>Leads</h4>
                                            </div>
                                            <CompanyLeadsList leadsList={leadsList}/>
                                        </div>


                                        <div className="tab-pane fade" id="files">
                                            <div className="view-header">
                                                <h4>Billing</h4>
                                            </div>
                                            <CompanyBillingList />
                                        </div>


                                        <div className="tab-pane fade" id="email">
                                            <div className="view-header">
                                                <h4>Branch Offices</h4>

                                            </div>
                                            <CompanyBranchOfficeList />
                                        </div>
                                        <div className="tab-pane fade" id="subsidiaries">
                                            <div className="view-header">
                                                <h4>Subsidiaries</h4>

                                            </div>
                                            <div className="deal-list-content">
                                                <div className="row align-items-center mt-3 mb-3">
                                                    <div className="col-xl-8 col-md-10 ms-auto">
                                                        <div className="d-flex justify-content-end title-head"><a className="btn add-btn" href="/company-create"><i className="la la-plus-circle"></i>Create</a></div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-lg-4 mb-4">
                                                        <div className="card cardbgclr">
                                                            <div className="card-body">
                                                                <div className="textalgend">
                                                                    <div className="dropdown ">
                                                                        <button className="btn btn-secondary drpclr" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <i className="las la-ellipsis-v"></i>
                                                                        </button>
                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                            <li><a className="dropdown-item" href="#">Edit</a></li>
                                                                            <li><a className="dropdown-item" href="#">View</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <p className="card-text mb-1 d-flex justify-content-between"><strong className="cardinclr">Name</strong><span>Green Branches</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">Country</strong><span className="cardinclr2">India</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">State</strong><span>Tamil Nadu</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">Address</strong><span>Chennai</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">Mobile</strong><span>+91 99448 37388</span></p>
                                                                <div className="d-flex justify-content-between mt-3"><i className="fas fa-edit mx-2 carditagclr" ></i><i className="fas fa-eye mx-2 cursor"></i></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4 mb-4">
                                                        <div className="card cardbgclr">
                                                            <div className="card-body">
                                                                <div className="textalgend">
                                                                    <div className="dropdown ">
                                                                        <button className="btn btn-secondary drpclr" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <i className="las la-ellipsis-v"></i>
                                                                        </button>
                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                            <li><a className="dropdown-item" href="#">Edit</a></li>
                                                                            <li><a className="dropdown-item" href="#">View</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <p className="card-text mb-1 d-flex justify-content-between"><strong className="cardinclr">Name</strong><span>Blue Branches</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">Country</strong><span className="cardinclr2">India</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">State</strong><span>Tamil Nadu</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">Address</strong><span>Madurai</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">Mobile</strong><span>+91 99857 2587</span></p>
                                                                <div className="d-flex justify-content-between mt-3"><i className="fas fa-edit mx-2 carditagclr" ></i><i className="fas fa-eye mx-2 cursor"></i></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4 mb-4">
                                                        <div className="card cardbgclr">
                                                            <div className="card-body">
                                                                <div className="textalgend">
                                                                    <div className="dropdown ">
                                                                        <button className="btn btn-secondary drpclr" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <i className="las la-ellipsis-v"></i>
                                                                        </button>
                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                            <li><a className="dropdown-item" href="#">Edit</a></li>
                                                                            <li><a className="dropdown-item" href="#">View</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <p className="card-text mb-1 d-flex justify-content-between"><strong className="cardinclr">Name</strong><span>Red Branches</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">Country</strong><span className="cardinclr2">India</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">State</strong><span>Karnataka</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">Address</strong><span>Bengaluru</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">Mobile</strong><span>+91 89634 12365</span></p>
                                                                <div className="d-flex justify-content-between mt-3"><i className="fas fa-edit mx-2 carditagclr"></i><i className="fas fa-eye mx-2 cursor"></i></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4 mb-4">
                                                        <div className="card cardbgclr">
                                                            <div className="card-body">
                                                                <div className="textalgend">
                                                                    <div className="dropdown ">
                                                                        <button className="btn btn-secondary drpclr" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <i className="las la-ellipsis-v"></i>
                                                                        </button>
                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                            <li><a className="dropdown-item" href="#">Edit</a></li>
                                                                            <li><a className="dropdown-item" href="#">View</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <p className="card-text mb-1 d-flex justify-content-between"><strong className="cardinclr">Name</strong><span>Yellow Branches</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">Country</strong><span className="cardinclr2">India</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">State</strong><span>Tamil Nadu</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">Address</strong><span>Trichy</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">Mobile</strong><span>+91 97454 59857</span></p>
                                                                <div className="d-flex justify-content-between mt-3"><i className="fas fa-edit mx-2 carditagclr"></i><i className="fas fa-eye mx-2 cursor"></i></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4 mb-4">
                                                        <div className="card cardbgclr" >
                                                            <div className="card-body">
                                                                <div className="textalgend">
                                                                    <div className="dropdown ">
                                                                        <button className="btn btn-secondary drpclr" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <i className="las la-ellipsis-v"></i>
                                                                        </button>
                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                            <li><a className="dropdown-item" href="#">Edit</a></li>
                                                                            <li><a className="dropdown-item" href="#">View</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <p className="card-text mb-1 d-flex justify-content-between"><strong className="cardinclr">Name</strong><span>Purple Branches</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">Country</strong><span className="cardinclr2">India</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">State</strong><span>Andra Pradesh</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">Address</strong><span>Vizhag</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">Mobile</strong><span>+91 63978 25874</span></p>
                                                                <div className="d-flex justify-content-between mt-3"><i className="fas fa-edit mx-2 carditagclr"></i><i className="fas fa-eye mx-2 cursor"></i></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4 mb-4">
                                                        <div className="card cardbgclr">
                                                            <div className="card-body">
                                                                <div className="textalgend">
                                                                    <div className="dropdown ">
                                                                        <button className="btn btn-secondary drpclr" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <i className="las la-ellipsis-v"></i>
                                                                        </button>
                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                            <li><a className="dropdown-item" href="#">Edit</a></li>
                                                                            <li><a className="dropdown-item" href="#">View</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <p className="card-text mb-1 d-flex justify-content-between"><strong className="cardinclr">Name</strong><span>Black Branches</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">Country</strong><span className="cardinclr2">India</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">State</strong><span>Tamil Nadu</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">Address</strong><span>Madurai</span></p>
                                                                <p className="card-text mb-1 d-flex justify-content-between mt-3"><strong className="cardinclr">Mobile</strong><span>+91 63521 12365</span></p>
                                                                <div className="d-flex justify-content-between mt-3"><i className="fas fa-edit mx- carditagclr"></i><i className="fas fa-eye mx-2 cursor"></i></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div >


    );
}

export default CompanyDetailsnew;