import React, { useState } from "react";
import './EstimateCreate.css';
import { Link, useLocation } from "react-router-dom";


function EstimateCreate() {

    const location = useLocation();
    const [isChecked, setIsChecked] = useState(false);

    // Handle the change event
    // const handleCheckboxChange = (event) => {
    //     setIsChecked(event.target.checked);
    // };

    const [isChecked1, setIsChecked1] = useState(false);

    // Handle the change event
    const handleCheckboxChange1 = (event) => {
        setIsChecked1(event.target.checked);
    };

    const [rows, setRows] = useState([
        { id: 1, text1: '', text2: '', type: 'MD', text3: '', text4: '', isChecked: false, showDeleteButton: false },
    ]);

    const handleAddRow = () => {
        const newRow = {
            id: rows.length + 1,
            text1: '',
            text2: '',
            type: 'MD',
            text3: '',
            text4: '',
            isChecked: false,
            showDeleteButton: true,
        };
        setRows([...rows, newRow]);
    };

    const handleDeleteRow = (id) => {
        setRows(rows.filter(row => row.id !== id));
    };

    const handleInputChange = (id, name, value) => {
       
        setRows(rows.map(row => row.id === id ? { ...row, [name]: value } : row));
    };

    const handleCheckboxChange = (id) => {
        setRows(rows.map(row => row.id === id ? { ...row, isChecked: !row.isChecked } : row));
    };

    return (


        <div className="page-wrapper custom-estimate-create">
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3 className="page-title">Create Invoice</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item"><Link to="/estimate-list">Estimate</Link></li>
                                <li className={`breadcrumb-item active`} >Create Estimate</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 ">
                        <form>
                            <div className="row ">
                                <div className="invoice-data-head"><h2 className="white-head">Invoice Data</h2> </div>
                                <div className="col-sm-6 col-md-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">Estimate ID</label>
                                        <input className="form-control" type="text" />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">Company Name <span className="text-danger">*</span></label>
                                        <select className="select">
                                            <option>Please Select</option>
                                            <option selected>Client 1</option>
                                            <option>Client 1</option>
                                            <option>Client 2</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">Date Of Issue </label>
                                        <div className="cal-icon">
                                            <input className="form-control " type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">Estimate Amount</label>
                                        <input className="form-control" type="text" />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">Deal ID</label>
                                        <select className="select">
                                            <option>ID 1</option>
                                            <option selected>ID 2</option>
                                            <option>ID 3</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">Sales in Charge</label>
                                        <input className="form-control" type="text" />
                                    </div>
                                </div>
                                {/* <div className="col-sm-6 col-md-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">Select Bank</label>
                                        <select className="select">
                                            <option>ID 1</option>
                                            <option selected>ID 2</option>
                                            <option>ID 3</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label"> Address</label>
                                        <textarea className="form-control" rows={3} defaultValue={""} />
                                    </div>
                                </div> */}
                            </div>

                            <div className="row ">
                                <div className="invoice-data-head"><h2 className="white-head">Project Data</h2> </div>

                                <div className="col-sm-6 col-md-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">Deal Name<span className="text-danger">*</span></label>
                                        <select className="select">
                                            <option> Select</option>
                                            <option selected>Sudhanshu</option>
                                            <option>Gopal</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">Contract Type <span className="text-danger">*</span></label>
                                        <select className="select">
                                            <option>Select</option>
                                            <option selected>Sub contracting</option>
                                            <option>Onsite placement</option>
                                            <option>SES</option>
                                            <option>Consulting Services</option>
                                            <option>Contract Development</option>
                                            <option>Others</option>
                                        </select>
                                    </div>
                                </div>


                                <div className="col-sm-6 col-md-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">Work Duration <span className="text-danger">*</span></label>
                                        <div className="row">
                                            <div className="col-sm-6 col-md-6">
                                                <div className="cal-icon">
                                                    <input className="form-control " type="text" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="cal-icon">
                                                    <input className="form-control " type="text" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-6">
                                    <label className="col-form-label">No of People (Man Month)</label>
                                    <div className="row">
                                        <div className="col-sm-6 col-md-4">
                                            <div className="input-block mb-3">
                                                <input className="form-control" type="text" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4">
                                            <div className="input-block mb-3 man-month">
                                                Man Months
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4">
                                            <div className="input-block mb-3">
                                                <input className="form-control" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">Estimation Validity</label>
                                        <div className="cal-icon">
                                            <input className="form-control " type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">Payment Terms</label>
                                        <select className="select">
                                            <option>Select</option>
                                            <option selected>Net 15</option>
                                            <option>Net 30</option>
                                            <option selected>Net 45</option>
                                            <option>Net 60</option>
                                            <option>As per discussion</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="invoice-data-head" ><h2 className="white-head">Working Data</h2> </div>
                                <div className="col-md-12 col-sm-12">
                                    <div className="table-responsive">
                                        <table className="table table-hover table-white" id="addTable">
                                            <thead>
                                                <tr>

                                                    <th>NO</th>
                                                    <th className="col-md-6">PARTICULAR</th>
                                                    <th className="col-sm-2">VOL</th>
                                                    <th>RATE</th>
                                                    <th>AMOUNT</th>
                                                    <th>TAX</th>
                                                </tr>
                                            </thead>
                                            <tbody className="tbodyone">
                                                {rows.map((row, index) => (
                                                    <tr key={row.id}>
                                                        <td>{row.id}</td>
                                                        <td>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                value={row.text1}
                                                                onChange={(e) => handleInputChange(row.id, 'text1', e.target.value)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <div className="row">
                                                                <div className="col-sm-6 col-md-7">
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={row.text2}
                                                                        onChange={(e) => handleInputChange(row.id, 'text2', e.target.value)}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-6 col-md-5">
                                                                    <select
                                                                        className="select"
                                                                        value={row.type}
                                                                        onChange={(e) => handleInputChange(row.id, 'type', e.target.value)}
                                                                    >
                                                                        <option>MM</option>
                                                                        <option>MD</option>
                                                                        <option>MH</option>
                                                                        <option>Others</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                value={row.text3}
                                                                onChange={(e) => handleInputChange(row.id, 'text3', e.target.value)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                className="form-control"
                                                                readOnly
                                                                type="text"
                                                                value={row.text4}
                                                                onChange={(e) => handleInputChange(row.id, 'text4', e.target.value)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                className="checkbox-design"
                                                                type="checkbox"
                                                                checked={row.isChecked}
                                                                onChange={() => handleCheckboxChange(row.id)}
                                                            />
                                                        </td>
                                                        <td>
                                                            {row.showDeleteButton && (
                                                                <Link
                                                                    className="text-danger font-18"
                                                                    title="Delete"
                                                                    onClick={() => handleDeleteRow(row.id)}
                                                                >
                                                                 <i className="fa-regular fa-trash-can" />
                                                                </Link>
                                                            )}
                                                            {!row.showDeleteButton && (
                                                                <Link
                                                                    className="text-success font-18"
                                                                    title="Add"
                                                                    onClick={handleAddRow}
                                                                >
                                                                    <i className="fa-solid fa-plus" />
                                                                </Link>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}

                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        Management Cost
                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td>
                                                        <select className="select">
                                                            <option>0.00%</option>
                                                            <option>1.00%</option>
                                                            <option>2.00%</option>
                                                            <option>3.00%</option>
                                                            <option>4.00%</option>
                                                            <option>5.00%</option>
                                                            <option>6.00%</option>
                                                            <option>7.00%</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input className="form-control" readOnly type="text" />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className="checkbox-design"
                                                            type="checkbox"
                                                            checked={isChecked1}
                                                            onChange={handleCheckboxChange1}
                                                        />
                                                    </td>

                                                    <td><a href="javascript:void(0)" className="text-danger font-18 remove" title="Remove"><i className="fa-regular fa-trash-can" /></a></td>
                                                  </tr>
                                                {/* </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>
                                                        <input className="form-control" type="text" />
                                                    </td>
                                                    <td>
                                                        <div className="row">
                                                            <div className="col-sm-6 col-md-7">
                                                                <input className="form-control" type="text" />
                                                            </div>
                                                            <div className="col-sm-6 col-md-5">
                                                                <select className="select">
                                                                    <option>MM</option>
                                                                    <option selected>MD</option>
                                                                    <option>MH</option>
                                                                    <option>Others</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                    </td>
                                                    <td>
                                                        <input className="form-control" type="text" />
                                                    </td>
                                                    <td>
                                                        <input className="form-control" readOnly type="text" />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className="checkbox-design"
                                                            type="checkbox"
                                                            checked={isChecked1}
                                                            onChange={handleCheckboxChange1}
                                                        />
                                                    </td>

                                                    <td><a href="javascript:void(0)" className="text-danger font-18 remove" title="Remove"><i className="fa-regular fa-trash-can" /></a></td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-hover table-white">
                                            <tbody>
                                                <tr>
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td className="text-end">Total</td>
                                                    <td className="text-end pe-4">0</td>
                                                </tr>
                                                <tr>
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />

                                                    {/* colSpan={5} */}
                                                    <td className="text-end">
                                                        <label>Tax</label>
                                                        <select className="select select-text">
                                                            <option>0.00%</option>
                                                            <option>1.00%</option>
                                                            <option>2.00%</option>
                                                            <option>3.00%</option>
                                                            <option>4.00%</option>
                                                            <option>5.00%</option>
                                                            <option>6.00%</option>
                                                            <option>7.00%</option>
                                                        </select>
                                                    </td>
                                                    <td className="text-end pr-4">
                                                        <input className="form-control text-end" defaultValue={0} readOnly type="text" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={5} className="text-end">
                                                        Discount %
                                                    </td>
                                                    <td className="text-end pe-4">
                                                        <input className="form-control text-end" type="text" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={5} className="text-end pe-4">
                                                        <b>Grand Total</b>
                                                    </td>
                                                    <td className="text-end tdata-width pe-4">
                                                        <b>$ 0.00</b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row">
                                        <div className="invoice-data-head"><h2 className="white-head">Remarks</h2> </div>
                                        <div className="col-md-12">
                                            <div className="input-block mb-3">
                                                <label className="col-form-label">Your remark details Appear</label>
                                                <textarea className="form-control" defaultValue={""} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="submit-section">
                                <button className="btn btn-primary submit-btn m-r-10">Save &amp; Send</button>
                                <button className="btn btn-primary submit-btn">Save</button>
                            </div>
                        </form>
                    </div>
                </div>

            </div >
        </div >


    )
}
export default EstimateCreate;