import React, { useEffect, useState } from "react";
import "./CompanyEdit.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  editCompany,
  fileUpload,
  getCompanyDetailsApi,
  getCountryListApi,
  getImageApi,
  getStateListApi,
  getUserListApi,
} from "../ApiCall";
import Select from "react-select";
import { toast } from "react-toastify";

function CompanyEdit() {
  const navigate = useNavigate();
  const [companyInfo, setCompanyInfo] = useState({
    userId: 2,
    logo: "",
    preview_logo: "",
    website: "",
    companyen: "",
    companyjp: "",
    capital: "",
    capitalDenomination: "Million",
    capitalCurrency: "JPY",
    revenueGenerated: "0",
    revenueDenomination: "Billion",
    revenueCurrency: "USD",
    employees: "",
    status: "",
    industry: "",
    remarks: "",
    addressline1: "",
    addressline2: "",
    telephone: "",
    countryId: "",

    stateId: "",
    stages: "",
    companyBranchOffice: [],
    companySubsidiaryOffice: [],
    companyOverseasOffice: [],
    salesInCharges: [],
  });
  const { id } = useParams();
  const [previewlogo, setpreviewlogo] = useState("");
  const [countrylist, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [salesList, setSalesList] = useState([]);
  const statuslist = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ]

  const stageslist = [
    { value: "Target", label: "Target" },
    { value: "Potential", label: "Potential" },
    { value: "Client", label: "Client" },
    { value: "Other", label: "Other" },
  ]
  const industries = [
    { value: 1, label: "Information Technology (IT)" },
    { value: 2, label: "Healthcare" },
    { value: 3, label: "Finance" },
  
  ];

  
  useEffect(() => {
    getCountryList();
    getCompanyDetails()
  }, []);

  const getCompanyDetails = async () => {
    const { response, err } = await getCompanyDetailsApi(id)
    if (!err) {
      SelectCountryFunc(response.address.countryId.id)
      getCompanyLogo(response.logo)
      var resData = { ...response }
      const branchoffice = await Promise.all(resData.companyBranchOffice.map(async (e, i) => {
        try {
          const { response, err } = await getStateListApi(e.countryId.id);
          if (response !== "State not found") {
            const mappedStates = response.map(({ id, name, ...rest }) => ({
              value: id,
              label: name,
              ...rest,
            }));
            return {
              ...e,
              countryId: e.countryId.id,
              stateId: e.stateId.id,
              stateList: mappedStates
            };
          } else {
            return e; 
          }
        } catch (error) {
          console.error("Error fetching state:", error);
          return e; 
        }

      }));

      const subbranchoffice = await Promise.all(resData.companySubsidiaryOffice.map(async (e, i) => {
        try {
          const { response, err } = await getStateListApi(e.countryId.id);
          if (response !== "State not found") {
            const mappedStates = response.map(({ id, name, ...rest }) => ({
              value: id,
              label: name,
              ...rest,
            }));
            return {
              ...e,
              countryId: e.countryId.id,
              stateId: e.stateId.id,
              stateList: mappedStates
            };
          } else {
            return e; 
          }
        } catch (error) {
          console.error("Error fetching state:", error);
          return e; // Return the original object in case of error
        }

      }));
      resData.companyBranchOffice = branchoffice
      resData.companySubsidiaryOffice = subbranchoffice
      resData.companyjp = resData.companyjpn
      resData.countryId = resData.address.countryId.id
      resData.stateId = resData.address.stateId.id
      resData.telephone = resData.address.telephone
      resData.addressline1 = resData.address.addressline1
      resData.addressline2 = resData.address.addressline2
      resData.id = id
      resData.userId = 2

      if (resData.salesInCharges.length > 0) {
        const gett333 = resData.salesInCharges.map((e, i) => e.user.id)
        resData.salesInCharges = gett333
        const userList = response.salesInCharges.map(({ user, ...rest }) => ({
          ...user,
          value: user.id,
          label: user.username,
          checked: true,
          ...rest,
        }));
        console.log("userList", userList);

        getUserList(userList);
      }
      setCompanyInfo(resData)
    }
    console.log("err", err)
  }

 
  const getCompanyLogo = async (path) => {
    const { response, err } = await getImageApi(path)
    if (!err) {
      setpreviewlogo(response)
    }
  }

  
  const getCountryValue = (id) => {
    const filterCountry = countrylist.find((e) => e.value === id);
    return filterCountry ? filterCountry : 'ffff'; 
  };


  const getStateValue = (id, list) => {
    const filterState = list.find((e) => e.value === id);
    return filterState ? filterState : 'ffff'; 
  };



  const getCountryList = async () => {
    const { response, err } = await getCountryListApi();
    if (!err) {
      const mappedCountries = response.map(({ id, name, ...rest }) => ({
        value: id,
        label: name,
        ...rest,
      }));
      setCountryList([...mappedCountries]);
    }
    console.log("err", err);
  };

 
  const getUserList = async (incharge) => {
    const { response, err } = await getUserListApi();
    if (!err) {
      const mappedCountries = response.map(({ id, username, ...rest }) => ({
        value: id,
        label: username,
        checked: false,
        ...rest,
      }));
      setSalesList(mappedCountries);
      const yyyy = mappedCountries.forEach((item, index) => {
        const match = incharge.find(obj => obj.value === item.value); // Matching by email
        if (match) {
          mappedCountries[index] = match;
        }
      });
      console.log("yyyy", yyyy);
    }
    console.log("err", err);
  };
  const options = [
    { value: "chocolate", label: " Sales Incharge 1" },
    { value: "strawberry", label: " Sales Incharge 2" },
    { value: "vanilla", label: " Sales Incharge 3" },
  ];

  const capitalDenomination = [
    { value: "Thousand", label: "Thousand" },
    { value: "Million", label: "Million" },
    { value: "Billion", label: "Billion" },

  ]

  const capitalCurrency = [
    { value: "JPY", label: "JPY" },
    { value: "INR", label: "INR" },
    { value: "USD", label: "USD" },

  ]
  const requiredFields = [
    "website",
    "companyen",
    "companyjp",
    "capital",
    "employees",
    "industry",
    "countryId",
    "stateId",
    "stages",
    "status",
    "salesInCharges"
  ];

  const [errors, setErrors] = useState({});

  // Handle field changes
  const handleChange = (name, value) => {
    setCompanyInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!companyInfo[field]) {
        newErrors[field] = `${field.replace(/_/g, " ")} field is required`;
      }
    });

    console.log("Form details", companyInfo);
    console.log("newErrors", newErrors);

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // Handle form submission
      console.log("Form submitted successfully:", companyInfo);
    
      const { response, err } = await editCompany(companyInfo);
      console.log("response", response);
      if (!err) {
        console.log("response", response);
        toast.success("Record Updated Successfully!");

        navigate("/company-list");
      }
    }
  };

  const SelectCountryFunc = async (id) => {
    handleChange("countryId", id);
    handleChange("stateId", "");

    const { response, err } = await getStateListApi(id);

    console.log("responsetrtrtrtr", response);

    if (response != "State not found") {
      const mappedSates = response.map(({ id, name, ...rest }) => ({
        value: id,
        label: name,
        ...rest,
      }));
      setStateList(mappedSates);
    }
    console.log("err", err);
  };

  const ArrayStateList = async (id, i, type) => {


    const { response, err } = await getStateListApi(id);

    console.log("responsetrtrtrtr", response);

    if (response != "State not found") {
      const mappedSates = response.map(({ id, name, ...rest }) => ({
        value: id,
        label: name,
        ...rest,
      }));
      if (type === "branch") {
        var data = [...companyInfo.companyBranchOffice];
        data[i].stateList = mappedSates;
        setCompanyInfo({
          ...companyInfo,
          companyBranchOffice: data,
        });

      }
      else {

        var data = [...companyInfo.companySubsidiaryOffice];
        data[i].stateList = mappedSates;
        setCompanyInfo({
          ...companyInfo,
          companySubsidiaryOffice: data,
        });


      }


    }
    console.log("err", err);
  };


  const UploadFile = async (file) => {
    handleChange("preview_logo", file);

    const { response, err } = await fileUpload(file);

    if (!err) {
      console.log("response", response);

      handleChange("logo", response);
    }
  };

  return (
    <div className="page-wrapper custom-lead  company-create">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col-xl-12">
              <h3 className="page-title">Create Company</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="#">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/company-list">Company</Link>
                </li>
                <li className="breadcrumb-item active">Edit Company</li>
              </ul>
            </div>

          </div>
        </div>
        <div className="">
          <form>
            <div className="contact-input-set">
              <h3 className="heading-subtitles">General Information</h3>

              <div className="row">
                <div className="row">
                  <div className="col-md-6 col-xl-5 col-lg-6">
                    <div className="input-block mt-3">
                      <label className="col-form-label">
                        Website link <span className="text-danger">*</span>
                      </label>
                      <input
                        placeholder="eg: www.google.com"
                        value={companyInfo.website}
                        onChange={(e) =>
                          handleChange("website", e.target.value)
                        }
                        className="form-control"
                        type="url"
                      />
                    </div>
                    {errors.website && (
                      <p className="error-text">{errors.website}</p>
                    )}
                  </div>
                  <div className="col-md-6 col-xl-5 col-lg-6">
                    <div className="input-block mt-3">
                      <div className="upload-button">

                        <div className="file-open" >
                          {companyInfo.preview_logo ? (
                            <img src={URL.createObjectURL(companyInfo.preview_logo)} alt="Preview" />
                          ) : (
                            previewlogo ?
                              <img src={`data:image/png;base64,${previewlogo}`} alt="User Image" />
                              :
                              <img src={require("../../../assets/office-building.png")} />
                          )}

                          <div className="icons">
                            <div className="icons-div">
                              <input
                                type="file"
                                id="upload"
                                accept="image/*"
                                onChange={(e) =>
                                  UploadFile(e.target.files[0])
                                }
                                style={{ display: 'none' }}
                              />

                              <label htmlFor="upload" >
                                <i className="fas fa-edit profile-edit-icon"></i>
                              </label>

                              {companyInfo.preview_logo ?
                                <i className="fas fa-trash profile-delete-icon" onClick={() => {
                                  handleChange("preview_logo", "");
                                  handleChange("logo", "");
                                }}  ></i>

                                : null}
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.logo && (
                      <p className="error-text">{errors.logo}</p>
                    )}
                  </div>

                  <div className="col-md-6 col-xl-5 col-lg-6">
                    <div className="input-block mt-3">
                      <label className="col-form-label">
                        Company name (EN){" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        placeholder="eg: Tata Consultancy Services"
                        value={companyInfo.companyen}
                        onChange={(e) =>
                          handleChange("companyen", e.target.value)
                        }
                        className="form-control"
                        type="text"
                      />
                    </div>
                    {errors.companyen && (
                      <p className="error-text">{errors.companyen}</p>
                    )}
                  </div>
                  <div className="col-md-6 col-xl-5 col-lg-6">
                    <div className="input-block mt-3">
                      <label className="col-form-label">
                        Company name (JP){" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        placeholder="eg: タタ・コンサルタンシー・サービス"
                        value={companyInfo.companyjp}
                        onChange={(e) =>
                          handleChange("companyjp", e.target.value)
                        }
                        className="form-control"
                        type="text"
                      />
                    </div>
                    {errors.companyjp && (
                      <p className="error-text">{errors.companyjp}</p>
                    )}
                  </div>
                  {/* <div className="col-md-2"> </div> */}

                  <div className="col-md-6 col-xl-5 col-lg-6 company-add-col">
                    <div className="input-block mt-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <label className="col-form-label">
                          Country <span className="text-danger">*</span>
                        </label>
                      </div>

                      <Select
                        value={
                          companyInfo.countryId
                            ? countrylist.find(
                              (e, i) => e.value === companyInfo.countryId
                            )
                            : null
                        }
                        onChange={(e) => SelectCountryFunc(e.value)}
                        options={countrylist}
                      />
                      {/* <select onChange={(e) => SelectCountryFunc(e.target.value)} className="select">
                                                    <option value="" >Select</option>
                                                    {countrylist.map((e, i) => (
                                                        <option value={e.id}>{e.name}</option>
                                                    ))}
                                                </select> */}

                      {errors.countryId && (
                        <p className="error-text">{errors.countryId}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-xl-5 col-lg-6">
                    <div className="input-block mt-3">
                      <label className="col-form-label">
                        Prefecture / State{" "}
                        <span className="text-danger">*</span>
                      </label>

                      <Select
                        value={
                          companyInfo.stateId
                            ? stateList.find(
                              (e, i) => e.value === companyInfo.stateId
                            )
                            : null
                        }
                        onChange={(e) => handleChange("stateId", e.value)}
                        options={stateList}
                      />

                      {errors.stateId && (
                        <p className="error-text">{errors.stateId}</p>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-2"> </div> */}

                  <div className="col-md-6 col-xl-5 col-lg-6">
                    <div className="input-block mt-3">
                      <label className="col-form-label">Address</label>
                      <input
                        placeholder="eg: 2/59,street name...."
                        value={companyInfo.addressline1}
                        onChange={(e) =>
                          handleChange("addressline1", e.target.value)
                        }
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>

              
                  <div className="col-md-6 col-xl-5 col-lg-6">
                    <div className="input-block mt-3">
                      <label className="col-form-label">Telephone</label>
                      <input
                        placeholder="eg: +919574636445"
                        value={companyInfo.telephone}
                        onChange={(e) =>
                          handleChange("telephone", e.target.value)
                        }
                        className="form-control"
                        type="number"
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-xl-5 col-lg-6">
                    <div className="input-block mt-3">
                      <label className="col-form-label">
                        Employee count <span className="text-danger">*</span>
                      </label>
                      <input
                        placeholder="eg: 50"
                        value={companyInfo.employees}
                        onChange={(e) =>
                          handleChange("employees", e.target.value)
                        }
                        className="form-control"
                        type="number"
                      />
                    </div>
                    {errors.employees && (
                      <p className="error-text">{errors.employees}</p>
                    )}
                  </div>
                  <div className="col-md-6 col-xl-5 col-lg-6">
                    <div className="input-block mt-3">
                      <label className="col-form-label">
                        Industry <span className="text-danger">*</span>
                      </label>

                      <Select
                        value={
                          companyInfo.industry
                            ? industries.find(
                              (e, i) => e.label === companyInfo.industry
                            )
                            : null
                        }
                        onChange={(e) => handleChange("industry", e.label)}
                        options={industries}
                      />
                    
                    </div>
                    {errors.industry && (
                      <p className="error-text">{errors.industry}</p>
                    )}
                  </div>

                  <div class="col-md-6 col-xl-5 col-lg-6 lead-phno-col del-phno-col">
                    <div class="row">

                      <div class="col-xl-4 col-lg-4 col-md-4 d-grid align-items-end">

                        <div class="input-block mt-3 d-grid align-items-center">
                          <label class="col-form-label">Capital <span
                            class="text-danger">*</span></label>
                          <Select
                            value={
                              companyInfo.capitalCurrency
                                ? capitalCurrency.find(
                                  (e, i) => e.value === companyInfo.capitalCurrency
                                )
                                : null
                            }
                            onChange={(e) => handleChange("capitalCurrency", e.value)}
                            placeholder=""
                            options={capitalCurrency}
                          />

                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-4">
                        <div class="input-block mt-2-5">

                          <input
                            placeholder="eg: 100000"
                            value={companyInfo.capital}
                            onChange={(e) =>
                              handleChange("capital", e.target.value)
                            }
                            className="form-control"
                            type="number"
                          />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-4 d-grid align-items-end">
                        <div class="input-block mt-3 d-grid align-items-center">


                          <Select
                            value={
                              companyInfo.capitalDenomination
                                ? capitalDenomination.find(
                                  (e, i) => e.value === companyInfo.capitalDenomination
                                )
                                : null
                            }
                            onChange={(e) => handleChange("capitalDenomination", e.value)}
                            placeholder=""
                            options={capitalDenomination}
                          />
                        </div>

                      </div>

                    </div>
                    {errors.capital && (
                      <p className="error-text">{errors.capital}</p>
                    )}
                  </div>

                  <div className="col-md-6 col-xl-5 col-lg-6">
                    <div className="input-block mt-3">
                      <label className="col-form-label">
                        Stage <span className="text-danger">*</span>
                      </label>
                     
                      <Select
                        value={
                          companyInfo.stages
                            ? stageslist.find(
                              (e, i) => e.value === companyInfo.stages
                            )
                            : null
                        }
                        onChange={(e) => handleChange("stages", e.value)}
                        options={stageslist}
                      />
                    </div>
                    {errors.stages && (
                      <p className="error-text">{errors.stages}</p>
                    )}
                  </div>
                  <div className="col-md-6 col-xl-5 col-lg-6">
                    <div className="input-block mt-3">
                      <label className="col-form-label">
                        Status <span className="text-danger">*</span>
                      </label>
                      <Select
                        value={
                          companyInfo.status
                            ? statuslist.find(
                              (e, i) => e.value === companyInfo.status
                            )
                            : null
                        }
                        onChange={(e) => handleChange("status", e.value)}
                        options={statuslist}
                      />
                    </div>
                    {errors.status && (
                      <p className="error-text">{errors.status}</p>
                    )}
                  </div>
                
                </div>
            
              </div>

              <h3 className="heading-subtitles mt-2">
                Branch Offices / Subsidiaries{" "}
              </h3>

              <div className="row align-items-xl-end input-block mt-3">
                <div className="col-md-8">
                  <div className="d-flex align-items-center">
                    <label className="col-form-label width-17 f-16">Branch office {companyInfo.companyBranchOffice.length > 0 && "1"}</label>


                    <div className="col-md-4 ml-10 ">
                      <div className="">
                        <button
                          onClick={() => {
                            var data = [...companyInfo.companyBranchOffice];
                            data.push({
                              name: "",
                              stateId: "",
                              countryId: "",
                              address: "",
                              telephone: "",
                              stateList: []

                            });
                            setCompanyInfo({ ...companyInfo, companyBranchOffice: data });
                          }}
                          className="btn btn-primary"
                          type="button"
                        >
                          <i className="la la-plus" />
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              {companyInfo.companyBranchOffice.map((e, i) => (
                <div>
                  <div className="row align-items-xl-end input-block mt-3">

                    <div className="col-md-10">
                      <div className="">
                        <label className="col-form-label">Name</label>

                        <input
                          placeholder="eg: Green Branches"
                          value={e.name}
                          onChange={(e) => {
                            var data = [...companyInfo.companyBranchOffice];
                            data[i].name = e.target.value;
                            setCompanyInfo({
                              ...companyInfo,
                              companyBranchOffice: data,
                            });
                          }}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>


                    <div className="col-md-2">
                      <div className="mt-3">
                        <button
                          onClick={() => {
                            var data = [...companyInfo.companyBranchOffice];
                            data.splice(i, 1);
                            setCompanyInfo({
                              ...companyInfo,
                              companyBranchOffice: data,
                            });
                          }}
                          className="btn btn-danger"
                          type="button"
                        >
                          <i className="las la-trash" />
                        </button>
                      </div>
                    </div>



                    <div className="col-md-6 col-xl-5 col-lg-6 ">
                      <div className=" mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <label className="col-form-label">
                            Country
                          </label>
                        </div>

                        <Select
                          value={e.countryId ?
                            getCountryValue(e.countryId) : null
                          }
                          onChange={(m) => {
                            var data = [...companyInfo.companyBranchOffice];
                            data[i].countryId = m.value;
                            setCompanyInfo({
                              ...companyInfo,
                              companyBranchOffice: data,
                            });

                            ArrayStateList(m.value, i, "branch")


                          }}
                          options={countrylist}
                        />

                      </div>
                    </div>


                    <div className="col-md-6 col-xl-5 col-lg-6 ">
                      <div className="mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <label className="col-form-label">
                            State
                          </label>
                        </div>

                        <Select
                          value={
                            e.stateId ?
                              getStateValue(e.stateId, e.stateList) : null
                          }
                          onChange={(m) => {
                            var data = [...companyInfo.companyBranchOffice];
                            data[i].stateId = m.value;
                            setCompanyInfo({
                              ...companyInfo,
                              companyBranchOffice: data,
                            });

                          }}
                          options={e.stateList}
                        />

                      </div>
                    </div>
                    {/* <div className="col-md-6 col-xl-5 col-lg-6"></div> */}

                    <div className="col-md-6 col-xl-5 col-lg-6">
                      <div className="mt-3">
                        <label className="col-form-label">Address</label>

                        <input
                          placeholder="eg: 3/66, street name...."
                          value={e.address}
                          onChange={(e) => {
                            var data = [...companyInfo.companyBranchOffice];
                            data[i].address = e.target.value;
                            setCompanyInfo({
                              ...companyInfo,
                              companyBranchOffice: data,
                            });
                          }}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-5 col-lg-6">
                      <div className="mt-3">
                        <label className="col-form-label">
                          Telephone
                        </label>

                        <input
                          placeholder="eg: +91 78678686654"
                          value={e.telephone}
                          onChange={(e) => {
                            var data = [...companyInfo.companyBranchOffice];
                            data[i].telephone = e.target.value;
                            setCompanyInfo({
                              ...companyInfo,
                              companyBranchOffice: data,
                            });
                          }}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>


                  </div>

                  <hr />
                  {
                    companyInfo.companyBranchOffice.length - i > 1 &&
                    <label className="col-form-label width-17 f-16">Branch office {i + 2}</label>
                  }
                </div>
              ))}

              <div className="row align-items-xl-end input-block mt-3">
                <div className="col-md-8">
                  <div className="d-flex align-items-center">
                    <label className="col-form-label width-17 f-16">Subsidiary office {companyInfo.companySubsidiaryOffice.length > 0 && "1"}</label>


                    <div className="col-md-4 ml-30  ">
                      <div className="">
                        <button
                          onClick={() => {
                            var data = [...companyInfo.companySubsidiaryOffice];
                            data.push({
                              name: "",
                              stateId: "",
                              countryId: "",
                              address: "",
                              telephone: "",
                              stateList: []
                            });
                            setCompanyInfo({ ...companyInfo, companySubsidiaryOffice: data });
                          }}
                          className="btn btn-primary"
                          type="button"
                        >
                          <i className="la la-plus" />
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              {companyInfo.companySubsidiaryOffice.map((e, i) => (
                <div>
                  <div className="row align-items-xl-end input-block mt-3">




                    <div className="col-md-10">
                      <div className="">
                        <label className="col-form-label">Name</label>

                        <input
                          placeholder="eg: Green Branches"
                          value={e.name}
                          onChange={(e) => {
                            var data = [...companyInfo.companySubsidiaryOffice];
                            data[i].name = e.target.value;
                            setCompanyInfo({
                              ...companyInfo,
                              companySubsidiaryOffice: data,
                            });

                          }}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>


                    <div className="col-md-2">
                      <div className="mt-3">
                        <button
                          onClick={() => {
                            var data = [...companyInfo.companySubsidiaryOffice];
                            data.splice(i, 1);
                            setCompanyInfo({
                              ...companyInfo,
                              companySubsidiaryOffice: data,
                            });
                          }}
                          className="btn btn-danger"
                          type="button"
                        >
                          <i className="las la-trash" />
                        </button>
                      </div>
                    </div>



                    <div className="col-md-6 col-xl-5 col-lg-6 ">
                      <div className=" mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <label className="col-form-label">
                            Country
                          </label>
                        </div>

                        <Select
                          value={e.countryId ?
                            getCountryValue(e.countryId) : null
                          }
                          onChange={(m) => {
                            var data = [...companyInfo.companySubsidiaryOffice];
                            data[i].countryId = m.value;
                            setCompanyInfo({
                              ...companyInfo,
                              companySubsidiaryOffice: data,
                            });

                            ArrayStateList(m.value, i, "sub_branch")

                          }}
                          options={countrylist}
                        />

                      </div>
                    </div>


                    <div className="col-md-6 col-xl-5 col-lg-6 ">
                      <div className="mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <label className="col-form-label">
                            State
                          </label>
                        </div>

                        <Select
                          value={
                            e.stateId ?
                              getStateValue(e.stateId, e.stateList) : null
                          }
                          onChange={(m) => {
                            var data = [...companyInfo.companySubsidiaryOffice];
                            data[i].stateId = m.value;
                            setCompanyInfo({
                              ...companyInfo,
                              companySubsidiaryOffice: data,
                            });

                          }}
                          options={e.stateList}
                        />

                      </div>
                    </div>
                    {/* <div className="col-md-6 col-xl-5 col-lg-6"></div> */}

                    <div className="col-md-6 col-xl-5 col-lg-6">
                      <div className="mt-3">
                        <label className="col-form-label">Address</label>

                        <input
                          placeholder="eg: 3/66, street name...."
                          value={e.address}
                          onChange={(e) => {
                            var data = [...companyInfo.companySubsidiaryOffice];
                            data[i].address = e.target.value;
                            setCompanyInfo({
                              ...companyInfo,
                              companySubsidiaryOffice: data,
                            });
                          }}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-5 col-lg-6">
                      <div className="mt-3">
                        <label className="col-form-label">
                          Telephone
                        </label>

                        <input
                          placeholder="eg: +91 78678686654"
                          value={e.telephone}
                          onChange={(e) => {
                            var data = [...companyInfo.companySubsidiaryOffice];
                            data[i].telephone = e.target.value;
                            setCompanyInfo({
                              ...companyInfo,
                              companySubsidiaryOffice: data,
                            });
                          }}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>


                  </div>

                  <hr />
                  {
                    companyInfo.companySubsidiaryOffice.length - i > 1 &&
                    <label className="col-form-label width-17 f-16">Subsidiary office {i + 2}</label>
                  }
                </div>

              ))}


              <div className="row">

                <div className="col-md-8">
                  <div className="input-block mt-3">
                    <h3 className="heading-subtitles mt-2">
                      Sales Information
                    </h3>

                    <ul className="personal-info">
                      <li className="mb-2">
                        <div className="title head"> Created By</div>

                      </li>
                      <li className="mb-3">

                        <div className="project-members">

                          <ul className="team-members">
                            <li>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                aria-label="John Doe"
                                data-bs-original-title="John Doe"
                              >
                                <img src="/assets/img/avatar/avatar-27.jpg" alt="User Image" />
                              </a>

                            </li>

                          </ul>
                        </div>



                      </li>
                    </ul>

                    <label className="col-form-label">Managed by <span
                      class="text-danger">*</span></label>


                  </div>
                  <div className="row">

                    {salesList.map((e, i) => (
                      e.checked ?
                        <div className="w-auto d-flex align-items-center justify-content-between sales-incharge-curve">
                          <div className=" d-flex align-items-center justify-content-between">
                            <div className="list-image-circle ">{e.label.split(' ')
                              .map(name => name.charAt(0).toUpperCase())
                              .join('')}</div>

                            <div className="offset-1">{e.label}</div>
                          </div>
                          <button onClick={() => {
                            var data = [...companyInfo.salesInCharges];
                            data.splice(i, 1)
                            setCompanyInfo({ ...companyInfo, salesInCharges: data })


                            var data1 = [...salesList]
                            data1[i].checked = false
                            setSalesList(data1)

                          }} type="button" class="custom-btn-close"><span aria-hidden="true">×</span></button>
                        </div>

                        : null
                    ))
                    }

                    <div className="col-md-3">

                      <div className="">
                        <button data-bs-toggle="modal" data-bs-target="#add_policy" className="btn btn-primary" type="button">
                          Add Person
                        </button>
                      </div>
                    </div>



                  </div>
                </div>

              </div>

              <div className="row">
                <div class="col-md-8">
                  <div class="input-block mt-3">
                    <label class="col-form-label">Remarks</label>
                    <textarea
                      placeholder="eg: type some information"
                      value={companyInfo.remarks}
                      onChange={(e) => handleChange("remarks", e.target.value)}
                      class="form-control"
                      rows="5"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div >
        <div className="col-lg-12 text-end form-wizard-button">
          <button className="button btn-lights reset-btn" type="reset">
            Back
          </button>
          <button className="button btn-lights reset-btn" type="reset">
            Cancel
          </button>
          <button
            onClick={() => handleSubmit()}
            className="btn btn-primary"
            type="button"
          >
            Save
          </button>
        </div>
      </div >


      <div id="add_policy" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">SalesInCharges</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">

              <div className=" mb-3">
                {/* <label className="col-form-label">
                  Policy Name
                </label> */}
                <input placeholder="search......" className="form-control" type="text" />
              </div>

              <div className="list-content-checkbox">


                {salesList.map((e, i) => (
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="image-circle ">{e.label.split(' ')
                        .map(name => name.charAt(0).toUpperCase())
                        .join('')}</div>

                      <p className="offset-1">{e.label}</p>
                    </div>
                    <div className="edit-column-checkbox">
                      <input checked={e.checked} onChange={() => {

                        var data = [...companyInfo.salesInCharges]

                        if (!data.includes(e.value)) {
                          data.push(e.value)

                        }
                        handleChange("salesInCharges", data);

                        var data1 = [...salesList]
                        if (data1[i].checked) {
                          data1[i].checked = false

                        }
                        else {
                          data1[i].checked = true

                        }
                        setSalesList(data1)

                      }} type="checkbox" id="myCheckbox" />
                    </div>
                  </div>
                ))}





              </div>

            </div>
          </div>
        </div>
      </div>

    </div >
  );
}

export default CompanyEdit;
