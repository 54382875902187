import React from "react"

const DealDetailsLead = ({ leadsList = []}) => {

  return(
    <div className="deal-list-content">
    <div className="row align-items-center mt-3 mb-3">
      <div className="col-xl-8 col-md-10 ms-auto">
        <div className="d-flex justify-content-end title-head">
          <a className="btn add-btn" href="/deal-create">
            <i className="la la-plus-circle"></i>Create
          </a>
        </div>
      </div>
    </div>
    <div className="row">
      {leadsList.map((deal, index) => (
        <div className="col-md-6 col-lg-4 mb-4" key={index}>
          <div className="card cardbgclr">
            <div className="card-body">
              <p className="card-text mb-1 d-flex justify-content-between">
                <strong className="cardinclr">Name</strong>
                <span className="cardinclr2">{deal.nameEnglish}</span>
              </p>
              <p className="card-text mb-1 d-flex justify-content-between mt-3">
                <strong className="cardinclr">Status</strong>
                <span className={`outline-${deal.status === "Active" ? "status" : "inactive-status"}`}>
                  {deal.status}
                </span>
              </p>
              <p className="card-text mb-1 d-flex justify-content-between mt-3">
                <strong className="cardinclr">Stage</strong>
                <span>{deal.stage}</span>
              </p>
              <p className="card-text mb-1 d-flex justify-content-between mt-3">
                <strong className="cardinclr">Position</strong>
                <span>{deal.position}</span>
              </p>
              <p className="card-text mb-1 d-flex justify-content-between mt-3">
                <strong className="cardinclr">Department</strong>
                <span>{deal.department}</span>
              </p>
              <div className="d-flex justify-content-between mt-3">
                <i className="fas fa-edit mx-2 carditagclr"></i>
                <i className="fas fa-eye mx-2 carditagclr2"></i>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
  )
}

export { DealDetailsLead }