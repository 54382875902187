import React from "react";

const DealDetailsMemo = () => {
  const memo = [
    {
      name: 'John Doe',
      status: 'Active',
      stage: 'Negotiation',
      position: 'Team Leader',
      department: 'HR',
    },
    {
      name: 'Jane Smith',
      status: 'Active',
      stage: 'Negotiation',
      position: 'Project Manager',
      department: 'Finance',
    },
    // Add more deal objects here
  ];
  
  const MemoCard = ({ memoData }) => (
    <div className="col-md-6 col-lg-4 mb-4">
      <div className="card cardbgclr">
        <div className="card-body">
          <p className="card-text mb-1 d-flex justify-content-between">
            <strong className="cardinclr">Name</strong>
            <span className="cardinclr2">{memoData.name}</span>
          </p>
          <p className="card-text mb-1 d-flex justify-content-between mt-3">
            <strong className="cardinclr">Status</strong>
            <span className="outline-status">{memoData.status}</span>
          </p>
          <p className="card-text mb-1 d-flex justify-content-between mt-3">
            <strong className="cardinclr">Stage</strong>
            <span>{memoData.stage}</span>
          </p>
          <p className="card-text mb-1 d-flex justify-content-between mt-3">
            <strong className="cardinclr">Position</strong>
            <span>{memoData.position}</span>
          </p>
          <p className="card-text mb-1 d-flex justify-content-between mt-3">
            <strong className="cardinclr">Department</strong>
            <span>{memoData.department}</span>
          </p>
          <div className="d-flex justify-content-between mt-3">
            <i className="fas fa-edit mx-2 carditagclr"></i>
            <i className="fas fa-eye mx-2 carditagclr2"></i>
          </div>
        </div>
      </div>
    </div>
  );
  return(
    <div className="deal-list-content">
    <div className="row align-items-center mt-3 mb-3">
      <div className="col-xl-8 col-md-10 ms-auto">
        <div className="d-flex justify-content-end title-head">
          <a className="btn add-btn" href="/deal-create">
            <i className="la la-plus-circle"></i>Create
          </a>
        </div>
      </div>
    </div>
    <div className="row">
      {memo.map((memo, index) => (
        <MemoCard key={index} memoData={memo} />
      ))}
    </div>
  </div>
  )
}

export { DealDetailsMemo}