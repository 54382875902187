import axios from 'axios';
import {jwtDecode} from "jwt-decode";


const URL = 'http://209.97.169.18:8080/api'
// const URL = 'http://localhost:8080/api'

const api = axios.create({
    baseURL: URL,
});

api.interceptors.request.use(
    (config) => {
        return config;
    },
    
    (error) => {
        return Promise.reject(error);
    }
);

export const handleApiCall = async (method, endpoint, data) => {

    try {
        let response;

        switch (method) {
            case 'GET':
                response = await api.get(endpoint, await getConfig(data));
                break;

            case 'POST':
                response = await api.post(endpoint, data, await getConfig(data));
                break;

            case 'DELETE':
                response = await api.delete(endpoint, await getConfig(data));
                break;

            case 'PUT':
                response = await api.put(endpoint, data, await getConfig(data));
                break;

            default:
                throw new Error('Invalid HTTP method');
        }

        return response.data;
    } catch (error) {
        console.log("errrrrr", error)

        if (error.response.status == 500) {
            console.log("It is 500 Internal server error ===>", error)
        }
        else if (error.response.status == 401) {
            console.log("It is 401 Unauthorized error ===>", error)
        }
        else if (error.response.status == 403) {

        }
        else if (error.response.status == 422) {
            throw error;
        }
    }
};

const isTokenExpired = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decodedToken.exp < currentTime;
    } catch (error) {
        console.error('Error decoding token:', error);
        return true;
    }
};

const getConfig = async (data) => {
    const token = localStorage.getItem("accesstoken");
    if (token && isTokenExpired(token)) {
        console.log('Token is expired');
        localStorage.setItem("accesstoken", '');
        window.location.href = '/login';
    }

    const headers = {};

    if (token) {
        headers.Authorization = token;
    }
    if (data instanceof FormData) {
        headers['Content-Type'] = 'multipart/form-data';
    }
    return { headers };
};