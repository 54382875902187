// HalfDoughnutChart.js
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Register the components
ChartJS.register(ArcElement, Tooltip, Legend);

const HalfDoughnutChart = () => {
    const data = {
        labels: ['blue'],
        datasets: [
            {
                label: '# of Votes',
                data: [12],
                backgroundColor: [
                    '#4c7bed',

                ],
                borderColor: [
                    '#4c7bed',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        rotation: -90, // start angle in degrees
        circumference: 180, // sweep angle in degrees
        responsive: true,
        cutout: '90%',
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            tooltip: {
                enabled: true,
            },
        },
    };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Doughnut data={data} options={options} width="100%"/>
        </div>
    );
};

export default HalfDoughnutChart;

