import React, { } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Login from './pages/Login/Login';
import AdminLayout from './components/AdminLayout';
import LeadList from './pages/Leads/LeadList/LeadList';
import LeadCreate from './pages/Leads/LeadCreate/LeadCreate';
import CompanyList from './pages/Company/CompanyList/CompanyList';
import CompanyCreate from './pages/Company/CompanyCreate/CompanyCreate';
import DealCreate from './pages/Deal/DealCreate/DealCreate';
import DealEdit from './pages/Deal/DealEdit/DealEdit';
import DealList from './pages/Deal/DealList/DealList';
import DealDetails from './pages/Deal/DealDetails/DealDetails';
import LeadDetails from './pages/Leads/LeadDetails/LeadDetails';
import InvoiceList from './pages/Invoices/InvoiceLists/InvoiceLists';
import CreateInvoice from './pages/Invoices/CreateInvoice/CreateInvoice';
import InvoiceView from './pages/Invoices/InvoiceView/InvoiceView';
import EstimateList from './pages/Estimates/EstimateList/EstimateList';
import EstimateCreate from './pages/Estimates/EstimateCreate/EstimateCreate';
import EstimateView from './pages/Estimates/EstimateView/EstimateView';
import BillingDashboard from './pages/BillingDashboard/BillingDashboard';
import SalesDashboard from './pages/SalesDashboard/SalesDashboard';
import Dashboard from './pages/Dashboard/Dashboard';
import ScrollToTop from './components/ScrollToTop';
import CompanyEdit from './pages/Company/CompanyEdit/CompanyEdit';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ForgotPassword from './pages/Login/ForgotPassword';
import LeadEdit from "./pages/Leads/LeadEdit/LeadEdit";
import CompanyDetails from './pages/Company/CompanyDetails/CompanyDetails';


function App() {
  // const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  // const toggleSidebar = () => {
  //   setIsSidebarCollapsed(prevState => !prevState);
  // };




  // const loadScript = (src) => {
  //   return new Promise((resolve, reject) => {
  //     const script = document.createElement('script');
  //     script.src = src;
  //     script.async = true;
  //     script.onload = () => resolve();
  //     script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
  //     document.body.appendChild(script);
  //   });

  // };

  const RouteChangeHandler = ({ onRouteChange }) => {
    // const location = useLocation();
    return null;
  };
  const handleRouteChange = async (location) => {
    console.log('Route changed to:', location.pathname);
    // Load and execute the external script
    try {

      // Assuming the script defines functions or initializes something globally
    } catch (error) {
      console.error(error);
    }
  };


  const token = localStorage.getItem("accesstoken");
  console.log('token', token);

  return (
    <Router>
      <ScrollToTop />
      <ToastContainer />
      <RouteChangeHandler onRouteChange={handleRouteChange} />
      <Routes>
        <Route path="/forget-password" element={<ForgotPassword />} />
        <Route path="/login" element={token ? <Navigate to="/company-list" /> : <Login />} />
        <Route path="/" element={token ? <AdminLayout /> : <Navigate to="/login" />}>
          <Route path="lead-list" element={<LeadList />} />
          <Route path="lead-create" element={<LeadCreate />} />
          <Route path="company-list" element={<CompanyList />} />
          <Route path="company-create" element={<CompanyCreate />} />
          <Route path="company-details/:id" element={<CompanyDetails />} />
          <Route path="company-edit/:id" element={<CompanyEdit />} />
          <Route path="deal-create" element={<DealCreate />} />
          <Route path="deal-edit/:id" element={<DealEdit />} />
          <Route path="deal-list" element={<DealList />} />
          <Route path="deal-details/:id" element={<DealDetails />} />
          <Route path="lead-details/:id" element={<LeadDetails />} />
          <Route path="/invoice-list" element={<InvoiceList />} />
          <Route path="/create-invoice" element={<CreateInvoice />} />
          <Route path="/invoice-view" element={<InvoiceView />} />
          <Route path="/estimate-list" element={<EstimateList />} />
          <Route path="/estimate-create" element={<EstimateCreate />} />
          <Route path="/estimate-view" element={<EstimateView />} />
          <Route path="/billingdashboard" element={<BillingDashboard />} />
          <Route path="/salesdashboard" element={<SalesDashboard />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="lead-edit/:id" element={<LeadEdit/>} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;

