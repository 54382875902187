import { handleApiCall } from "../../Config/api"


export const createLead = async (body) => {
    try {
        const response = await handleApiCall("POST", "/lead", body)
        return { response }
    }
    catch (err) {
        return { err }
    }

}

export const getLeadListApi = async () => {
    try {
        const response = await handleApiCall("GET", "/lead")
        return { response }
    }
    catch (err) {
        return { err }
    }

}

export const leadUpdateApi = async (body) => {
    try {
        const response = await  handleApiCall("PUT", "/lead", body)
        return { response }
    }
    catch (err) {
        return err
    }
}


export const deleteLeadByIDApi = async (id) => {
    try {
        const response = await handleApiCall('DELETE', '/lead?id=' + id)
        return { response }
    }
    catch (err) {
        return err
    }
}

export const getLeadDetailsByID = async (id) => {

    try {
        const response = await handleApiCall("GET", "/lead/byId?id=" + id)
        return { response }
    }
    catch (err) {
        return { err }

    }

}

export const editLead = async (body) => {
    try {
        const response = await handleApiCall("PUT", "/lead", body)
        return { response }
    }
    catch (err) {
        return { err }
    }
}

export const getDealListByLeadIDApi = async (id) => {
    try {
        const response = await handleApiCall("GET", "/deal/byLeadId?id="+id)
        return { response }
    }
    catch (err) {
        return { err }
    }

}
