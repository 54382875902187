import React from "react";
const CompanyBranchOfficeList = () => {

  const subCompanies = [
    {
        name: "Green Branches",
        country: "India",
        state: "Tamil Nadu",
        address: "Chennai",
        mobile: "+91 99448 37388"
    },
    {
        name: "Blue Branches",
        country: "India",
        state: "Tamil Nadu",
        address: "Madurai",
        mobile: "+91 99857 2587"
    },
    {
        name: "Red Branches",
        country: "India",
        state: "Karnataka",
        address: "Bengaluru",
        mobile: "+91 89634 12365"
    },
    {
        name: "Yellow Branches",
        country: "India",
        state: "Tamil Nadu",
        address: "Trichy",
        mobile: "+91 97454 59857"
    },
    {
        name: "Purple Branches",
        country: "India",
        state: "Andhra Pradesh",
        address: "Vizag",
        mobile: "+91 63978 25874"
    },
    {
        name: "Black Branches",
        country: "India",
        state: "Tamil Nadu",
        address: "Madurai",
        mobile: "+91 63521 12365"
    }
];
  return (
    <div class="deal-list-content">
    <div class="row align-items-center mt-3 mb-3">
        <div class="col-xl-8 col-md-10 ms-auto">
            <div class="d-flex justify-content-end title-head">
                <a class="btn add-btn" href="/company-create"><i class="la la-plus-circle"></i>Create</a>
            </div>
        </div>
    </div>
    <div id="card-container" class="row">
    {subCompanies.map((company, index) => (
       <div class="col-md-6 col-lg-4 mb-4" key={index}>
            <div class="card cardbgclr">
                <div class="card-body">
                    <div class="textalgend">
                        <div class="dropdown">
                            <button class="btn btn-secondary drpclr" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="las la-ellipsis-v"></i>
                            </button>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#">Edit</a></li>
                                <li><a class="dropdown-item" href="#">View</a></li>
                            </ul>
                        </div>
                    </div>
                    <p class="card-text mb-1 d-flex justify-content-between"><strong class="cardinclr">Name</strong><span>${company.name}</span></p>
                    <p class="card-text mb-1 d-flex justify-content-between mt-3"><strong class="cardinclr">Country</strong><span class="cardinclr2">${company.country}</span></p>
                    <p class="card-text mb-1 d-flex justify-content-between mt-3"><strong class="cardinclr">State</strong><span>${company.state}</span></p>
                    <p class="card-text mb-1 d-flex justify-content-between mt-3"><strong class="cardinclr">Address</strong><span>${company.address}</span></p>
                    <p class="card-text mb-1 d-flex justify-content-between mt-3"><strong class="cardinclr">Mobile</strong><span>${company.mobile}</span></p>
                    <div class="d-flex justify-content-between mt-3">
                        <i class="fas fa-edit mx-2 carditagclr"></i>
                        <i class="fas fa-eye mx-2 cursor"></i>
                    </div>
                </div>
            </div>
        </div>
 ))}
    </div>
</div>
  )
}

export { CompanyBranchOfficeList}