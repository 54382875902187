import React from "react";
import './InvoiceView.css';
import { Link, useLocation } from "react-router-dom";
function InvoiceView() {

    const location = useLocation();
    const handlePrint = () => {
        window.print();
    };

    return (
        <div className="page-wrapper custom-viewinvoice">
            <div className="content container-fluid pb-0">
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Invoice</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item"><Link to="/invoice-list">Invoice List</Link></li>
                                <li className={`breadcrumb-item active`}>Invoice View</li>
                            </ul>
                        </div>
                        <div className="col-auto float-end ms-auto">
                            <div className="btn-group btn-group-sm">
                                <button className="btn btn-white">CSV</button>
                                <button className="btn btn-white">PDF</button>
                                <button className="btn btn-white" onClick={handlePrint}>
                                    <i className="fa-solid fa-print fa-lg" /> Print
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {/* <div className="row">
                                    <div className="col-sm-6 m-b-20">
                                        <img src="/assets/img/logo2.png" className="inv-logo" alt="Logo" />
                                        <ul className="list-unstyled">
                                            <li>Dreamguy's Technologies</li>
                                            <li>3864 Quiet Valley Lane,</li>
                                            <li>Sherman Oaks, CA, 91403</li>
                                            <li>GST No:</li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-6 m-b-20">
                                        <div className="invoice-details">
                                            <h3 className="text-uppercase">Invoice #INV-0001</h3>
                                            <ul className="list-unstyled">
                                                <li>Date: <span>March 12, 2019</span></li>
                                                <li>Due date: <span>April 25, 2019</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
                                <img src="/assets/img/logo2.png" className="inv-logo" alt="Logo" />
                                <div className="row">

                                    <div className="col-sm-6 col-lg-6 col-xl-6 m-b-20">

                                        <ul className="list-unstyled">
                                            <h4 className="">INVOICE DATA</h4>
                                            <li><div className="row"><div className="col-xl-4 col-md-4 col-lg-4 col-sm-4"><strong className="label-invoice"> Invoice ID  </strong></div><div className="col-xl-1 col-md-1 col-lg-1 col-sm-1">:</div><div className="col-xl-7 col-md-7 col-lg-7 col-sm-7">INE.180030401</div></div>
                                            <div className="row"> <div className="col-xl-4 col-md-4 col-lg-4 col-sm-4"> <strong className="label-invoice"> Company Name </strong></div><div className="col-xl-1 col-md-1 col-lg-1 col-sm-1">:</div><div className="col-xl-7 col-md-7 col-lg-7 col-sm-7">Reliance Pvt. Ltd.</div></div>
                                            <div className="row"> <div className="col-xl-4 col-md-4 col-lg-4 col-sm-4"> <strong className="label-invoice"> Address </strong></div><div className="col-xl-1 col-md-1 col-lg-1 col-sm-1">:</div><div className="col-xl-7 col-md-7 col-lg-7 col-sm-7">Reliance Pvt. Ltd.
                                                Coosada, AL, 36020
                                                United States</div></div>
                                            <div className="row"> <div className="col-xl-4 col-md-4 col-lg-4 col-sm-4"> <strong className="label-invoice">  Invoice Amount </strong></div><div className="col-xl-1 col-md-1 col-lg-1 col-sm-1">:</div><div className="col-xl-7 col-md-6 col-lg-6 col-sm-6">5000</div></div>
                                            <div className="row"> <div className="col-xl-4 col-md-4 col-lg-4 col-sm-4"> <strong className="label-invoice">  Deal ID </strong></div><div className="col-xl-1 col-md-1 col-lg-1 col-sm-1">:</div><div className="col-xl-7 col-md-7 col-lg-7 col-sm-7">180451</div></div>

                                            <div className="row"> <div className="col-xl-4 col-md-4 col-lg-4 col-sm-4"> <strong className="label-invoice">  Date of Issue </strong></div><div className="col-xl-1 col-md-1 col-lg-1 col-sm-1">:</div><div className="col-xl-7 col-md-7 col-lg-7 col-sm-7">INE.180030401</div></div>
                                            <div className="row"> <div className="col-xl-4 col-md-4 col-lg-4 col-sm-4"> <strong className="label-invoice">  Bank Details </strong></div><div className="col-xl-1 col-md-1 col-lg-1 col-sm-1">:</div><div className="col-xl-7 col-md-7 col-lg-7 col-sm-7">Axis Bank</div></div>

                                         </li>





                                        </ul>
                                    </div>
                                    <div className="col-sm-6 col-lg-6 col-xl-6 m-b-20">

                                        <ul className="list-unstyled invoice-payment-details">
                                            <h4 className="">PROJECT DATA</h4>

                                            <li><div className="row"><div className="col-xl-4 col-md-4 col-lg-4 col-sm-4"><strong className="label-invoice"> Deal Name  </strong></div><div className="col-xl-1 col-md-1 col-lg-1 col-sm-1">:</div><div className="col-xl-7 col-md-7 col-lg-7 col-sm-7">Employee Connect</div></div>
                                            <div className="row"> <div className="col-xl-4 col-md-4 col-lg-4 col-sm-4"> <strong className="label-invoice"> Work Duration </strong></div><div className="col-xl-1 col-md-1 col-lg-1 col-sm-1">:</div><div className="col-xl-7 col-md-7 col-lg-7 col-sm-7">2018 / 04 / 31 - 2018 / 05 / 15</div></div>
                                            <div className="row"> <div className="col-xl-4 col-md-4 col-lg-4 col-sm-4"> <strong className="label-invoice"> Deal Volume </strong></div><div className="col-xl-1 col-md-1 col-lg-1 col-sm-1">:</div><div className="col-xl-7 col-md-7 col-lg-7 col-sm-7">25 Man Months</div></div>
                                            <div className="row"> <div className="col-xl-4 col-md-4 col-lg-4 col-sm-4"> <strong className="label-invoice">  Contract Type </strong></div><div className="col-xl-1 col-md-1 col-lg-1 col-sm-1">:</div><div className="col-xl-7 col-md-7 col-lg-7 col-sm-7">Contractual Based Work</div></div>
                                            <div className="row"> <div className="col-xl-4 col-md-4 col-lg-4 col-sm-4"> <strong className="label-invoice"> PO Number </strong></div><div className="col-xl-1 col-md-1 col-lg-1 col-sm-1">:</div><div className="col-xl-7 col-md-7 col-lg-7 col-sm-7">INE.180030401</div></div>

                                            <div className="row"> <div className="col-xl-4 col-md-4 col-lg-4 col-sm-4"> <strong className="label-invoice"> Payment Terms </strong></div><div className="col-xl-1 col-md-1 col-lg-1 col-sm-1">:</div><div className="col-xl-7 col-md-7 col-lg-7 col-sm-7">Net 50</div></div>
                                            
                                       </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>

                                                <th>NO</th>
                                                <th className="d-none d-sm-table-cell">PARTICULAR</th>
                                                <th>VOL</th>
                                                <th>RATE</th>
                                                <th className="text-end">AMOUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Configuration</td>
                                                <td>50 MM</td>
                                                {/* <td className="d-none d-sm-table-cell">Lorem ipsum dolor sit amet, consectetur adipiscing elit</td> */}
                                                <td>500,000</td>
                                                <td className="text-end">25,000,000</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Management Cost [0.00%]</td>
                                                <td></td>
                                                {/* <td className="d-none d-sm-table-cell">Lorem ipsum dolor sit amet, consectetur adipiscing elit</td> */}
                                                <td></td>
                                                <td className="text-end">25,000,000</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Setup</td>
                                                <td>50 MM</td>
                                                {/* <td className="d-none d-sm-table-cell">Lorem ipsum dolor sit amet, consectetur adipiscing elit</td> */}
                                                <td>50,999</td>
                                                <td className="text-end">2,549,950</td>
                                            </tr>
                                            {/* <tr>
                                                <td>2</td>
                                                <td>Ios Application</td>
                                                <td className="d-none d-sm-table-cell">Lorem ipsum dolor sit amet, consectetur adipiscing elit</td>
                                                <td>$1750</td>
                                                <td>1</td>
                                                <td className="text-end">$1750</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Codeigniter Project</td>
                                                <td className="d-none d-sm-table-cell">Lorem ipsum dolor sit amet, consectetur adipiscing elit</td>
                                                <td>$90</td>
                                                <td>3</td>
                                                <td className="text-end">$270</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Phonegap Project</td>
                                                <td className="d-none d-sm-table-cell">Lorem ipsum dolor sit amet, consectetur adipiscing elit</td>
                                                <td>$1200</td>
                                                <td>2</td>
                                                <td className="text-end">$2400</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Website Optimization</td>
                                                <td className="d-none d-sm-table-cell">Lorem ipsum dolor sit amet, consectetur adipiscing elit</td>
                                                <td>$200</td>
                                                <td>2</td>
                                                <td className="text-end">$400</td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <div className="row invoice-payment">
                                        <div className="col-sm-7">
                                        </div>
                                        <div className="col-sm-5">
                                            <div className="m-b-20">
                                                <div className="table-responsive no-border">
                                                    <table className="table mb-0">
                                                        <tbody>
                                                            <tr>
                                                                <th>Subtotal:</th>
                                                                <td className="text-end">27,549,950</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Tax: <span className="text-regular">(0%)</span></th>
                                                                <td className="text-end">0</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Total:</th>
                                                                <td className="text-end text-primary"><h5>INR 27,549,950</h5></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="invoice-info">
                                        <h5>Remarks</h5>
                                        <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sed dictum ligula, cursus blandit risus. Maecenas eget metus non tellus dignissim aliquam ut a ex. Maecenas sed vehicula dui, ac suscipit lacus. Sed finibus leo vitae lorem interdum, eu scelerisque tellus fermentum. Curabitur sit amet lacinia lorem. Nullam finibus pellentesque libero, eu finibus sapien interdum vel</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >


    )
}
export default InvoiceView;