import React, { useState } from "react";
import { Link } from "react-router-dom";
import GraphChart from "../../components/common/GraphChart";
import HalfDoughnutChart from "../../components/common/BarChart";
import BarChart from "../../components/common/GraphChart";
import './Dashboard.css';

function Dashboard() {


    const [view, setView] = useState('yearly');

    const [data, setData] = useState({
        yearly: { totalDeals: 100, totalRevenue: 50000, totalCustomers: 300 },
        quarterly: { totalDeals: 25, totalRevenue: 12500, totalCustomers: 75 },
        monthly: { totalDeals: 8, totalRevenue: 4000, totalCustomers: 25 },
    });

    const handleClick = (viewType) => {
        setView(viewType);
    };

    const [progress, setProgress] = useState('table');

    const onhandleClick = (item) => {
        setProgress(item);
    }

    const [dealconversation, setDealConversation] = useState('weekly');

    const onDealhandleClick = (item) => {

        setDealConversation(item);
    }


    const [todo, setTodo] = useState('today');


    const onTodohandleClick = (item) => {

        setTodo(item);

    }

    const currentData = data[view];
    return (

        <div className="page-wrapper custom-dashboard">
            <div className="content container-fluid pb-0">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-12 col-lg-12 col-md-12 col-xl-12">
                            <h3 className="page-title">Welcome Sales!</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ul>
                        </div>


                    </div>
                </div>
                <div className="row">

                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                        <div className="card dash-widget">
                            <div className="card-body">
                                <span className="dash-widget-icon"><i className="fa-solid fa-cubes" /></span>
                                <div className="dash-widget-info">
                                    <h3>01</h3>
                                    <span>Total Company</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                        <div className="card dash-widget">
                            <div className="card-body">
                                <span className="dash-widget-icon"><i className="fa-solid fa-dollar-sign" /></span>
                                <div className="dash-widget-info">

                                    <h3>05</h3>
                                    <span>Total Leads</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                        <div className="card dash-widget">
                            <div className="card-body">
                                <span className="dash-widget-icon"><i className="fa-regular fa-gem" /></span>
                                <div className="dash-widget-info">

                                    <h3>0</h3>
                                    <span>Total Deals</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                        <div className="card dash-widget">
                            <div className="card-body">
                                <span className="dash-widget-icon"><i className="fa-regular fa-gem" /></span>
                                <div className="dash-widget-info">

                                    <h3>0</h3>
                                    <span>Total Deals</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row mb-4">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-4">
                                <div className="background-target border-to-graph">
                                    <div className="row">
                                        <div className="col-md-6 col-xl-6 border-width">
                                            <h4 className="mb-4">Target vs Achievement</h4>
                                        </div>
                                        <div className="col-md-6 col-xl-6 mb-4 float-right">
                                            <button
                                                className={`target-btn ${view === 'yearly' ? 'target-active' : ''}`}
                                                onClick={() => handleClick('yearly')}
                                            >
                                                Yearly
                                            </button>
                                            <button
                                                className={`target-btn ${view === 'quarterly' ? 'target-active' : ''}`}
                                                onClick={() => handleClick('quarterly')}
                                            >
                                                Quarterly
                                            </button>
                                            <button
                                                className={`target-btn ${view === 'monthly' ? 'target-active' : ''}`}
                                                onClick={() => handleClick('monthly')}
                                            >
                                                Monthly
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row custm-card">
                                        <div className="col-md-6">
                                            <div className="card dash-widget">
                                                <div className="card-body">
                                                    <span className="dash-widget-icon"><i className="fa-solid fa-cubes" /></span>
                                                    <div className="dash-widget-info">
                                                        <h4>{currentData.totalDeals}</h4>
                                                        <span>Total Deals</span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="card dash-widget">
                                                <div className="card-body">
                                                    <span className="dash-widget-icon"><i className="fa-solid fa-cubes" /></span>
                                                    <div className="dash-widget-info">
                                                        <h4>{currentData.totalRevenue}</h4>
                                                        <span>Total Deals</span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="card dash-widget">
                                                <div className="card-body">
                                                    <span className="dash-widget-icon"><i className="fa-solid fa-cubes" /></span>
                                                    <div className="dash-widget-info">
                                                        <h4>{currentData.totalCustomers}</h4>
                                                        <span>Total Deals</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <HalfDoughnutChart />
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="col-md-12 col-lg-12 col-xl-6 col-sm-12 d-flex">
                                <div className="card analytics-card w-100">
                                    <div className="card-body">
                                        <div className="statistic-header">
                                            <h4>Progress</h4>
                                            <div>
                                                <button
                                                    className={`progress-btn ${progress === 'graph' ? 'progress-active' : ''}`}
                                                    onClick={() => onhandleClick('graph')}
                                                >
                                                    <i className="fas fa-chart-line" /> Graph
                                                </button>
                                                <button
                                                    className={`progress-btn ${progress === 'table' ? 'progress-active' : ''}`}
                                                    onClick={() => onhandleClick('table')}
                                                >
                                                    <i className="fas fa-table" /> Table
                                                </button>
                                            </div>
                                        </div>
                                        {progress === 'table' && (
                                            <div className="table-responsive">
                                                <table className="table custom-table table-nowrap mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Target</th>
                                                            <th>Achievement</th>
                                                            <th>%</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td><a>2nd Quarter</a></td>
                                                            <td><a>0</a></td>
                                                            <td><a>0</a></td>
                                                            <td>0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td><a>1st Quarter</a></td>
                                                            <td><a>0</a></td>
                                                            <td><a>0</a></td>
                                                            <td>0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td><a>2nd Quarter</a></td>
                                                            <td><a>0</a></td>
                                                            <td><a>0</a></td>
                                                            <td>0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td><a>4th Quarter</a></td>
                                                            <td><a>0</a></td>
                                                            <td><a>0</a></td>
                                                            <td>0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td><a>Total</a></td>
                                                            <td><a>0</a></td>
                                                            <td><a>0</a></td>
                                                            <td>0.00</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>)}
                                        {progress === 'graph' && (
                                            <div className="table-responsive">
                                                <BarChart />
                                            </div>)}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                <div className="background-target border-to-graph">


                                    <div className="row border-width">
                                        <div className="col-md-6">
                                            <h4 className="mb-4">Progress</h4>
                                        </div>
                                        <div className="col-md-6 mb-4 float-right" >
                                            <button
                                                className={`progress-btn ${progress === 'graph' ? 'progress-active' : ''}`}
                                                onClick={() => onhandleClick('graph')}
                                            >
                                                <i className="fas fa-chart-line" /> Graph
                                            </button>
                                            <button
                                                className={`progress-btn ${progress === 'table' ? 'progress-active' : ''}`}
                                                onClick={() => onhandleClick('table')}
                                            >
                                                <i className="fas fa-table" /> Table
                                            </button>
                                        </div>
                                    </div>
                                    {progress === 'table' && (
                                        <table className="table table-nowrap custom-table mb-0">
                                            <thead style={{ backgroundColor: 'red' }}>
                                                <tr>
                                                    <th></th>
                                                    <th>Target</th>
                                                    <th>Achievement</th>
                                                    <th>%</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><a>Current Month</a></td>
                                                    <td><a>0</a></td>
                                                    <td><a>0</a></td>
                                                    <td>0.00</td>
                                                </tr>
                                                <tr>
                                                    <td><a>1st Quarter</a></td>
                                                    <td><a>0</a></td>
                                                    <td><a>0</a></td>
                                                    <td>0.00</td>
                                                </tr>
                                                <tr>
                                                    <td><a>2nd Quarter</a></td>
                                                    <td><a>0</a></td>
                                                    <td><a>0</a></td>
                                                    <td>0.00</td>
                                                </tr>
                                                <tr>
                                                    <td><a>3rd Quarter</a></td>
                                                    <td><a>0</a></td>
                                                    <td><a>0</a></td>
                                                    <td>0.00</td>
                                                </tr>
                                                <tr>
                                                    <td><a>4th Quarter</a></td>
                                                    <td><a>0</a></td>
                                                    <td><a>0</a></td>
                                                    <td>0.00</td>
                                                </tr>
                                                <tr>
                                                    <td><a>Total</a></td>
                                                    <td><a>0</a></td>
                                                    <td><a>0</a></td>
                                                    <td>0.00</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    )}
                                    {progress === 'graph' && (
                                        <BarChart /> // Ensure you have BarChart component imported and defined
                                    )}

                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="row">

                    <div className="col-md-12 col-lg-12 col-xl-6 col-sm-12">
                        <div className="background-target border-to-graph">
                            <div className="border-width">
                                <div className="statistic-header">
                                    <h4>Lead Generation</h4>
                                </div>
                                <GraphChart />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-12 col-xl-6 col-sm-12 d-flex">
                        <div className="card analytics-card w-100">
                            <div className="card-body">
                                <div className="statistic-header">
                                    <h4>Deal Conversation</h4>
                                    <div>
                                        <button
                                            className={`deal-btn ${dealconversation === 'weekly' ? 'deal-active' : ''}`}
                                            onClick={() => onDealhandleClick('weekly')}
                                        >
                                            Weekly
                                        </button>
                                        <button
                                            className={`deal-btn ${dealconversation === 'quarterly' ? 'deal-active' : ''}`}
                                            onClick={() => onDealhandleClick('quarterly')}
                                        >
                                            Quarterly
                                        </button>
                                    </div>
                                </div>
                                {dealconversation === 'weekly' && (
                                    <div className="table-responsive">
                                        <table className="table custom-table table-nowrap mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Weekly</th>
                                                    <th>Client</th>
                                                    <th>Hot</th>
                                                    <th>Warm</th>
                                                    <th>Cold</th>
                                                    <th>Contact</th>
                                                    <th>Dead</th>
                                                    <th>Other</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>This Week</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                </tr>
                                                <tr>
                                                    <td>1 Week Ago</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                </tr>
                                                <tr>
                                                    <td>2 Weeks Ago</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                </tr>
                                                <tr>
                                                    <td>3 Weeks Ago</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Total</strong></td>
                                                    <td><strong>-</strong></td>
                                                    <td><strong>-</strong></td>
                                                    <td><strong>-</strong></td>
                                                    <td><strong>-</strong></td>
                                                    <td><strong>-</strong></td>
                                                    <td><strong>-</strong></td>
                                                    <td><strong>-</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>)}
                                {dealconversation === 'quarterly' && (
                                    <div className="table-responsive">
                                        <table className="table custom-table table-nowrap mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Quarterly</th>
                                                    <th>Client</th>
                                                    <th>Hot</th>
                                                    <th>Warm</th>
                                                    <th>Cold</th>
                                                    <th>Contact</th>
                                                    <th>Dead</th>
                                                    <th>Other</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>This Week</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                </tr>
                                                <tr>
                                                    <td>1 Week Ago</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                </tr>
                                                <tr>
                                                    <td>2 Weeks Ago</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                </tr>
                                                <tr>
                                                    <td>3 Weeks Ago</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Total</strong></td>
                                                    <td><strong>-</strong></td>
                                                    <td><strong>-</strong></td>
                                                    <td><strong>-</strong></td>
                                                    <td><strong>-</strong></td>
                                                    <td><strong>-</strong></td>
                                                    <td><strong>-</strong></td>
                                                    <td><strong>-</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>)}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">

                    <div className="col-md-12 col-lg-12 col-xl-6 col-sm-12">
                        <div className="background-target border-to-graph">
                            <div className="card-body">
                                <div className="statistic-header">
                                    <h4>Deal Conversation</h4>
                                    <div>
                                        <button
                                            className={`deal-btn ${todo === 'today' ? 'deal-active' : ''}`}
                                            onClick={() => onTodohandleClick('today')}
                                        >
                                            Today
                                        </button>
                                        <button
                                            className={`deal-btn ${todo === 'week' ? 'deal-active' : ''}`}
                                            onClick={() => onTodohandleClick('week')}
                                        >
                                            Week
                                        </button>
                                        <button
                                            className={`deal-btn ${todo === 'month' ? 'deal-active' : ''}`}
                                            onClick={() => onTodohandleClick('month')}
                                        >
                                            Month
                                        </button>
                                    </div>
                                </div>
                                {todo === 'today' && (
                                    <div className="table-responsive">
                                        <table className="table custom-table table-nowrap mb-0">
                                            {/* <thead>
                                                <tr>
                                                    <th>WEEKLY</th>
                                                    <th>CLIENT</th>
                                                    <th>HOT</th>
                                                    <th>WARM</th>
                                                    <th>COLD</th>
                                                    <th>CONTACT</th>
                                                    <th>DEAD</th>
                                                    <th>OTHER</th>
                                                </tr>
                                            </thead> */}
                                            <tbody>
                                                <tr>
                                                    <td className="side-border-black">TEL for HOT activities</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td ><div className="zero-btn">0</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="side-border-blue">TEL for WARM activities</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td ><div className="zero-btn">0</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="side-border-black">MAIL for HOT activities</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td ><div className="zero-btn">0</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="side-border-blue">VISIT IN for HOT activities</td>

                                                    <td> </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td ><div className="zero-btn">0</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="side-border-black">VISIT IN for WARM activities</td>
                                                    <td> </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td ><div className="zero-btn">0</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="side-border-blue">VISIT OUT for HOT activitiess</td>
                                                    <td> </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td ><div className="zero-btn">0</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="side-border-black">VISIT OUT for WARM activities</td>
                                                    <td> </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td ><div className="zero-btn">0</div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>)}
                                {todo === 'week' && (
                                    <div className="table-responsive">
                                        <table className="table custom-table table-nowrap mb-0">
                                            <tbody>
                                                <tr>
                                                    <td className="side-border-black">TEL for HOT activities</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td ><div className="zero-btn">0</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="side-border-blue">TEL for WARM activities</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td ><div className="zero-btn">0</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="side-border-black">MAIL for HOT activities</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td ><div className="zero-btn">0</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="side-border-blue">VISIT IN for HOT activities</td>

                                                    <td> </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td ><div className="zero-btn">0</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="side-border-black">VISIT IN for WARM activities</td>
                                                    <td> </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td ><div className="zero-btn">0</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="side-border-blue">VISIT OUT for HOT activitiess</td>
                                                    <td> </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td ><div className="zero-btn">0</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="side-border-black">VISIT OUT for WARM activities</td>
                                                    <td> </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td ><div className="zero-btn">0</div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>)}
                                {todo === 'month' && (
                                    <div className="table-responsive">
                                        <table className="table custom-table table-nowrap mb-0">

                                            <tbody>
                                                <tr>
                                                    <td className="side-border-black">TEL for WARM activities</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td ><div className="zero-btn">0</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="side-border-blue">TEL for WARM activities</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td ><div className="zero-btn">0</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="side-border-black">MAIL for HOT activities</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td ><div className="zero-btn">0</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="side-border-blue">VISIT IN for HOT activities</td>

                                                    <td> </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td ><div className="zero-btn">0</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="side-border-black">VISIT IN for WARM activities</td>
                                                    <td> </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td ><div className="zero-btn">0</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="side-border-blue">VISIT OUT for HOT activitiess</td>
                                                    <td> </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td ><div className="zero-btn">0</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="side-border-black">VISIT OUT for WARM activities</td>
                                                    <td> </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td ><div className="zero-btn">0</div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>)}
                            </div>
                        </div>
                    </div>


                    <div className="col-md-12 col-lg-12 col-xl-6 col-sm-12">
                        <div className="background-target border-to-graph">
                            <div className="card-body">
                                <div className="statistic-header">
                                    <div className="col-md-6 col-sm-12"><h4 className="pd-10">Top Client</h4></div>

                                </div>
                                <table className="table custom-table table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th>Company</th>
                                            <th>Revenue</th>
                                            <th>%</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>

                                        </tr>
                                        <tr>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>


                                        </tr>
                                        <tr>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>

                                        </tr>
                                        <tr>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>

                                        </tr>
                                        <tr>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>

                                        </tr>
                                        <tr>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>



                </div>



            </div>

        </div >

    )
}
export default Dashboard;