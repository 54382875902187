import React from "react";
import '../LeadDetails.css';

const LeadDetailsMemo = () => {
  const memoData = [
    {
      memo1: "Memo 1",
      name: "John Doe",
      timeStamp1: "1.03",
      memo2: "Negotiation",
      timeStamp2: "1.25",
    },
    {
      memo1: "Memo 1",
      name: "John Doe",
      timeStamp1: "2.03",
      memo2: "Negotiation",
      timeStamp2: "2.50",
    },
    {
      memo1: "Memo 1",
      name: "John Doe",
      timeStamp1: "9.45",
      memo2: "Negotiation",
      timeStamp2: "7.25",
    },
    // Add more objects for additional memos
  ];
  return (
    <div className="deal-list-content">
    <div className="row align-items-center mt-3 mb-3">
      <div className="col-xl-8 col-md-10 ms-auto">
        <div className="d-flex justify-content-end title-head">
          <a className="btn add-btn" href="/lead-create">
            <i className="la la-plus-circle"></i>Create
          </a>
        </div>
      </div>
    </div>
    <div className="row">
      {memoData.map((memo, index) => (
        <div className="col-md-6 col-lg-4 mb-4" key={index}>
          <div className="card cardbgclr">
            <div className="card-body">
              <p className="card-text mb-1 d-flex justify-content-between">
                <strong className="cardinclr">{memo.memo1}</strong>
                <span className="cardinclr2">{memo.name}</span>
              </p>
              <p className="card-text mb-1 d-flex justify-content-between mt-3">
                <strong className="cardinclr">Time stamp</strong>
                <span>{memo.timeStamp1}</span>
              </p>
              <p className="card-text mb-1 d-flex justify-content-between mt-3">
                <strong className="cardinclr">Memo 2</strong>
                <span>{memo.memo2}</span>
              </p>
              <p className="card-text mb-1 d-flex justify-content-between mt-3">
                <strong className="cardinclr">Time stamp</strong>
                <span>{memo.timeStamp2}</span>
              </p>
              <div className="d-flex justify-content-between mt-3">
                <i className="fas fa-edit mx-2 carditagclr"></i>
                <i className="fas fa-eye mx-2 carditagclr2"></i>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>

  )
}

export { LeadDetailsMemo}