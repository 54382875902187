const DealsDetailsActivityLogs = () => {

  const activities = [
    {
      type: "Sent Mail",
      time: "14.00 - May 20, 2024",
      icon: "fa-envelope",
      color: "pink-bg",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      type: "Made Call",
      time: "12.00 - May 21, 2024",
      icon: "fa-phone",
      color: "blue-bg",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      type: "Visit in",
      time: "12.00 - May 21, 2024",
      icon: "la la-file-text",
      color: "green-bg",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      type: "Visit out",
      time: "12.00 - May 21, 2024",
      icon: "la la-hourglass-start",
      color: "bg-primary",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      type: "Signed NDA",
      time: "12.00 - May 21, 2024",
      icon: "la la-pause",
      color: "bg-warning",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      type: "Signed MSA",
      time: "12.00 - May 21, 2024",
      icon: "la la-pause",
      color: "bg-primary",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      type: "Created Deal",
      time: "12.00 - May 21, 2024",
      icon: "la la-pause",
      color: "bg-secondary",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      type: "Created of Stage",
      time: "12.00 - May 21, 2024",
      icon: "la la-pause",
      color: "bg-success",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      type: "Created of status",
      time: "12.00 - May 21, 2024",
      icon: "la la-pause",
      color: "bg-danger",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
  ];

  return (
    <div className="activity-logs-content">
      <div className="profile-box flex-fill bg-white pd-25">
        <div className="experience-box">
          <ul className="experience-list">
            {activities.map((activity, index) => (
              <li key={index}>
                <div className="experience-user">
                  <div className={`before-circle ${activity.color}`}>
                    <i className={`fa ${activity.icon} ${activity.color.replace("-bg", "-color")}`}></i>
                  </div>
                </div>
                <div className="experience-content">
                  <div className="timeline-content">
                    <a href="#/" className="name">{activity.type}</a>
                    <div>{activity.time}</div>
                    <div className="height-100 mt-3">{activity.description}</div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export { DealsDetailsActivityLogs}