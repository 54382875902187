import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    getDealDetailsApi,
    updateDealApi
} from "../ApiCall";
import { getUserListApi } from "../../Company/ApiCall"
import Select from "react-select";
import { toast } from "react-toastify";
import {getLeadListApi} from "../../Leads/ApiCall";

function DealEdit() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [dealInfo, setDealInfo] = useState({
        name: "",
        leadId: "",
        value: "",
        stage: "",
        closingDate: "",
        probability: "",
        nextAction: "",
        date: "",
        salesInCharge: "",
        remarks: "",
    });
    const [leadList, setLeadList] = useState([]);
    const [salesList, setSalesList] = useState([]);
    const [selectedSale, setSelectedSale] = useState([]);
    const [errors, setErrors] = useState({});
    const requiredFields = [
        "name",
        "leadId",
        "value",
        "stage",
        "closingDate",
        "probability",
        "salesInCharge",
        "date",
        "nextAction"
    ];
    const stagesList = [
        {value: "Target", label: "Target"},
        {value: "Potential", label: "Potential"},
        {value: "Client", label: "Client"},
        {value: "Other", label: "Other"},
    ]

    useEffect(() => {
        getLeadList();
        getDealDetails()
    }, []);

    const getLeadList = async () => {
        const {response, err} = await getLeadListApi()

        try {
            if (!err) {
                const mapped = response.map(({id, nameEnglish, ...rest}) => ({
                    value: id,
                    label: nameEnglish,
                    ...rest,
                }));
                setLeadList(mapped)
            }
        } catch (err) {
            return err
        }
    }

    const getDealDetails = async () => {
        const { response, err } = await getDealDetailsApi(id)
        if (!err) {
            var data = { ...response }
            console.log(data, 'DEal Data')
            getUserList(data.salesInCharge);

            setDealInfo(data)
        }
        console.log("err", err)
    }

    const getUserList = async (userId) => {
        const { response, err } = await getUserListApi();
        if (!err) {
            const usersList = response.map(({ id, username, ...rest }) => ({
                value: id,
                label: username,
                checked: false,
                ...rest,
            }));
            setSalesList(usersList);

            // To find Index of the mapped users
            const index = usersList.findIndex(item => item.value === userId);
            var updatedUser;

            if (index !== -1) {
                const user = [...usersList];
                user[index] = {
                    ...user[index],
                    checked: true
                };

                updatedUser = user
            }

            usersList.forEach((item, index) => {
                const match = updatedUser.find(obj => obj.value === item.value);
                if (match) {
                    usersList[index] = match;
                }
            });
        }
    };

    const handleChange = (name, value) => {
        let formattedValue = value;

    if (name === 'date' || name === 'closingDate') {
        // Convert date string from YYYY-MM-DD to ISO format
        formattedValue = parseDateFromInput(value);
    }

    setDealInfo((prevState) => ({
        ...prevState,
        [name]: name === 'salesInCharge' ? value[0] : formattedValue,
    }));

    };

    const formatDateForInput = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (`0${date.getMonth() + 1}`).slice(-2); // Months are zero-based
        const day = (`0${date.getDate()}`).slice(-2);
        return `${year}-${month}-${day}`;
    };

    const parseDateFromInput = (dateString) => {
        // Date string from input is in YYYY-MM-DD format
        return new Date(dateString).toISOString();
    };

    const handleCheckboxChange = (e, i) => {
        const updatedSalesList = salesList.map((item, index) => ({
            ...item,
            checked: index === i,
        }));
        const updatedSalesInCharges = updatedSalesList[i].checked ? [updatedSalesList[i].value] : [];
        handleChange('salesInCharge', updatedSalesInCharges);
        setSalesList(updatedSalesList);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        const newErrors = {};

        requiredFields.forEach((field) => {
            if (!dealInfo[field]) {
                newErrors[field] = `${field.replace(/_/g, " ")} field is required`;
            }
        });

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            // Handle form submission
            console.log("Form submitted successfully:", dealInfo);
            const { response, err } = await updateDealApi(dealInfo);
            if (!err) {
                toast.success("Record Updated Successfully!");
                navigate("/deal-list");
            }
        }
    };

    return (
        <div className="page-wrapper custom-lead  company-create">
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col-xl-12">
                            <h3 className="page-title">Create Deal</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="#">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/company-list">Deal</Link>
                                </li>
                                <li className="breadcrumb-item active">Edit Deal</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="">
                    <form>
                        <div className="contact-input-set">
                            <div className="row">

                                <div className="row">
                                    <div className="col-md-6 col-xl-5 col-lg-6">
                                        <div className="input-block mt-3">
                                            <label className="col-form-label">
                                                Deal Name (EN/JP) <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                placeholder="eg: www.google.com"
                                                value={dealInfo.name}
                                                onChange={(e) =>
                                                    handleChange("name", e.target.value)
                                                }
                                                className="form-control"
                                                type="text"
                                            />
                                        </div>
                                        {errors.name && (
                                            <p className="error-text">{errors.name}</p>
                                        )}
                                    </div>

                                    <div className="col-md-6 col-xl-5 col-lg-6">
                                        <div className="input-block mt-3">
                                            <label className="col-form-label">
                                                Lead Name <span className="text-danger">*</span>
                                            </label>
                                            <Select
                                                value={
                                                    dealInfo.leadId
                                                        ? leadList.find(
                                                            (e, i) => e.value === dealInfo.leadId
                                                        )
                                                        : null
                                                }
                                                onChange={(e) =>
                                                    handleChange("leadId", e.value)
                                                }
                                                options={leadList}
                                            />
                                        </div>
                                        {errors.leadId && (
                                            <p className="error-text">{errors.leadId}</p>
                                        )}
                                    </div>

                                    <div className="col-md-6 col-xl-5 col-lg-6">
                                        <div className="input-block mt-3">
                                            <label className="col-form-label">
                                                Deal Value
                                                <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                placeholder="eg: 1000"
                                                value={dealInfo.value}
                                                onChange={(e) =>
                                                    handleChange("value", e.target.value)
                                                }
                                                className="form-control"
                                                type="number"
                                            />
                                        </div>
                                        {errors.value && (
                                            <p className="error-text">{errors.value}</p>
                                        )}
                                    </div>

                                    <div className="col-md-6 col-xl-5 col-lg-6 company-add-col">
                                        <div className="input-block mt-3">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <label className="col-form-label">
                                                    Deal Stage <span className="text-danger">*</span>
                                                </label>
                                            </div>

                                            <Select
                                                value={
                                                    dealInfo.stage
                                                        ? stagesList.find(
                                                            (e, i) => e.label === dealInfo.stage
                                                        )
                                                        : null
                                                }
                                                onChange={(e) => handleChange("stage", e.label)}
                                                options={stagesList}
                                            />
                                            {errors.stage && (
                                                <p className="error-text">{errors.stage}</p>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-xl-5 col-lg-6">
                                        <div className="input-block mt-3">
                                            <label className="col-form-label">
                                                Closing Date
                                                <span className="text-danger">*</span>
                                            </label>
                                            <input placeholder="YYYY / MM / DD"
                                                    value={dealInfo.closingDate ? formatDateForInput(dealInfo.closingDate) : ''}
                                                   onChange={(e) =>
                                                       handleChange("closingDate", e.target.value)
                                                   }
                                                   className="form-control"
                                                   type="date"
                                            />
                                            {errors.closingDate && (
                                                <p className="error-text">{errors.closingDate}</p>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-xl-5 col-lg-6">
                                        <div className="input-block mt-3">
                                            <label className="col-form-label">
                                                Probability<span className="text-danger">*</span>
                                            </label>
                                            <input
                                                placeholder="eg: 100"
                                                value={dealInfo.probability}
                                                onChange={(e) =>
                                                    handleChange("probability", e.target.value)
                                                }
                                                className="form-control"
                                                type="number"
                                            />
                                            {errors.probability && (
                                                <p className="error-text">{errors.probability}</p>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-xl-5 col-lg-6">
                                        <div className="input-block mt-3">
                                            <label className="col-form-label">
                                                Date <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                placeholder="YYYY / MM / DD"
                                                value={dealInfo.date ? formatDateForInput(dealInfo.date) : ''}
                                                onChange={(e) =>
                                                    handleChange("date", e.target.value)
                                                }
                                                className="form-control"
                                                type="date"
                                            />
                                            {errors.date && (
                                                <p className="error-text">{errors.date}</p>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-xl-5 col-lg-6">
                                        <div className="input-block mt-3">
                                            <label className="col-form-label">
                                                Next Action  <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                placeholder="eg: Followup meeting"
                                                value={dealInfo.nextAction}
                                                onChange={(e) =>
                                                    handleChange(e.target.value)
                                                }
                                                className="form-control"
                                                type="text"
                                            />
                                        </div>
                                        {errors.nextAction && (
                                            <p className="error-text">{errors.nextAction}</p>
                                        )}
                                    </div>

                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-8">
                                    <div className="input-block mt-3">
                                        <h3 className="heading-subtitles mt-2">
                                            Sales Information
                                        </h3>
                                        <ul className="personal-info">
                                            <li className="mb-2">
                                                <div className="title head"> Created By</div>
                                            </li>
                                            <li className="mb-3">
                                                <div className="project-members">
                                                    <ul className="team-members">
                                                        <li>
                                                            <a
                                                                href="#"
                                                                data-bs-toggle="tooltip"
                                                                aria-label="John Doe"
                                                                data-bs-original-title="John Doe"
                                                            >
                                                                <img src="/assets/img/avatar/avatar-27.jpg" alt="User Image" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                        <label className="col-form-label">
                                            Managed by <span className="text-danger">*</span>
                                        </label>
                                    </div>

                                    <div className="row">
                                        {salesList.map((e, i) => (
                                            e.checked ?
                                                <div className="w-auto d-flex align-items-center justify-content-between sales-incharge-curve">
                                                    <div className=" d-flex align-items-center justify-content-between">
                                                        <div className="list-image-circle ">
                                                            {e.label.split(' ')
                                                                .map(name => name.charAt(0).toUpperCase())
                                                                .join('')
                                                            }
                                                        </div>
                                                        <div className="offset-1">{e.label}</div>
                                                    </div>
                                                    <button
                                                        onClick={() => {
                                                            setDealInfo({ ...dealInfo, salesInCharge: e.value })
                                                            var data1 = [...salesList]
                                                            data1[i].checked = false
                                                            setSalesList(data1)
                                                        }}
                                                        type="button"
                                                        className="custom-btn-close">
                                                        <span aria-hidden="true">×</span>
                                                    </button>
                                                </div>
                                                : null
                                        ))}

                                        <div className="col-md-3">
                                            <div className="">
                                                <button data-bs-toggle="modal" data-bs-target="#add_policy" className="btn btn-primary" type="button">
                                                    Add Person
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-8">
                                    <div className="input-block mt-3">
                                        <label className="col-form-label">Remarks</label>
                                        <textarea
                                            placeholder="eg: type some information"
                                            value={dealInfo.remarks}
                                            onChange={(e) => handleChange("remarks", e.target.value)}
                                            className="form-control"
                                            rows="5"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div >

                <div className="col-lg-12 text-end form-wizard-button">
                    <button className="button btn-lights reset-btn" type="reset">
                        Back
                    </button>
                    <button className="button btn-lights reset-btn" type="reset">
                        Cancel
                    </button>
                    <button
                        onClick={() => handleSubmit()}
                        className="btn btn-primary"
                        type="button"
                    >
                        Save
                    </button>
                </div>
            </div >


            <div id="add_policy" className="modal custom-modal fade" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">SalesInCharges</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className=" mb-3">
                                <input placeholder="search......" className="form-control" type="text" />
                            </div>

                            <div className="list-content-checkbox">

                                {salesList.map((e, i) => (
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="image-circle ">
                                                {e.label.split(' ')
                                                    .map(name => name.charAt(0).toUpperCase())
                                                    .join('')}
                                            </div>
                                            <p className="offset-1">{e.label}</p>
                                        </div>
                                        <div className="edit-column-checkbox">
                                            <input
                                                type="checkbox"
                                                checked={e.checked}
                                                onChange={(event) => handleCheckboxChange(event, i)}
                                                id={`checkbox-${i}`}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default DealEdit;
