import React from "react";
import '../LeadDetails.css';

const LeadDetailsActivityLog = () => {

  const activities = [
    {
      action: 'Sent Mail',
      icon: 'fa fa-envelope',
      iconColor: 'pink-color',
      bgColor: 'pink-bg',
      time: '14:00',
      date: 'May 20, 2024',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
      action: 'Made Call',
      icon: 'fa fa-phone',
      iconColor: 'blue-color',
      bgColor: 'blue-bg',
      time: '12:00',
      date: 'May 21, 2024',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
      action: 'Visit in',
      icon: 'la la-file-text',
      iconColor: 'green-color',
      bgColor: 'green-bg',
      time: '12:00',
      date: 'May 21, 2024',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    // Add more activities as necessary...
  ];
  return(
    <div className="activity-logs-content">
    <div className="profile-box flex-fill bg-white pd-25">
      <div className="experience-box">
        <ul className="experience-list">
          {activities.map((activity, index) => (
            <li key={index}>
              <div className="experience-user">
                <div className={`before-circle ${activity.bgColor}`}>
                  <i className={`${activity.icon} ${activity.iconColor}`}></i>
                </div>
              </div>
              <div className="experience-content">
                <div className="timeline-content">
                  <a href="#/" className="name">{activity.action}</a>
                  <div>{`${activity.time} - ${activity.date}`}</div>
                  <div className="height-100 mt-3">{activity.description}</div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
  )
}

export { LeadDetailsActivityLog }