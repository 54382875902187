import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchfogetpassword } from "./ApiCall";
import { isEmptyObject } from "jquery";
import { isEmpty } from "../../utils/helpers"
import { toast } from "react-toastify";
function ForgotPassword() {


    useEffect(() => {



    }, [])

    // State to manage form inputs
    const [formValues, setFormValues] = useState({
        username: '',
        password: ''
    });
    const [email, setemail] = useState("")

    // State to manage validation errors
    const [errors, setErrors] = useState("");

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    // Validate inputs
    const validate = () => {
        const newErrors = {};

        // Iterate over form values and validate
        Object.keys(formValues).forEach(key => {
            if (!formValues[key]) {
                newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const navigate = useNavigate()
    const LoginApiCall = async () => {
        if (email) {
            // Handle successful validation (e.g., send data to the server)
            console.log('Form submitted', email);

            const { response, err } = await fetchfogetpassword(email)
            // console.log("err----------------------->", response)
            setErrors("")
            setemail("")
            if (!err) {


                toast.success("Reset Password sent to the email  Successfully!");
            }


        }
        else {

            setErrors("Email field is required")
        }
    }
    return (
        <div className="account-page">
            <div className="main-wrapper">
                <div className="account-content">
                    <div className="container">
                        <div className="account-logo">
                            <a href="admin-dashboard.html">
                                <img src={require("../../assets/indo-logo.png")} alt="Indo Sakura" />
                            </a>
                        </div>
                        <div className="account-box">
                            <div className="account-wrapper">
                                <h3 className="account-title">Forgot Password?</h3>
                                <p className="account-subtitle">
                                    Enter your email to get a password reset link
                                </p>
                                <form>
                                    <div className="input-block mb-4">
                                        <label className="col-form-label">Email Address</label>
                                        <input value={email} onChange={(e) => setemail(e.target.value)} className="form-control" type="text" />
                                    </div>
                                    {errors && <p className="error-text">{errors}</p>}
                                    <div className="input-block mb-4 text-center">
                                        <a onClick={() => LoginApiCall()} className="btn btn-primary account-btn" >
                                            Reset Password
                                        </a>
                                    </div>
                                    <div className="account-footer">
                                        <p>
                                            Remember your password? <Link to="/login">Login</Link>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}
export default ForgotPassword;