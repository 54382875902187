import { handleApiCall } from "../../Config/api"

export const fetchlogin = async (data) => {



    try {
        const response = await handleApiCall("POST", "/auth/signin", data)
        // console.log("response", response)
        return { response }

    }
    catch (err) {

        console.log("errrrrrrrrrr", err)

    }


}

export const fetchfogetpassword = async (email) => {



    try {
        const response = await handleApiCall("GET", "/user/fogetPassword?email=" + email)
        console.log("response", response)
        return { response }

    }
    catch (err) {

        console.log("errrrrrrrrrr", err)

    }


}