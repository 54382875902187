const CompanyBillingList = () => {
  const billing = [
    { invoiceId: 'INV001', dealName: 'Deal A', billingMonth: 'January', amount: '1000 USD' },
    { invoiceId: 'INV002', dealName: 'Deal B', billingMonth: 'February', amount: '2000 JPY' },
    { invoiceId: 'INV003', dealName: 'Deal C', billingMonth: 'March', amount: '1500 IND' },
    { invoiceId: 'INV004', dealName: 'Deal D', billingMonth: 'April', amount: '3000 USD' },
    { invoiceId: 'INV005', dealName: 'Deal E', billingMonth: 'May', amount: '5000 JPY' },
    { invoiceId: 'INV006', dealName: 'Deal F', billingMonth: 'June', amount: '2500 IND' },
  ];
  return(
    <div className="deal-list-content">
      <div className="row align-items-center mt-3 mb-3">
        <div className="col-xl-8 col-md-10 ms-auto">
          <div className="d-flex justify-content-end title-head">
            <a className="btn add-btn" href="/company-create">
              <i className="la la-plus-circle"></i>Create
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        {billing.map((deal, index) => (
          <div className="col-md-6 col-lg-4 mb-4" key={index}>
            <div className="card cardbgclr">
              <div className="card-body">
                <div className="textalgend">
                  <div className="dropdown">
                    <button className="btn btn-secondary drpclr" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="las la-ellipsis-v"></i>
                    </button>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item" href="#">Edit</a></li>
                      <li><a className="dropdown-item" href="#">View</a></li>
                    </ul>
                  </div>
                </div>
                <p className="card-text mb-1 d-flex justify-content-between">
                  <strong className="cardinclr">Invoice ID</strong>
                  <span>{deal.invoiceId}</span>
                </p>
                <p className="card-text mb-1 d-flex justify-content-between mt-3">
                  <strong className="cardinclr">Deal name</strong>
                  <span className="cardinclr2">{deal.dealName}</span>
                </p>
                <p className="card-text mb-1 d-flex justify-content-between mt-3">
                  <strong className="cardinclr">Billing month</strong>
                  <span>{deal.billingMonth}</span>
                </p>
                <p className="card-text mb-1 d-flex justify-content-between mt-3">
                  <strong className="cardinclr">Amount</strong>
                  <span>{deal.amount}</span>
                </p>
                <div className="d-flex justify-content-between mt-3">
                  <i className="fas fa-edit mx-2 carditagclr"></i>
                  <i className="fas fa-eye mx-2 cursor"></i>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export {CompanyBillingList}